import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { stagingUrl } from "../../../src/helper";
import QRCode from "react-qr-code";
import BackButton from "../globals/BackButton";
import { useNavigate } from "react-router-dom";
import { AlertCircle, ChevronRight } from "lucide-react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../state/index";

const ActivateTipping = () => {
  const { activeEntity } = useTipJarLocalState();
  console.log("ACTIVE ENTITY ---", activeEntity)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { logOut } = bindActionCreators(actions, dispatch);

  const handleBack = () => {
    logOut();
    navigate("/login");
  };

  const handleNavigateToEnterDetails = () => {
    navigate("/enter-business-details/");
  };

  return (
    <div className="w-full min-h-screen bg-white p-4 flex flex-col">
      <div className="w-full">
        <BackButton onPress={handleBack} />
      </div>

      <h1 className="text-2xl font-semibold text-[#50A2A7] mt-6 mb-8">
        Activate tipping
      </h1>

      <div className="w-full flex flex-col items-center">
        {/* QR Code Container */}
        <div className="w-full max-w-md bg-[#F6F6F6] rounded-lg p-6 flex flex-col items-center">
          <h2 className="text-[#023844] font-semibold mb-4">TipJAR QR Code</h2>

          <div className="relative mb-4">
            <QRCode
              value={`${stagingUrl}${activeEntity?.shukran_id}`}
              size={200}
              fgColor="#CECECE"
              style={{ margin: "0 auto" }}
              className="bg-white p-2"
            />
            {/* Avatar overlay on QR code - optional based on your needs */}
            {/* <div className="absolute bottom-4 right-4 bg-[#50A2A7] rounded-full w-8 h-8 flex items-center justify-center text-white border-2 border-white">
              M
            </div> */}
          </div>

          <p className="text-[#023844] text-sm">
            TipJAR ID: {activeEntity?.shukran_id}
          </p>
        </div>

        {/* Inactive Notice */}
        <div className="w-full max-w-md mt-6 flex flex-col items-center">
          <div className="bg-[#FFF1D6] rounded-lg p-4 w-full flex flex-col items-center gap-3 mb-4">
            <div className="flex items-center gap-2">
              <AlertCircle className="text-[#EE5582]" size={20} />
              <span className="text-[#EE5582] font-medium">
                QR code inactive!
              </span>
            </div>
            <p className="text-center text-sm text-gray-600">
              Complete business KYC to activate TipJAR and start receiving tips
            </p>
            {/* Verify Business Button */}
            <button
              className="bg-[#023844] text-white px-6 py-3 rounded-full flex items-center gap-2 hover:bg-opacity-90 transition-all"
              onClick={handleNavigateToEnterDetails}
            >
              Verify business
              <ChevronRight size={20} color="white" />
            </button>
          </div>

          {/* Share QR Button */}
          <button className="mt-4 bg-[#F2C773] text-white px-6 py-3 rounded-full w-full hover:bg-opacity-90 transition-all hidden">
            SHARE QR
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivateTipping;
