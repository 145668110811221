import { useState, useEffect } from "react";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { Input } from "../../../src/components-shadcn/ui/input";
import { Button } from "../../../src/components-shadcn/ui/button";
import { Checkbox } from "../../../src/components-shadcn/ui/checkbox";
import { PhoneInput } from "../../../src/components-shadcn/ui/phone-input";
import { useRegisterEntity } from "./state/server-state/entityRegistrationServerState";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import PasswordStep from "./PasswordStep";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import ContactPersonDetails from "./ContactPersonDetails";
import { useSignInUser } from "../Login/state/server-state/loginServerState";
import useIsIOS from "../popups/useIsIos";
import { saveToSessionStorage } from "../../Services/Storage";
import { useRegistrationLocalState } from "./state/local-state/useRegistrationLocalState";

const EntityRegistration = () => {
  const [currentStep, setCurrentStep] = useState("details");
  const { setActiveEntity } = useTipJarLocalState();
  const [errorCountryCode, setErrorCountryCode] = useState(null);
  const registerEntity = useRegisterEntity();
  const isRegisteringEntity = registerEntity.isLoading;
  const navigate = useNavigate();
  // Get organization details from registration store
  const { organizationDetails, setOrganizationDetails } = useRegistrationLocalState();
  
  // Form data state initialized with stored values if they exist
  const [formData, setFormData] = useState({
    organization_name: organizationDetails?.organization_name || "",
    business_email: organizationDetails?.business_email || "",
    business_phone: organizationDetails?.business_phone || "",
    agreeToTerms: false,
  });
  // Initialize businessNumber from stored value if it exists
  const [businessNumber, setBusinessNumber] = useState(
    organizationDetails?.business_phone ? `+${organizationDetails.business_phone}` : null
  );

  const [isIOS, setIsIOS] = useIsIOS();
  const signInUser = useSignInUser();
  const isSigningIn = signInUser.isLoading;

  // Rest of your existing validation state and functions...
  // Validation state
  const [validationState, setValidationState] = useState({
    organization_name: {
      isValid: false,
      errorMessage: "",
    },
    business_email: {
      isValid: false,
      errorMessage: "",
    },
    business_phone: {
      isValid: false,
      errorMessage: "",
    }
  });

  // Handle phone number change
  useEffect(() => {
    if (businessNumber) {
      const validationResult = validatePhoneNumber(businessNumber);
      setValidationState(prev => ({
        ...prev,
        business_phone: validationResult
      }));
      
      if (validationResult.isValid) {
        setOrganizationDetails({
          ...organizationDetails,
          business_phone: businessNumber.toString().substring(1)
        });
      }
    }else{
      setBusinessNumber(null);
    }
  }, [businessNumber]);

  // Initialize validations on component mount
  useEffect(() => {
    if (organizationDetails) {
      setValidationState({
        organization_name: validateOrganizationName(organizationDetails.organization_name),
        business_email: validateEmail(organizationDetails.business_email),
        business_phone: validatePhoneNumber(organizationDetails.business_phone)
      });
    }
  }, []);

  // Validation functions
  const validateOrganizationName = (name) => {
    if (!name || name.trim().length === 0) {
      return {
        isValid: false,
        errorMessage: "Organization name is required"
      };
    }
    return {
      isValid: true,
      errorMessage: ""
    };
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return {
        isValid: false,
        errorMessage: "Email is required"
      };
    }
    if (!emailRegex.test(email)) {
      return {
        isValid: false,
        errorMessage: "Please enter a valid email address"
      };
    }
    return {
      isValid: true,
      errorMessage: ""
    };
  };

  const validatePhoneNumber = (phone) => {
    if (!phone) {
      return {
        isValid: false,
        errorMessage: "Phone number is required"
      };
    }
    
    const cleanNumber = phone.toString().replace(/\D/g, '');
    
    if (!cleanNumber.startsWith('254')) {
      return {
        isValid: false,
        errorMessage: "Invalid country code. Service is currently only available in Kenya (+254)"
      };
    }

    const phoneRegex = /^\d{9,12}$/;
    if (!phoneRegex.test(cleanNumber.substring(3))) {
      return {
        isValid: false,
        errorMessage: "Please enter a valid phone number"
      };
    }

    return {
      isValid: true,
      errorMessage: ""
    };
  };

  // Check if form is valid
  const isFormValid = () => {
    return (
      validationState.organization_name.isValid &&
      validationState.business_email.isValid &&
      validationState.business_phone.isValid &&
      formData.agreeToTerms
    );
  };

  // Handle input changes with validation
  const handleInputChange = (field) => (e) => {
    const value = e.target.value;
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));

    const newData = {
      ...formData,
      [field]: value
    };
    
    setFormData(newData);
    
    // Update store with new values
    setOrganizationDetails({
      ...organizationDetails,
      [field]: value
    });

    // Validate field
    let validationResult;
    switch (field) {
      case "organization_name":
        validationResult = validateOrganizationName(value);
        break;
      case "business_email":
        validationResult = validateEmail(value);
        break;
      default:
        return;
    }

    setValidationState(prev => ({
      ...prev,
      [field]: validationResult
    }));
  };

  const handleFirstStep = () => {
    if (!isFormValid()) {
      toast.error("Please fill all required fields correctly");
      return;
    }

    setFormData(prev => ({
      ...prev,
      business_phone: businessNumber.toString().substring(1)
    }));

    setCurrentStep("contact");
  };

  const handleSignInAfterComplete = async(orgData) => {
    const signInPayload = {
      platform: isIOS.platform ? isIOS.platform : "",
      device: isIOS.device ? isIOS.device : "",
      phone: orgData?.business_phone || "",
      password: orgData?.password || "",
    };

    try {
      const response = await signInUser.mutateAsync(signInPayload);
      
      if (!response) {
        toast.error("Sign in failed");
        return;
      }
      if (!response?.token || !response?.shukran_id || !response?.phone) {
        toast.error("Invalid data found");
        return;
      }
      
      await saveToSessionStorage("token", response.token);
      await saveToSessionStorage("ShukranId", response.shukran_id);
      await saveToSessionStorage("firstName", response.first_name);
      await saveToSessionStorage("phoneNumber", response.phone);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("origin", false);
      navigate("/");
    } catch(error) {
      toast.error("An unexpected error occurred");
      console.log("LOGIN ERROR", error);
    }
  };

  const handleRegisterEntity = async (completeData) => {
    try {
      const response = await registerEntity.mutateAsync(completeData);
      setActiveEntity(response.data);
      toast.success("Entity registered successfully");
      handleSignInAfterComplete(completeData);
    } catch (error) {
      const errorMsg = error?.toString() || "Failed to register entity";
      toast.error(errorMsg);
    }
  };

  // Avatar display logic
  const showAvatar = formData.organization_name.trim().length > 0;
  const getInitial = () => formData.organization_name.trim().charAt(0).toUpperCase();

  if (currentStep === "password") {
    return (
      <PasswordStep
        onBack={() => setCurrentStep("details")}
        onComplete={handleRegisterEntity}
        registrationData={formData}
        isLoading={isRegisteringEntity || isSigningIn}
      />
    );
  }

  if (currentStep === "contact") {
    return (
      <ContactPersonDetails
        onBack={() => setCurrentStep("details")}
        onNext={(combinedData) => {
          setFormData(combinedData);
          setCurrentStep("password");
        }}
        registrationData={formData}
      />
    );
  }

  return (
    <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
      <div className="w-full flex justify-center mt-8 mb-16">
        <img src={ShukranLogo} alt="Shukran Logo" className="w-32" />
      </div>

      <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-6">
        <div className="w-full flex flex-col gap-2">
          <h1 className="text-2xl font-semibold text-[#50A2A7] mb-6">
            Create a business account
          </h1>

          <div className="space-y-4">
            <div>
              <div className="relative">
                <Input
                  placeholder="Organization name"
                  value={formData.organization_name}
                  onChange={handleInputChange("organization_name")}
                  className={`w-full h-[55px] bg-white border-[#F5F5F5] rounded-lg ${
                    showAvatar ? "pl-11" : "pl-3"
                  }`}
                />
                {showAvatar && (
                  <div className="absolute left-3 top-1/2 -translate-y-1/2 w-6 h-6 rounded-full bg-[#50A2A7] flex items-center justify-center text-white">
                    {getInitial()}
                  </div>
                )}
              </div>
              <p className={`text-xs mt-1 ${validationState.organization_name.errorMessage ? "text-red-500" : "text-gray-400"}`}>
                {validationState.organization_name.errorMessage || "Eg. Hotel Shukran"}
              </p>
            </div>

            <div>
              <Input
                type="email"
                placeholder="Business email"
                value={formData.business_email}
                onChange={handleInputChange("business_email")}
                className="w-full h-[55px] bg-white border-[#F5F5F5] rounded-lg"
              />
              <p className={`text-xs mt-1 ${validationState.business_email.errorMessage ? "text-red-500" : "text-gray-400"}`}>
                {validationState.business_email.errorMessage || "Eg. business@email.com"}
              </p>
            </div>

            <div>
              <PhoneInput
                placeholder="Business phone number"
                defaultCountry="KE"
                value={businessNumber}
                onChange={(e) => setBusinessNumber(e)}
                className="w-full h-[55px] bg-white border-[#F5F5F5] rounded-lg"
              />
              <p className={`text-xs mt-1 ${validationState.business_phone.errorMessage ? "text-red-500" : "text-gray-400"}`}>
                {validationState.business_phone.errorMessage || "Eg. 0712345678"}
              </p>
            </div>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="terms"
                checked={formData.agreeToTerms}
                onCheckedChange={(checked) =>
                  setFormData(prev => ({ ...prev, agreeToTerms: checked }))
                }
                className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
              />
              <p style={{ fontSize: "14px" }}>
                I agree to{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://shukran.co/terms/"
                  target="_blank"
                >
                  terms of use
                </a>{" "}
                and{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://shukran.co/policy/"
                  target="_blank"
                >
                  privacy policy
                </a>
              </p>
            </div>
          </div>

          <Button
            className="w-full mt-6 bg-[#F2C773] hover:bg-[#e0b665] text-white rounded-full h-[60px] disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleFirstStep}
            disabled={!isFormValid()}
          >
            CREATE ACCOUNT
          </Button>

          <p className="text-center mt-6 text-md text-gray-600">
            Already have an account?{" "}
            <span
              className="text-[#50A2A7] cursor-pointer"
              onClick={() => navigate("/login/")}
            >
              Log in
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EntityRegistration;