import React, { useState } from "react";
import { Input } from "../../../src/components-shadcn/ui/input";
import { Button } from "../../../src/components-shadcn/ui/button";
import { ChevronLeft, Eye, EyeOff, Shield } from "lucide-react";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

import BackButton from "../globals/BackButton";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";

const PasswordStep = ({ onBack, onComplete, registrationData, isLoading }) => {
  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
 
  const handleComplete = () => {
    if (!passwordData.password) {
      toast.error("Password is required");
      return;
    }
    if (passwordData.password !== passwordData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    onComplete({ ...registrationData, password: passwordData.password });
  };



  return (
    <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
      <div className="w-full pl-2">
        <BackButton onPress={onBack} />
      </div>
      <div className="w-full flex justify-center mt-8 mb-16">
        <img src={ShukranLogo} alt="Shukran Logo" className="w-32" />
      </div>

      <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-6">
        <h1 className="text-2xl font-semibold text-[#50A2A7] mb-6">
          Create a secure password
        </h1>

        <div className="space-y-4">
          <div>
            <label className="text-gray-600 text-sm mb-1">Password</label>
            <div className="relative">
              <Input
                type={showPassword ? "text" : "password"}
                value={passwordData.password}
                onChange={(e) =>
                  setPasswordData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                className="w-full h-[55px] bg-white border-[#F5F5F5] rounded-lg pr-10"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2"
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
          </div>

          <div>
            <label className="text-gray-600 text-sm mb-1">
              Confirm password
            </label>
            <div className="relative">
              <Input
                type={showConfirmPassword ? "text" : "password"}
                value={passwordData.confirmPassword}
                onChange={(e) =>
                  setPasswordData((prev) => ({
                    ...prev,
                    confirmPassword: e.target.value,
                  }))
                }
                className="w-full h-[55px] bg-white border-[#F5F5F5] rounded-lg pr-10"
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2"
              >
                {showConfirmPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-400" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
          </div>

          <div className="bg-[#FFEBF1] rounded-lg p-4 flex items-start space-x-3">
            <Shield className="h-5 w-5 text-[#EE5582] flex-shrink-0 mt-1" />
            <div>
              <h3 className="font-medium text-[#EE5582] mb-1">
                Protect your password!
              </h3>
              <p className="text-sm text-[#EE5582]">
                Your password is your secret. Do not share it with anyone who
                does not manage the business account.
              </p>
            </div>
          </div>
        </div>

        <Button
          className="w-full mt-6 bg-[#F2C773] hover:bg-[#e0b665] text-white rounded-full h-[60px]"
          onClick={handleComplete}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              COMPLETING REGISTRATION...
            </>
          ) : (
            "COMPLETE"
          )}
        </Button>
      </div>
    </div>
  );
};

export default PasswordStep;
