import { create, StateCreator } from "zustand";

import {
    createJSONStorage,
    devtools,
    persist,
    PersistOptions,
  } from "zustand/middleware";


  interface OrganizationDetailsDetailsSlice {
    organizationDetails: {
      contact_person_name: string;
      contact_person_role: string;
      contact_person_phone: string;
      organization_name: string;
      organization_email: string;
      business_phone: string;
    } | null;
    setOrganizationDetails: (details: {
      contact_person_name: string;
      contact_person_role: string;
      contact_person_phone: string;
      organization_name: string;
      organization_email: string;
      business_phone: string;
    } | null) => void;
  }


  // Add to initial state
const initialState = {
    // ... existing initial state
    organizationDetails: {
        contact_person_name: "",
        contact_person_role: "",
        contact_person_phone: "",
        organization_name: "",
        business_email: "",
        business_phone: "",
      },
  };

  type RegistrationStore= OrganizationDetailsDetailsSlice

  // Create the slice
const createOrganizationDetailsSlice: StateCreator<
RegistrationStore,
[],
[],
OrganizationDetailsDetailsSlice
> = (set) => ({
organizationDetails: null,
setOrganizationDetails: (details) => set({ organizationDetails: details }),
});


  export const useRegistrationLocalState = create<RegistrationStore>()(
      (...a) => ({
        ...initialState,
        ...createOrganizationDetailsSlice(...a),
      }),
  );