import React, { useState } from "react";
import { Input } from "../../../../src/components-shadcn/ui/input";
import { Button } from "../../../components-shadcn/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components-shadcn/ui/select";
import { NotebookTabs, CirclePlus } from "lucide-react";
import { toast } from "sonner";
import { useTipJarLocalState } from "../state/local-state/useTipjarLocalState";
import { PhoneInput } from "../../../../src/components-shadcn/ui/phone-input";
import { Checkbox } from "../../../components-shadcn/ui/checkbox";

const AddWithPhoneNumber = ({ setSearchPhone, setAddNewMember }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [canJoinEntityJar, setCanJoinEntityJar] = useState(true);
  const { entityDepartments } = useTipJarLocalState();

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{9,12}$/;
    return phoneRegex.test(phone.replace(/\D/g, ""));
  };

  const checkDuplicatePhone = (phone) => {
    const existingMembers = localStorage.getItem("express_members");
    if (existingMembers) {
      const { express_members } = JSON.parse(existingMembers);
      return express_members.some((member) => member.phone === phone);
    }
    return false;
  };

  const updateMemberDepartments = (phoneNumber, departmentId) => {
    const existingDepartments = localStorage.getItem("memberDepartments");
    let departmentMappings = {};

    if (existingDepartments) {
      departmentMappings = JSON.parse(existingDepartments);
    }

    departmentMappings[phoneNumber] = departmentId;

    localStorage.setItem(
      "memberDepartments",
      JSON.stringify(departmentMappings)
    );
  };

  const handleSubmit = () => {
    // Validate phone number
    if (!validatePhoneNumber(phoneNumber)) {
      toast.error("Please enter a valid phone number");
      return false;
    }

    // Check for duplicate phone number
    if (checkDuplicatePhone(phoneNumber)) {
      toast.error("A member with this phone number has already been added");
      return false;
    }

    // Validate names
    if (!firstName.trim()) {
      toast.error("First name is required");
      return false;
    }

    if (!lastName.trim()) {
      toast.error("Last name is required");
      return false;
    }

    // Validate department selection
    if (!selectedDepartment) {
      toast.error("Please select a department");
      return false;
    }

    // Create new member object
    const newMember = {
      id: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber.substring(1),
      department_id: selectedDepartment,
      can_join_entity_jar: canJoinEntityJar,
      shukran_id: "N/A",
    };

    // Get existing members or initialize empty array
    const existingData = localStorage.getItem("express_members");
    let memberPayload = { express_members: [] };

    if (existingData) {
      memberPayload = JSON.parse(existingData);
    }

    const memberExists = memberPayload.express_members.some(
      (member) => member.phone === newMember.phone
    );

    if (memberExists) {
      toast.error("Member has already been added");
      return false;
    }

    // Add new member to array
    memberPayload.express_members.push(newMember);

    try {
      // Save member payload
      localStorage.setItem("express_members", JSON.stringify(memberPayload));

      // Update department mappings
      updateMemberDepartments(phoneNumber, selectedDepartment);

      // Reset form
      setPhoneNumber("");
      setFirstName("");
      setLastName("");
      setSelectedDepartment("");
      setCanJoinEntityJar(true);
      return true;
    } catch (error) {
      toast.error("Failed to save member details");
      return false;
    }
  };

  const handleProceed = () => {
    // Check if there's any data entered in the fields
    const hasPartialData =
      phoneNumber || firstName || lastName || selectedDepartment;

    if (!hasPartialData) {
      // If no data entered, proceed directly
      setAddNewMember(false);
      return;
    }

    // Check if all required fields are filled
    const hasAllData =
      phoneNumber && firstName && lastName && selectedDepartment;

    if (!hasAllData) {
      toast.error("Please complete all fields before proceeding");
      return;
    }

    // Try to save the data before proceeding
    const saveSuccessful = handleSubmit();

    if (saveSuccessful) {
      setAddNewMember(false);
    }
  };

  return (
    <div className="flex flex-col w-full max-w-md mx-auto p-4">
      <label className="text-3xl font-semibold text-primary-pwa">
        Add with phone number
      </label>

      <div className="relative mt-6 h-[55px]">
        <PhoneInput
          placeholder="Business phone number"
          defaultCountry="KE"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.toString())}
          className="w-full bg-white border-[#F5F5F5] rounded-lg shadow-md"
        />
      </div>

      <Input
        type="text"
        placeholder="First name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        className="mb-4 h-[55px] shadow-md border-0 focus:ring-0 rounded-lg"
      />

      <Input
        type="text"
        placeholder="Last name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        className="mb-4 h-[55px] shadow-md border-0 focus:ring-0 rounded-lg"
      />

      <Select value={selectedDepartment} onValueChange={setSelectedDepartment}>
        <SelectTrigger className="mb-6 h-[55px] shadow-md border-0 focus:ring-0 rounded-lg">
          <SelectValue placeholder="Select department" />
        </SelectTrigger>
        <SelectContent>
          {entityDepartments.map((dept) => (
            <SelectItem key={dept.id} value={dept.id} disabled={!dept.active}>
              {dept.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <div className="flex items-center space-x-2 mb-6">
        <Checkbox
          id="joinEntityJar"
          checked={canJoinEntityJar}
          onCheckedChange={setCanJoinEntityJar}
          className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
        />
        <label htmlFor="joinEntityJar" className="text-sm text-gray-600">
          Include member in group tips
        </label>
      </div>

      <div className="py-4 flex flex-row items-center text-sm">
        <span className="text-primary">OR</span>
        <div
          className="border-none rounded-md px-1 py-1 bg-[#64C8CE] text-white ml-2 cursor-pointer"
          onClick={() => setSearchPhone(false)}
        >
          add with Shukran ID
        </div>
      </div>

      <Button
        onClick={handleSubmit}
        className="rounded-full bg-white text-[#6CCBD1] border-2 border-[#6CCBD1] text-m mx-auto w-full flex flex-row items-center justify-center h-[60px] mb-2"
      >
        <p className="text-[#6CCBD1]">ADD OTHER </p>
        <CirclePlus color={"#6CCBD1"} className="ml-2" />
      </Button>

      <Button
        onClick={handleProceed}
        className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-white h-[60px] rounded-full font-medium mt-3"
      >
        PROCEED
      </Button>
    </div>
  );
};

export default AddWithPhoneNumber;