import { useState, useEffect } from "react";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { Input } from "../../../src/components-shadcn/ui/input";
import { Button } from "../../../src/components-shadcn/ui/button";
import { PhoneInput } from "../../../src/components-shadcn/ui/phone-input";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";
import BackButton from "../globals/BackButton";
import { useRegistrationLocalState } from "./state/local-state/useRegistrationLocalState";

const ContactPersonDetails = ({ onBack, onNext, registrationData }) => {
  // Form data state
  const [contactData, setContactData] = useState({
    contact_person_name: "",
    contact_person_role: "",
    contact_person_phone: "",
  });

  // Validation state
  const [validationState, setValidationState] = useState({
    contact_person_name: {
      isValid: false,
      errorMessage: "",
    },
    contact_person_role: {
      isValid: false,
      errorMessage: "",
    },
    contact_person_phone: {
      isValid: false,
      errorMessage: "",
    }
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Validation functions
  const validateName = (name) => {
    if (!name || name.trim().length === 0) {
      return {
        isValid: false,
        errorMessage: "Contact person name is required"
      };
    }
    return {
      isValid: true,
      errorMessage: ""
    };
  };

  const validateRole = (role) => {
    if (!role || role.trim().length === 0) {
      return {
        isValid: false,
        errorMessage: "Contact person role is required"
      };
    }
    return {
      isValid: true,
      errorMessage: ""
    };
  };

  // Updated phone validation to match EntityRegistration
  const validatePhoneNumber = (phone) => {
    if (!phone) {
      return {
        isValid: false,
        errorMessage: "Phone number is required"
      };
    }
    
    const cleanNumber = phone.toString().replace(/\D/g, '');
    
    if (!cleanNumber.startsWith('254')) {
      return {
        isValid: false,
        errorMessage: "Invalid country code. Service is currently only available in Kenya (+254)"
      };
    }

    const phoneRegex = /^\d{9,12}$/;
    if (!phoneRegex.test(cleanNumber.substring(3))) {
      return {
        isValid: false,
        errorMessage: "Please enter a valid phone number"
      };
    }

    return {
      isValid: true,
      errorMessage: ""
    };
  };

  // Check if form is valid
  const isFormValid = () => {
    return (
      validationState.contact_person_name.isValid &&
      validationState.contact_person_role.isValid &&
      validationState.contact_person_phone.isValid
    );
  };

  // Handle input changes with validation
  const handleInputChange = (field) => (e) => {
    const value = e.target.value;
    setContactData(prev => ({
      ...prev,
      [field]: value
    }));

    // Validate field
    let validationResult;
    switch (field) {
      case "contact_person_name":
        validationResult = validateName(value);
        break;
      case "contact_person_role":
        validationResult = validateRole(value);
        break;
      default:
        return;
    }

    setValidationState(prev => ({
      ...prev,
      [field]: validationResult
    }));
  };

  // Handle phone number change with validation
  useEffect(() => {
    if (contactData.contact_person_phone) {
      const validationResult = validatePhoneNumber(contactData.contact_person_phone);
      setValidationState(prev => ({
        ...prev,
        contact_person_phone: validationResult
      }));
    }
  }, [contactData.contact_person_phone]);

  const handleSubmit = () => {
    if (!isFormValid()) {
      toast.error("Please fill all required fields correctly");
      return;
    }

    setIsSubmitting(true);
    
    try {
      // Format phone number by removing the leading '+'
      const formattedPhone = contactData.contact_person_phone.toString().substring(1);
      
      // Combine the data and proceed to next step
      const combinedData = {
        ...registrationData,
        contact_person_name: contactData.contact_person_name,
        contact_person_role: contactData.contact_person_role,
        contact_person_phone: formattedPhone,
      };

      onNext(combinedData);
    } catch (error) {
      toast.error("An error occurred while processing your request");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
      <div className="w-full pl-2">
        <BackButton onPress={onBack} />
      </div>

      <div className="w-full flex justify-center mt-8 mb-16">
        <img src={ShukranLogo} alt="Shukran Logo" className="w-32" />
      </div>

      <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-6">
        <div className="w-full flex flex-col gap-2">
          <h1 className="text-2xl font-semibold text-[#50A2A7] mb-6">
            Kindly provide a contact that our team can reach out to
          </h1>

          <div className="space-y-4">
            <div>
              <Input
                placeholder="Contact person's name"
                value={contactData.contact_person_name}
                onChange={handleInputChange("contact_person_name")}
                className="w-full h-[55px] bg-white border-[#F5F5F5] rounded-lg"
              />
              <p className={`text-xs mt-1 ${validationState.contact_person_name.errorMessage ? "text-red-500" : "text-gray-400"}`}>
                {validationState.contact_person_name.errorMessage || "Eg. John Doe"}
              </p>
            </div>

            <div>
              <Input
                placeholder="Contact person's role"
                value={contactData.contact_person_role}
                onChange={handleInputChange("contact_person_role")}
                className="w-full h-[55px] bg-white border-[#F5F5F5] rounded-lg"
              />
              <p className={`text-xs mt-1 ${validationState.contact_person_role.errorMessage ? "text-red-500" : "text-gray-400"}`}>
                {validationState.contact_person_role.errorMessage || "Eg. Manager"}
              </p>
            </div>

            <div>
              <PhoneInput
                placeholder="Contact person's phone number"
                defaultCountry="KE"
                value={contactData.contact_person_phone}
                onChange={(phone) => 
                  setContactData(prev => ({
                    ...prev,
                    contact_person_phone: phone
                  }))
                }
                className="w-full h-[55px] bg-white border-[#F5F5F5] rounded-lg"
              />
              <p className={`text-xs mt-1 ${validationState.contact_person_phone.errorMessage ? "text-red-500" : "text-gray-400"}`}>
                {validationState.contact_person_phone.errorMessage || "Eg. 0712345678"}
              </p>
            </div>
          </div>

          <Button
            className="w-full mt-6 bg-[#F2C773] hover:bg-[#e0b665] text-white rounded-full h-[60px] disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleSubmit}
            disabled={!isFormValid() || isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                PROCESSING...
              </>
            ) : (
              "PROCEED"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactPersonDetails;