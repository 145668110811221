import React, { useState, useEffect } from "react";
import { Input } from "../../components-shadcn/ui/input";
import { Button } from "../../components-shadcn/ui/button";
import { Card } from "../../components-shadcn/ui/card";
import BackButton from "../globals/BackButton";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useCreateEntityJar } from "../TipGroups/state/server-state/useTipGroupsServerState";
import { useAddMemeberToGroup } from "./state/server-state/useTipJarServerState";
import { useTipJarLocalState } from "./state/local-state/useTipjarLocalState";
import { Search, Plus, X, Loader2 } from "lucide-react";

const CreateEntityJar = () => {
  const [entityJarName, setEntityJarName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [groupLeader, setGroupLeader] = useState(null);
  const { activeEntity, setActiveEntityJAR, entityDepartments } =
    useTipJarLocalState();
  const createEntityJar = useCreateEntityJar();
  const isCreatingEntityJar = createEntityJar.isLoading;

  const addGroupMembers = useAddMemeberToGroup();
  const isAddingGroupMembers = addGroupMembers.isLoading;

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  // Filter members who can join the entity jar
  const eligibleMembers = entityDepartments.flatMap((dept) =>
    dept.members.filter((member) => member.can_join_entity_jar === true)
  );

  const filteredMembers = eligibleMembers.filter(
    (member) =>
      `${member.user.first_name} ${member.user.last_name}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      member.user.shukran_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddGroupLeader = async (entityJar) => {
    const { id, name } = entityJar;

    if (!groupLeader) {
      toast.error("Please select a group leader");
      return;
    }
    if (!id) {
      toast.error("Entity jar ID not found");
      return;
    }

    const payload = {
      entity_jar: id,
      shukran_ids: [
        {
          shukran_id: groupLeader.user.shukran_id,
          department_id: groupLeader.department,
          is_team_leader: true,
        },
      ],
    };

    try {
      await addGroupMembers.mutateAsync(payload);
      toast.success("Group leader added successfully");
      handleBack();
    } catch (error) {
      toast.error("Failed to add group leader");
    }
  };

  const handleCreateEntityJar = async () => {
    if (!activeEntity) {
      toast.error("Some data is missing");
      return;
    }

    if (!entityJarName || entityJarName.length <= 0) {
      toast.error("Enter tipJAR name");
      return;
    }

    if (!groupLeader) {
      toast.error("Please select a jar manager");
      return;
    }

    const payload = {
      name: entityJarName,
      entity_id: activeEntity.id,
      is_default_entity_jar: false,
    };

    try {
      const response = await createEntityJar.mutateAsync(payload);
      toast.success("TipJAR created");
      if (response?.data?.data) {
        setActiveEntityJAR(response?.data?.data);
        await handleAddGroupLeader(response.data.data);
      }
    } catch (error) {
      toast.error("Failed to create tipJAR");
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white px-2">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
        </div>
      </div>
      <h1 className="text-2xl font-bold mb-2 mt-6">New TipJAR</h1>
      <p className="text-gray-500 mb-4">
        Create a unique tipJAR to manage events and activities
      </p>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            TipJAR name
          </label>
          <Input
            placeholder="Enter TipJAR name"
            value={entityJarName}
            onChange={(e) => setEntityJarName(e.target.value)}
            className="h-[55px] shadow-md border-2 border-[#6CCBD1] rounded-md p-2 w-full placeholder-gray-300 focus:border-transparent focus:outline-transparent"
          />
        </div>
      </div>

      {groupLeader && (
        <Card className="mt-4 p-4 flex items-center justify-between">
          <div className="flex items-center">
            <div className="w-10 h-10 rounded-full bg-[#6CCBD1] flex items-center justify-center text-white font-bold mr-3">
              {groupLeader.user.first_name[0]}
              {groupLeader.user.last_name[0]}
            </div>
            <div>
              <p className="font-semibold">{`${groupLeader.user.first_name} ${groupLeader.user.last_name}`}</p>
              <p className="text-sm text-gray-500">
                {groupLeader.user.shukran_id}
              </p>
            </div>
          </div>
          <Button variant="ghost" onClick={() => setGroupLeader(null)}>
            <X className="h-6 w-6 text-red-500" />
          </Button>
        </Card>
      )}

      <div className="relative mt-4 flex w-full flex-col gap-1">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Select jar manager
        </label>

        <Input
          placeholder="Search name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-3 h-[55px] shadow-md border-2 border-[#6CCBD1] rounded-full w-full flex items-center"
        />
      </div>

      <div className="mt-4 space-y-2 overflow-y-auto flex-grow">
        {filteredMembers.map((member) => (
          <div
            key={member.id}
            className="flex items-center justify-between p-2 border rounded-lg"
          >
            <div className="flex items-center">
              <div className="w-10 h-10 rounded-full bg-[#6CCBD1] flex items-center justify-center text-white font-bold mr-3">
                {member.user.first_name[0]}
                {member.user.last_name[0]}
              </div>
              <div>
                <p className="font-semibold">{`${member.user.first_name} ${member.user.last_name}`}</p>
                <p className="text-sm text-gray-500">
                  {member.user.shukran_id}
                </p>
              </div>
            </div>
            <Button
              variant="outline"
              className="bg-[#6CCBD1] text-white h-[30px]"
              onClick={() => setGroupLeader(member)}
            >
              <Plus className="h-4 w-4 mr-1" color="white" /> Assign
            </Button>
          </div>
        ))}
      </div>
      <div className="w-full p-2">
        <Button
          className="w-full bg-[#F2C773] text-white rounded-full h-[60px] font-semibold"
          onClick={handleCreateEntityJar}
          disabled={isCreatingEntityJar || isAddingGroupMembers}
        >
          {isCreatingEntityJar ||
            (isAddingGroupMembers && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            ))}
          {isCreatingEntityJar || isAddingGroupMembers
            ? "Processing..."
            : "CREATE TIPJAR"}
        </Button>
      </div>
    </div>
  );
};

export default CreateEntityJar;
