import {
  useQuery,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
  useMutation,
} from "@tanstack/react-query";

import { AxiosInstance, AxiosResponse } from "axios";
import { constructApiUrl } from "../../../../utils/constructApiUrl";
import useAxios from "../../../../shared/useAxios";
import { entityRegistrationQueryTypes } from "./entityRegistrationQueryTypes";

type BusinessInfo = {
  organization_name: string;
  business_email: string;
  business_phone: string;
  password: string;
};

const registerEntity = async (
  $http: AxiosInstance,
  data: BusinessInfo
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/kyc/`);
  try {
    const response = await $http.post(apiUrl, data);
    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }
    console.log("error", error?.response?.data?.data);
    throw new Error( `${error?.response?.data?.data}`);
  }
};

export const useRegisterEntity = () => {
  const $http = useAxios();

  return useMutation({
    mutationKey: [entityRegistrationQueryTypes.REGISTER_ENTITY],
    mutationFn: (data: BusinessInfo) => registerEntity($http, data),
  });
};

type UploadKYCData = {
  entity_id: string;
  document: File;
  document_type: string;
};

// Function to upload KYC documents
const uploadKYCDocuments = async (
  $http: AxiosInstance,
  data: UploadKYCData
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/kyc/upload-kyc/`);

  // Create FormData
  const formData = new FormData();
  formData.append("entity_id", data.entity_id);
  formData.append("document", data.document);
  formData.append("document_type", data.document_type);

  try {
    const response = await $http.post(apiUrl, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      throw new Error("Your session has expired. Please login again.");
    }
    throw new Error("An unexpected error occurred");
  }
};

// Mutation hook to use the upload function
export const useUploadKYCDocuments = () => {
  const $http = useAxios();

  return useMutation({
    mutationKey: [entityRegistrationQueryTypes.UPLOAD_KYC_DOCUMENTS],
    mutationFn: (data: UploadKYCData) => uploadKYCDocuments($http, data),
  });
};
