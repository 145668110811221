import React from 'react';
import GroupTipJarIcon from "../../assets/Images/group-tipjar-icon.png";
const EntityJarCard = ({ entityJar, defaultJAR, handleEntityJarClick }) => (
  <div 
    className="flex items-center justify-center w-full cursor-pointer"
    onClick={() => handleEntityJarClick(entityJar)}
  >
    <div className="flex items-center justify-between w-full h-[65px] mb-3 border-2 border-[#F5F5F5] rounded-lg">
      <div className="flex flex-row items-center gap-1.5 w-full px-2">
        <div className="w-10 h-10 rounded-full flex items-center justify-center p-1 bg-[#472884]">
          <img src={GroupTipJarIcon} alt="icon" />
        </div>
        
        <div className="ml-2 w-full">
          <p className="font-semibold text-[#404B5A] text-sm">
            {entityJar?.name}
          </p>
          <p className="text-[#929FB1] text-xs">
            {entityJar?.shukran_id}
          </p>
        </div>
      </div>

      {entityJar?.id === defaultJAR?.id && (
        <div className="flex items-center px-2">
          <span className="text-xs text-white bg-accent px-2 py-1 rounded">
            Main
          </span>
        </div>
      )}
      
      {entityJar?.active === false && (
        <div className="flex items-center px-2">
          <span className="text-xs text-white px-2 py-1 bg-red-500 rounded">
            Inactive
          </span>
        </div>
      )}
    </div>
  </div>
);

const EntityJarList = ({ entityJars, defaultJAR, handleEntityJarClick }) => {
  // Sort entityJars to ensure default JAR appears first
  const sortedEntityJars = React.useMemo(() => {
    if (!entityJars?.data?.data) return [];
    
    return [...entityJars.data.data].sort((a, b) => {
      if (a.id === defaultJAR?.id) return -1;
      if (b.id === defaultJAR?.id) return 1;
      return 0;
    });
  }, [entityJars?.data?.data, defaultJAR?.id]);

  return (
    <>
      {sortedEntityJars.map((entityJar, index) => (
        <EntityJarCard
          key={entityJar.id || index}
          entityJar={entityJar}
          defaultJAR={defaultJAR}
          handleEntityJarClick={handleEntityJarClick}
        />
      ))}
    </>
  );
};

export default EntityJarList;