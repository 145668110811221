import Share2 from "../../assets/Icons/Dashboard/share2.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DepositIcon from "../../assets/Icons/Dashboard/deposit_Icon.svg";
import customerSupport from "../../assets/Icons/Dashboard/customer_support.svg";
import AccessTipIcon from "../../assets/Icons/Dashboard/transfer_icon.svg";
import TipIcon from "../../assets/Icons/Dashboard/tip_icon.svg";
import ActivateIcon from "../../assets/Icons/Dashboard/activate.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import Members from "../../assets/Icons/Dashboard/Members.png";
import ReservationIcon from "../../assets/Icons/Dashboard/reservation.svg";
import inviteSacco from "../../assets/Icons/Dashboard/invite_sacco.svg";
import { Link } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
} from "../../components-shadcn/ui/alert-dialog";
import { toast } from "sonner";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { getFromSessionStorage } from "../../Services/Storage";
import { useEffect, useState, useCallback } from "react";

import { useGetProfile } from "../../shared/server-state/profile/profileServerState";

function DashboardActions({
  userData,
  encodeUrl,
  hasAdjustedPercentage,
  setUserData,
}) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(userData);
  const getProfile = useGetProfile();
  const isGetingProfile = getProfile.isLoading;
  const dispatch = useDispatch();
  const makeReservation = () => {
    // setReservationBanner(true);
    navigate("/searchRestaurant");
  };

  const navigateToTipGroupsHome = () => {
    navigate("/tip-groups");
  };

  let localShukranId = getFromSessionStorage("ShukranId");

  const handleGetProfile = useCallback(async () => {
    let params = {
      shukran_id: localShukranId,
    };
    try {
      const response = await getProfile.mutateAsync(params);
      if (response === 403) {
        navigate("/login/");
      }
      const data = response?.data?.data;

      // dispatch(actionTypes.getUserDataSuccess(data));
      setUserInfo(data);
      setUserData(data);
    } catch (error) {
      toast.error("Failed to get profile");
    }
  }, [localShukranId, getProfile]);

  useEffect(() => {
    if (!userInfo || !userInfo?.shukran_id) {
      handleGetProfile();
    }
  }, [userInfo]);

  return (
    <div>
      {" "}
      <div className="flex flex-row justify-center mx-auto items-center mt-16 z-50">
        {userInfo?.user_type === "SERVICE WORKER" ? (
          !userInfo.sacco_stage ||
          userInfo.sacco_stage === "new" ||
          userInfo.sacco_stage === "kyc_incomplete" ||
          userInfo.sacco_stage === "application_rejected" ? (
            <Link to="/activateExplainer" className="Small_Button h-24">
              <div className="circle">
                <img
                  src={ActivateIcon}
                  alt="Sacco_Activate"
                  className="w-10 h-10"
                />
                <p className="test-sm text-white mt-1">Join Sacco</p>
              </div>
            </Link>
          ) : (
            <Link to="/depositOpt" className="Small_Button h-24">
              <div className="circle">
                <img
                  src={DepositIcon}
                  alt="Sacco_Activate"
                  className="w-10 h-10"
                />
                <p className="test-sm text-white mt-1"></p>
              </div>
            </Link>
          )
        ) : userInfo?.user_type === "TIPPER" ? (
          <Link to="/search">
            <div className="circle">
              <img src={TipIcon} alt="Sacco_Activate" className="w-10 h-10" />
              <p className="test-sm text-white mt-1">Tip</p>
            </div>
          </Link>
        ) : userInfo?.user_type === "TIP_JAR" ||
          userInfo?.user_type === "ENTITY_JAR" ? (
          <Link to="/select-jars-to-disburse-from/">
            <div className="circle">
              <img src={TipIcon} alt="Sacco_Activate" className="w-10 h-10" />
              <p
                className="test-sm text-white mt-1"
                style={{ fontSize: "14px" }}
              >
                Distribute
              </p>
            </div>
          </Link>
        ) : // <div
        //   className="circle"
        //   onClick={() => navigateToManageAndDistributeTipJAR()}
        // >
        //   <img src={TipIcon} alt="Sacco_Activate" className="w-10 h-10" />
        //   <p className="test-sm text-white mt-1" style={{ fontSize: "14px" }}>
        //     Distribute
        //   </p>
        // </div>
        userInfo.sacco_stage === "new" ||
          userInfo.sacco_stage === "approval_pending" ||
          userInfo.sacco_stage === "application_rejected" ? (
          <Link to="/activateExplainer">
            <div className="circle">
              <img
                src={ActivateIcon}
                alt="Sacco_Activate"
                className="w-10 h-10"
              />
              <p className="test-sm text-white mt-1">Join Sacco</p>
            </div>
          </Link>
        ) : userInfo.sacco_stage === "pay_membership_fee" ? (
          <Link to="/saccoDepositOpt">
            <div className="circle">
              <img
                src={DepositIcon}
                alt="Sacco_Activate"
                className="w-10 h-10"
              />
              <p className="test-sm text-white mt-1">Deposit</p>
            </div>
          </Link>
        ) : (
          <img
            src={loader}
            alt="loader"
            className={`animate-spin h-14 mx-auto text-sm mb-8`}
          />
        )}
      </div>
      {/* SERVICE WORKER SACCO - MIDDLE ROW */}
      {/* {userInfo.user_type === "TIPPER" && ( */}
      {userInfo?.user_type === "SERVICE WORKER" && (
        <div className="flex flex-row justify-between items-start mt-4 px-10 z-40">
          {/* <Share
        setFallbackOption={setFallbackOption}
        inviteOption={''}
        title="Have you heard of Shukran?"
        text="I am using Shukran, a digital tipping platform that ensures the most
    deserving service workers are rewarded. To learn more, Visit"
      /> */}
          {userInfo.sacco_stage === "pay_share_capital" ||
          userInfo.sacco_stage === "full_member" ? (
            <div
              onClick={() => {
                navigate("/referrals");
              }}
              className="Small_Button h-24"
            >
              <div className={`smallCircle bg-primary-green`}>
                <img
                  src={inviteSacco}
                  alt="Deposit Button"
                  className="w-full h-full"
                />
              </div>

              <div className="test-sm mt-1 w-16 text-center">
                Invite A Friend
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                navigate("/referrals");
              }}
              className="Small_Button h-24"
            >
              <div className={`smallCircle bg-primary-green`}>
                <img
                  src={inviteSacco}
                  alt="Deposit Button"
                  className="w-full h-full"
                />
              </div>

              <div className="test-sm mt-1 w-16 text-center">
                Invite A Friend
              </div>
            </div>
          )}
          {!userInfo.sacco_stage ||
          userInfo.sacco_stage === "new" ||
          userInfo.sacco_stage === "kyc_incomplete" ||
          userInfo.sacco_stage === "approval_pending" ||
          userInfo.sacco_stage === "application_rejected" ? (
            <div
              onClick={() => {
                navigate("/finalWithdrawal");
              }}
              className="Small_Button h-24"
            >
              <div className={`smallCircle bg-primary-green`}>
                <img
                  src={AccessTipIcon}
                  alt="Tip Button"
                  className="w-10 h-10"
                />
              </div>
              <p className="test-sm mt-1">Withdraw</p>
            </div>
          ) : (
            <div
              onClick={() => {
                if (hasAdjustedPercentage) {
                  navigate("/accessTips");
                } else {
                  navigate("/adjustPercentage");
                }
              }}
              className="Small_Button h-24"
            >
              <div className={`smallCircle bg-primary-green`}>
                <img
                  src={AccessTipIcon}
                  alt="Tip Button"
                  className="w-10 h-10"
                />
              </div>
              <p className="test-sm mt-1">Access Tips</p>
            </div>
          )}
        </div>
      )}
      {userInfo?.user_type === "TIP_JAR" ||
      userInfo?.user_type === "ENTITY_JAR" ? (
        <div className="flex flex-row justify-between items-start mt-4 px-10 z-40">
          {/* <Share
        setFallbackOption={setFallbackOption}
        inviteOption={''}
        title="Have you heard of Shukran?"
        text="I am using Shukran, a digital tipping platform that ensures the most
    deserving service workers are rewarded. To learn more, Visit"
      /> */}

          <div
            onClick={() => navigate("/invite")}
            className="Small_Button h-26 cursor-pointer"
          >
            <div
              style={{
                width: "68px",
                height: "68px",
                borderRadius: "34px",
                alignItems: "center !important",
                justifyContent: "center !important",
              }}
              className="bg-primary-green"
            >
              <img
                src={Share2}
                alt="Deposit Button"
                className="w-14 ml-1 h-15 mt-2"
                style={{
                  padding: "5px",
                  alignSelf: "center",
                }}
              />
            </div>

            <div className="test-sm mt-1 w-16 text-center">Share TipJar</div>
          </div>

          <div
            onClick={() => navigate("/tipjar/")}
            className="Small_Button h-26 cursor-pointer"
            style={{ flexDirection: "column", display: "flex" }}
          >
            <div
              style={{
                width: "68px",
                height: "68px",
                borderRadius: "34px",
                alignItems: "center !important",
                justifyContent: "center !important",
              }}
              className="bg-[#E75581]"
            >
              <img
                src={Members}
                alt="Deposit Button"
                className="w-14 ml-1.5 h-15 mt-3"
                style={{
                  padding: "5px",
                  alignSelf: "center",
                }}
              />
            </div>

            <div
              className="test-sm mt-1 w-16 text-center"
              style={{ position: "relative" }}
            >
              Manage TipJAR
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* TIPPER - MIDDLE ROW */}
      {/* {userInfo.user_type === "SERVICE WORKER" && ( */}
      {userInfo?.user_type === "TIPPER" && (
        <div className="flex flex-row justify-between items-start mt-4 px-10 z-40">
          <div
            onClick={makeReservation}
            className="Small_Button cursor-pointer w-16 h-full text-center"
          >
            <div className="smallCircle bg-[#6CCBD1] flex justify-center items-center">
              <img
                src={ReservationIcon}
                alt="Tip Button"
                className="h-10 pb-2"
              />
            </div>
            <div className="test-sm mt-1">Reservations</div>
          </div>
          {/* <Share
        setShareApiErr={setShareApiErr}
        title="Have you heard of Shukran?"
        text="I am using Shukran, a digital tipping platform that ensures the most
        deserving service workers are rewarded. To learn more, Visit"
      /> */}
          <div
            onClick={() => {
              navigate("/invite");
            }}
            className="Small_Button h-24"
          >
            <div className={`smallCircle bg-primary-green`}>
              <img
                src={inviteSacco}
                alt="Deposit Button"
                className="w-full h-full"
              />
            </div>

            <div className="test-sm mt-1 w-16 text-center">Invite A Friend</div>
          </div>
        </div>
      )}
      <div
        className={`flex flex-row justify-center items-end
      ${userInfo?.user_type === "TIPPER" ? "mt-12" : "-mt-3"}
      px-12 z-40
    `}
      >
        {/* <Share
        setShareApiErr={setShareApiErr}
        title="Have you heard of Shukran?"
        text="Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit"
      /> */}
        {userInfo.user_type !== "TIPPER" && (
          <a
            href={encodeUrl}
            target="_blank"
            rel="noreferrer"
            className="Small_Button h-24 cursor-pointer"
          >
            <div className="smallCircle bg-[#8A64E8]">
              <img
                src={customerSupport}
                alt="Tip Button"
                className="h-10 w-10"
              />
            </div>
            <p className="test-sm mt-1">Customer Support</p>
          </a>
        )}
      </div>
      <AlertDialog defaultOpen={userInfo?.user_type == "SERVICE WORKER"}>
        {/* <AlertDialogTrigger asChild>
          <Button variant="outline">Show Dialog</Button>
        </AlertDialogTrigger> */}
        <AlertDialogContent>
          <div class="container mx-auto px-4 py-12">
            <div class="bg-white rounded-lg shadow-lg overflow-hidden">
              <img
                src={require("../../assets/Images/sacco-icon.png")}
                alt="App Banner"
                class="w-full h-64 object-cover"
              ></img>
            </div>

            <div class="bg-white mt-8 rounded-lg shadow-lg p-8 text-center">
              <h1 class="text-4xl font-bold text-gray-800">
                Introducing Our New App
              </h1>
              <p class="mt-4 text-gray-600">
                Discover the amazing features and seamless user experience of
                our brand new sacco app. Available now on the Play Store and App
                Store.
              </p>

              <div class="mt-8 flex justify-center space-x-4">
                <a
                  href="https://play.google.com/store/apps/details?id=com.sacco.shukran"
                  target="_blank"
                  class="bg-accent text-white font-bold py-2 px-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300"
                >
                  <svg
                    class="w-6 h-6 inline-block mr-2"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M3.89,4.63,12,11.8V2.54L5.71,1.24A1.47,1.47,0,0,0,3.89,4.63ZM20.22,2.54c-.2-.12-.39-.24-.6-.34L14.21,7.45l4.34,3.5Zm-2,2.87L13.27,8.44l6.49,5.27A1.48,1.48,0,0,0,19.83,10.29ZM14.21,16.55l5.41,4.56c.22-.09.41-.21.6-.33L12,13.8V21.46l7.29-1.3A1.48,1.48,0,0,0,20.22,16.55ZM3.89,19.37A1.47,1.47,0,0,0,5.71,21.46L12,20.21V12.19L3.89,19.37Z" />
                  </svg>
                  Play Store
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sacco.shukran"
                  target="_blank"
                  class="bg-primary text-white font-bold py-2 px-4 rounded-full shadow-lg hover:bg-blue-600 transition duration-300"
                >
                  <svg
                    class="w-6 h-6 inline-block mr-2"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18.87,13.31a7.25,7.25,0,0,1-4.22,6.82c-.79.35-1.12.6-2.12.6s-1.33-.23-2.11-.61c-1.81-.8-2.91-2.27-3.5-4.18A7.68,7.68,0,0,1,6,15.63c.61-.56,1.24-.77,1.81-.46a1.27,1.27,0,0,0,1.33-.1,4.28,4.28,0,0,1,2.56-.47,4.23,4.23,0,0,1,1.84,1.24,4.52,4.52,0,0,0,1.9,1.25,6.7,6.7,0,0,0,2.07.27c-.22-.78-.35-1.48-.35-2.12A4.75,4.75,0,0,1,18.87,13.31ZM17.41,2a5.23,5.23,0,0,0-3.63,1.77A4.73,4.73,0,0,0,12.57,5.91,5.24,5.24,0,0,0,14,7.68,5.15,5.15,0,0,0,17.41,2Z" />
                  </svg>
                  App Store
                </a>
              </div>
              <div class="flex justify-center mt-8">
                <AlertDialogFooter>
                  <AlertDialogCancel>Close</AlertDialogCancel>
                </AlertDialogFooter>
              </div>
            </div>
          </div>
          {/* <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction>Continue</AlertDialogAction>
          </AlertDialogFooter> */}
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

export default DashboardActions;
