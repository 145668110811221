import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components-shadcn/ui/select";
import KenyanFlag from "../../assets/Icons/Tipping/kenya.png";
import USAFlag from "../../assets/Icons/Tipping/united-states.png";

const SelectCurrency = () => {
  const { setSelectedCurency } = useTipJarLocalState();
  const [currency, setCurrency] = useState("KES");

  useEffect(() => {
    setSelectedCurency(currency);
  }, [currency]);

  // Get flag based on selected currency
  const getFlag = () => {
    return currency === "KES" ? KenyanFlag : USAFlag;
  };

  return (
    <Select value={currency} onValueChange={setCurrency}>
      <SelectTrigger className="w-[120px] mb-4">
        <div className="flex items-center gap-2">
         
          <SelectValue placeholder="Currency" />
        </div>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="KES">
          <div className="flex items-center gap-2">
            <img 
              src={KenyanFlag} 
              alt="Kenyan flag" 
              className="w-6 h-4 object-cover rounded-sm"
            />
            <span>KES</span>
          </div>
        </SelectItem>
        <SelectItem value="USD">
          <div className="flex items-center gap-2">
            <img 
              src={USAFlag} 
              alt="USA flag" 
              className="w-6 h-4 object-cover rounded-sm"
            />
            <span>USD</span>
          </div>
        </SelectItem>
      </SelectContent>
    </Select>
  );
};

export default SelectCurrency;