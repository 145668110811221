import { useTipJarLocalState } from "../../../src/components/Tipjar/state/local-state/useTipjarLocalState";
import { useFetchTipJarReports } from "../../../src/components/Tipjar/state/server-state/useTipJarServerState";
import { useFetchEntityTransactions } from "../../../src/components/Tipjar/state/server-state/useTipJarServerState";
import { Card } from "../../components-shadcn/ui/card";
import { Skeleton } from "../../components-shadcn/ui/skeleton";
import React, { useState, useEffect } from "react";
import { ChevronDown, ChevronUp, Send,ArrowBigLeftDash, ArrowBigRightDash } from "lucide-react";
import { useNavigate } from "react-router-dom";

import useWindowDimensions from "../../../src/hooks/useWindowDimensions";
import { toast } from "sonner";

const mergeAndSortReportsAndTransactions = (reports, transactions, entityJARs,setEntityTransactions) => {
  const transformedTransactions = transactions.map(transaction => {
    const matchingJar = entityJARs?.find(jar => jar.shukran_id === transaction.shukran_id);

    return {
      entity_jar: matchingJar || {
        name: "Transaction", 
      },
      total_distribution_amount: transaction.amount,
      created_at: transaction.created_at,
      isTransaction: true,
      transactionData: {
        ...transaction,
        entityJarDetails: matchingJar
      }
    };
  });

  setEntityTransactions(transformedTransactions);
  const combined = [...reports, ...transformedTransactions];
  return combined
};

const DashboardReports = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();
  
  const {
    activeEntity,
    tipjarReport,
    setActiveTipJarReport,
    setActiveEntityTransaction,
    setTipJarReport,
    entityTransactions,
    entityJARs,
    setEntityTransactions, 
    setActiveEntityJAR,
  } = useTipJarLocalState();

  const [displayedReports, setDisplayedReports] = useState([]);
  const [transactions, setTransactions]=useState(entityTransactions);
  const fetchReports = useFetchTipJarReports();
  const fetchEntityTransactions = useFetchEntityTransactions();
  

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(date).toLowerCase();
    } catch (error) {
      return "";
    }
  };

  const handleFetchReports = async () => {
    try {
      const response = await fetchReports.mutateAsync({
        entity_id: activeEntity.id,
      });
      setTipJarReport(response.data || []);
    } catch (error) {
      setTipJarReport([]);
      console.log("error", error);
      toast.error(`Error fetching reports: ${error}`);
    }
  };

  const handleFetchEntityTransactions = async () => {
    try {
      const response = await fetchEntityTransactions.mutateAsync({
        entity_id: activeEntity.id,
      });
      setTransactions(response.data || []);
    } catch (error) {
      setTransactions([]);
      console.log("error", error);
      toast.error(`Error fetching tips: ${error}`);
    }
  };

  useEffect(() => {
    if (!activeEntity || !activeEntity?.id) return;
    handleFetchReports();
    handleFetchEntityTransactions();
  }, [activeEntity]);

  useEffect(() => {
    if (tipjarReport && transactions && entityJARs) {
      const combinedData = mergeAndSortReportsAndTransactions(
        tipjarReport, 
        transactions, 
        entityJARs,
        setEntityTransactions
      );
      setDisplayedReports(combinedData);
    }
  }, [tipjarReport, transactions, entityJARs]);

  const handleReportClick = (reportData) => {
    setActiveTipJarReport(reportData);
    const entityJar=reportData.entity_jar;
    const entityJarId=entityJar.id;
    const matchingJar = entityJARs?.find(jar => jar.id === entityJarId);
    setActiveEntityJAR(matchingJar);
    navigate(`/disbursment-overview/${entityJarId}`);
  };

  const handleTransactionClick = (transactionData) => {
    const entityJar=transactionData.entity_jar;
    const entityJarId=entityJar.id;
    setActiveEntityTransaction([transactionData]);
    navigate(`/tipjar/report/${entityJarId}`);
  }

  // Get items to display based on expanded state
  const itemsToShow = isExpanded ? displayedReports : displayedReports.slice(0, 1);

  return (
    <div
      className={`${
        height > 800 && "fixed bottom-0"
      } w-full max-w-[600px] bg-transparent bg-white transition-all duration-300 ease-in-out rounded-tl-lg rounded-tr-lg
          ${isExpanded ? "h-[40vh]" : "h-auto"}`}
      style={{
        background: `${isExpanded ? "rgba(255, 255, 255, 0.9)" : "transparent"}`,
      }}
    >
      <div className="flex justify-between items-center px-4 py-3 border-b">
        <p className="text-sm font-semibold text-gray-700">HIGHLIGHTS</p>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center text-[#6CCBD1] text-sm font-medium"
        >
          SHOW {isExpanded ? "LESS" : "MORE"}
          {isExpanded ? (
            <ChevronDown className="ml-1 h-4 w-4" />
          ) : (
            <ChevronUp className="ml-1 h-4 w-4" />
          )}
        </button>
      </div>

      <div
        className={`overflow-y-auto px-4 transition-all duration-300
            ${isExpanded ? "max-h-[calc(50vh-60px)]" : "max-h-[120px] overflow-hidden"}`}
      >
        {fetchReports.isLoading || fetchEntityTransactions.isLoading ? (
          <div className="flex justify-center items-center h-24">
            <span className="text-gray-500">Loading...</span>
          </div>
        ) : displayedReports.length > 0 ? (
          itemsToShow.map((item, index) => (
            <Card
              key={index}
              className="my-3 bg-[#F3FAF9] border-none shadow hover:shadow-md transition-shadow duration-200"
              onClick={() => item.isTransaction ? handleTransactionClick(item) : handleReportClick(item)}
            >
              <div className="flex items-center justify-between p-4">
                <div className="flex items-center space-x-3">
                  <div className="w-8 h-8 rounded-full flex items-center justify-center">
                    {item.isTransaction ? (
                      <ArrowBigRightDash 
                        size={28} 
                        color="#90EE90" 
                      />
                    ) : (
                      <ArrowBigLeftDash 
                        size={28} 
                        color="orange"
                      />
                    )}
                  </div>
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-900 text-sm sm:text-base">
                      {item?.isTransaction ? item?.transactionData&&"Tip" : "Distribution"}
                    </span>
                    <span className="text-gray-500 text-xs sm:text-sm">
                      {item?.entity_jar?.name}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col items-end">
                  <span className="font-medium text-gray-900 text-sm sm:text-base">
                    {item?.isTransaction ? `${item?.transactionData?.currency} ` : 'KSH '}
                    {item?.total_distribution_amount?.toLocaleString()}
                  </span>
                  <span className="text-gray-500 text-xs sm:text-sm">
                    {formatDate(item?.created_at)}
                  </span>
                </div>
              </div>
            </Card>
          ))
        ) : (
          <div className="flex justify-center items-center h-24">
            <span className="text-gray-500">No notifications</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardReports;
