import React, { useState, useEffect } from "react";
import { Input } from "../../../components-shadcn/ui/input";
import { Button } from "../../../components-shadcn/ui/button";
import { FaCircleCheck } from "react-icons/fa6";
import { MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { X, Check, Loader2 } from "lucide-react";
import BackButton from "../../globals/BackButton";
import { Checkbox } from "../../../components-shadcn/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components-shadcn/ui/dialog";
import { toast } from "sonner";
import { useTipJarLocalState } from "../state/local-state/useTipjarLocalState";
import { useDisbursePointSystem } from "../../TipGroups/state/server-state/useTipGroupsServerState";
import { useGetDistributionPointsData } from "../state/server-state/useTipJarServerState";
import { Switch } from "../../../components-shadcn/ui/switch";
import { useFetchSingleJarMutation, useGetJARsMutation } from "../../TipGroups/state/server-state/useTipGroupsServerState";
const ConfirmTipRecipients = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const {
    activeEntity,
    activeEntityJAR,
    amountToDisburseFromDefaultJar,
    setAmountToDisburseFromDefaultJar,
    setActiveEntityJAR
  } = useTipJarLocalState();

  // State management
  const [checkedMembers, setCheckedMembers] = useState({});
  const [originalPoints, setOriginalPoints] = useState({});
  const [tipJARMembers, setTipJARMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [customPoints, setCustomPoints] = useState(0);
  const [distributionPointsData, setDistributionPointsData] = useState(null);
  const [disbursementPayload, setDisbursementPayload] = useState(null);
  const [jarBalance, setJarBalance] = useState(0);
  const [valuePerPoint, setValuePerPoint] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [isAmountMode, setIsAmountMode] = useState(false);
  const [customAmount, setCustomAmount] = useState(0);
  const [membersWithCustomAmount, setMembersWithCustomAmount] = useState({});
  const [remainingBalance, setRemainingBalance] = useState(0);
  const fetchSingleJarMutation=useFetchSingleJarMutation()
  const fetchJarsMutation=useGetJARsMutation()
  const isFetchingJars=fetchJarsMutation.isLoading

  // Distribution state calculation
  const [distributionState, setDistributionState] = useState({
    totalFixedAmount: 0,
    availableForPoints: 0,
    valuePerPoint: 0,
  });

  const disbursePointSystem = useDisbursePointSystem();
  const isDisbursingPointSystem = disbursePointSystem.isLoading;
  const getDistributionPointsData = useGetDistributionPointsData();

  const handleFetchJar=async()=>{
    try{
      const response=await fetchSingleJarMutation.mutateAsync(activeEntityJAR.id)
      if(response===403){
        toast.error("Your session has expired, please sign in again")
        navigate("/login/")
        return
      }
      console.log("SINGLE JAR FETCH ", response)
    }catch(error){
      console.log("FETCH JAR ERROR", error)
    }
  }

  const handleFetchJars=async()=>{
    try{
      const response=await fetchJarsMutation.mutateAsync(activeEntity.id)
      if(response===403){
        toast.error("Your session has expired, please sign in again")
        navigate("/login/")
        return
      }
      const jars=response?.data?.data
      if(!jars){
        return
      }
      const activeJar=jars.find((el)=>el?.id===activeEntityJAR.id)
      setActiveEntityJAR(activeJar)
      
    }catch(error){
      console.log("FETCH JAR ERROR", error)
    }
  }

  // useEffect(()=>{
  //   if(!activeEntityJAR||!activeEntityJAR?.id)return
  //   handleFetchJar()
  // },[activeEntityJAR])

    useEffect(()=>{
    if(!activeEntity||!activeEntity?.id)return
    handleFetchJars()
  },[activeEntity])

  

  // Initialize data
  useEffect(() => {
    if (activeEntityJAR?.members) {
      const members = activeEntityJAR?.members?.filter((member)=>member.can_join_entity_jar===true).map((member) => ({
        ...member,
        group_disbursement_points: activeEntityJAR.is_default_entity_jar
          ? member.group_disbursement_points
          : member.group_disbursement_points,
      }));

      const initialCheckedState = {};
      const initialPoints = {};
      members.forEach((member) => {
        initialCheckedState[member.id] = true;
        initialPoints[member.id] =
          member.customized_disbursement_points ||
          (activeEntityJAR.is_default_entity_jar
            ? member.group_disbursement_points
            : member.group_disbursement_points);
      });

      setTipJARMembers(members);
      setFilteredMembers(members);
      setCheckedMembers(initialCheckedState);
      setOriginalPoints(initialPoints);
    }
  }, [activeEntityJAR]);

  // Set initial jar balance
  useEffect(() => {
    if (amountToDisburseFromDefaultJar) {
      setJarBalance(amountToDisburseFromDefaultJar);
      setRemainingBalance(amountToDisburseFromDefaultJar);
    } else {
      setJarBalance(activeEntityJAR?.jar_balance || 0);
      setRemainingBalance(activeEntityJAR?.jar_balance || 0);
    }
  }, [activeEntityJAR?.jar_balance, amountToDisburseFromDefaultJar]);

  // Fetch distribution points data
  useEffect(() => {
    const fetchDistributionData = async () => {
      if (activeEntityJAR?.id) {
        try {
          const response = await getDistributionPointsData.mutateAsync({
            entity_jar: activeEntityJAR.id,
          });
          setDistributionPointsData(response.data);
        } catch (error) {
          toast.error("Failed to fetch distribution data");
        }
      }
    };
    fetchDistributionData();
  }, [activeEntityJAR]);

  // Filter members based on search
  useEffect(() => {
    const filtered = tipJARMembers.filter((member) =>
      `${member?.user?.first_name} ${member?.user?.last_name}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredMembers(filtered);
  }, [searchTerm, tipJARMembers]);

  const calculateTotalPoints = () => {
    return tipJARMembers.reduce((sum, member) => {
      if (!checkedMembers[member.id]) return sum;
      // Skip members with custom amounts
      if (membersWithCustomAmount[member.id]) return sum;
      const points =
        member.customized_disbursement_points || originalPoints[member.id];
      return sum + points;
    }, 0);
  };

  const handleCustomAmountChange = (value) => {
    const parsedValue = Number(value);
    console.log("Parsed value: ", parsedValue);
    const amount = Math.min(
      Math.max(0, parseFloat(parsedValue) || 0),
      remainingBalance + (membersWithCustomAmount[selectedMember?.id] || 0)
    );
    console.log("setCustomAmount: ", amount.toString());
    setCustomAmount(amount.toString());
  };

  const recalculateDistributions = () => {
    // Only sum custom amounts from checked members
    const totalCustomAmount = Object.entries(membersWithCustomAmount).reduce(
      (sum, [memberId, amount]) => {
        // Only include amount if member is checked
        return checkedMembers[memberId] ? sum + amount : sum;
      },
      0
    );

    const balanceForPoints = jarBalance - totalCustomAmount;
    const newTotalPoints = calculateTotalPoints();
    const newValuePerPoint =
      newTotalPoints > 0 ? balanceForPoints / newTotalPoints : 0;

    setRemainingBalance(balanceForPoints);
    setTotalPoints(newTotalPoints);
    setValuePerPoint(newValuePerPoint);
  };

  useEffect(() => {
    recalculateDistributions();
  }, [checkedMembers, tipJARMembers, jarBalance, membersWithCustomAmount]);

  const handleBack = () => {
    setAmountToDisburseFromDefaultJar(null);
    navigate(-1);
  };

  const handleCheckboxChange = (memberId, checked) => {
    setCheckedMembers((prev) => ({
      ...prev,
      [memberId]: checked,
    }));

    const updatedMembers = tipJARMembers.map((member) => {
      if (member.id === memberId) {
        return {
          ...member,
          customized_disbursement_points: checked
            ? originalPoints[memberId]
            : 0,
        };
      }
      return member;
    });

    setTipJARMembers(updatedMembers);
  };

  const openCustomizeModal = (member) => {
    setSelectedMember(member);
    setCustomPoints(
      member.customized_disbursement_points || originalPoints[member.id] || 0
    );
    setIsModalOpen(true);
  };

  const handleCustomPointsChange = (value) => {
    setCustomPoints(Math.max(0, Math.min(10, parseInt(value) || 0)));
  };

  const saveCustomPoints = () => {
    if (isAmountMode) {
      // Save custom amount
      setMembersWithCustomAmount((prev) => ({
        ...prev,
        [selectedMember.id]: Number(customAmount),
      }));
      // Clear points for this member
      setOriginalPoints((prev) => ({
        ...prev,
        [selectedMember.id]: 0,
      }));
    } else {
      // Remove any custom amount for this member
      const { [selectedMember.id]: _, ...restCustomAmounts } =
        membersWithCustomAmount;
      setMembersWithCustomAmount(restCustomAmounts);
      // Save points
      setOriginalPoints((prev) => ({
        ...prev,
        [selectedMember.id]: customPoints,
      }));
    }

    const updatedMembers = tipJARMembers.map((member) => {
      if (member.id === selectedMember.id) {
        return {
          ...member,
          customized_disbursement_points: isAmountMode ? 0 : customPoints,
        };
      }
      return member;
    });

    setTipJARMembers(updatedMembers);
    setIsModalOpen(false);
    toast.success(
      isAmountMode
        ? "Custom amount set successfully"
        : "Points customized successfully"
    );
  };

  // Update the modal content to include the switch
  const renderCustomizeModal = () => (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogContent className="sm:max-w-[425px] rounded-3xl">
        <DialogHeader>
          <DialogTitle className="flex justify-between items-center">
            <span
              className="text-lg font-semibold"
              style={{ color: "#50A2A7" }}
            >
              Customize {isAmountMode ? "amount" : "points"}
            </span>
          </DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <div className="flex items-center mb-4">
            <div className="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full mr-3">
              <p className="text-sm font-semibold text-white">
                {selectedMember?.user?.first_name[0]}
              </p>
            </div>
            <div>
              <p className="font-semibold">
                {`${selectedMember?.user?.first_name} ${selectedMember?.user?.last_name}`}
              </p>
              <p className="text-sm text-gray-500">
                {selectedMember?.user?.shukran_id}
              </p>
            </div>
          </div>

          {/* Add mode switch */}
          <div className="flex items-center justify-between mb-4 p-2 bg-gray-50 rounded-lg">
            <span className="text-sm font-medium">Customize by amount</span>
            <Switch
              checked={isAmountMode}
              onCheckedChange={(checked) => {
                setIsAmountMode(checked);
                if (checked) {
                  setCustomAmount(Math.floor(customPoints * valuePerPoint));
                } else {
                  setCustomPoints(originalPoints[selectedMember.id] || 0);
                }
              }}
            />
          </div>

          {isAmountMode ? (
            // Amount input
            <div className="mb-4">
              <Input
                type="number"
                value={customAmount}
                onChange={(e) => handleCustomAmountChange(e.target.value)}
                className="text-center"
                placeholder="Enter amount"
              />
              <p className="text-center text-sm text-gray-500 mt-2">
                Remaining balance after: KES{" "}
                {(
                  remainingBalance -
                  customAmount +
                  (membersWithCustomAmount[selectedMember?.id] || 0)
                ).toLocaleString("en-US")}
              </p>
            </div>
          ) : (
            // Points input
            <div className="flex items-center justify-between mb-4">
              <Button
                onClick={() => handleCustomPointsChange(customPoints - 1)}
                className="bg-[#F2C773B2]"
              >
                -
              </Button>
              <Input
                type="number"
                value={customPoints}
                onChange={(e) => handleCustomPointsChange(e.target.value)}
                className="mx-2 text-center"
              />
              <Button
                onClick={() => handleCustomPointsChange(customPoints + 1)}
                className="bg-[#F2C773B2]"
              >
                +
              </Button>
            </div>
          )}

          <p className="text-center mb-4">
            {isAmountMode
              ? `Fixed amount: KES ${customAmount.toLocaleString("en-US")}`
              : `Tip amount: KES ${Math.floor(
                  customPoints * valuePerPoint
                ).toLocaleString("en-US")}`}
          </p>

          <Button
            className="w-full h-[60px] rounded-full font-bold text-white"
            style={{ backgroundColor: "#F2C773" }}
            onClick={saveCustomPoints}
          >
            SAVE CHANGES
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );

  // Update the member list rendering to show custom amounts
  const renderMemberAmount = (member) => {
    if (membersWithCustomAmount[member.id]) {
      return `KES ${membersWithCustomAmount[member.id].toLocaleString(
        "en-US"
      )} (Fixed)`;
    }
    const points =
      member.customized_disbursement_points || originalPoints[member.id];
    return `${points} points - KES ${Math.floor(
      points * valuePerPoint
    ).toLocaleString("en-US")}`;
  };

  const handleProceed = () => {
    if (!activeEntityJAR) {
      toast.error("Entity JAR not found");
      return;
    }
    if (jarBalance === 0) {
      toast.error("Your Tipjar balance is KES 0/=");
      return;
    }

    const payload = {
      entity_jar: activeEntityJAR.id,
      distribution_amounts: tipJARMembers
        .filter((member) => checkedMembers[member.id])
        .map((member) => {
          const distributionUser = distributionPointsData.find(
            (data) => data.user.shukran_id === member.user.shukran_id
          );

          let amount;
          if (membersWithCustomAmount[member.id]) {
            amount = membersWithCustomAmount[member.id];
          } else {
            const points =
              member.customized_disbursement_points ||
              originalPoints[member.id];
            amount = Math.floor(points * valuePerPoint);
          }

          return {
            user_id: distributionUser ? distributionUser.user.id : null,
            amount: amount,
          };
        })
        .filter((item) => item.user_id !== null),
    };

    setDisbursementPayload(payload);
    setIsConfirmModalOpen(true);
  };

  const handleSendRequestClick = async () => {
    try {
      await disbursePointSystem.mutateAsync(disbursementPayload);
      setIsConfirmModalOpen(false);
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        navigate("/tipjar/tipjar-group/");
      }, 3000);
    } catch (error) {
      toast.error("Failed to send request");
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white px-2">
      <BackButton onPress={handleBack} />

      <h1 className="text-2xl font-bold mt-6 mb-2" style={{ color: "#50A2A7" }}>
        Confirm tip recipients
      </h1>
      <p className="text-gray-500 mb-4">
        {`Customize members to include in the Group TipJar distribution of KES ${
          jarBalance?.toLocaleString() || 0
        }`}
      </p>

      <Input
        placeholder="Search name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 h-[55px] rounded-full shadow-md"
        style={{ borderColor: "#6CCBD1" }}
      />

      <div className="flex justify-between items-center mb-2">
        <span className="font-semibold">
          Tip JAR members ({tipJARMembers.length})
        </span>
      </div>

      <div className="flex-1 overflow-auto">
        {filteredMembers?.filter((el)=>el?.can_join_entity_jar).map((member) => (
          <div
            key={member.id}
            className="flex items-center justify-between mb-4 p-3 rounded-lg"
            style={{ border: "1px solid #F5F5F5" }}
          >
            <div className="flex items-center">
              <div className="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full mr-3">
                <p className="text-sm font-semibold text-white">
                  {member?.user?.first_name[0]}
                </p>
              </div>
              <div>
                <p className="font-semibold">{`${member?.user?.first_name} ${member?.user?.last_name}`}</p>
                <p className="text-sm text-gray-500">
                  {checkedMembers[member.id]
                    ? renderMemberAmount(member)
                    : "0 points - KES 0"}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <Button
                variant="outline"
                size="sm"
                className="mr-2 flex items-center"
                style={{
                  borderColor: checkedMembers[member.id]
                    ? "#E9B44C"
                    : "#E5E5E5",
                  color: checkedMembers[member.id] ? "#E9B44C" : "#E5E5E5",
                }}
                onClick={() => openCustomizeModal(member)}
                disabled={!checkedMembers[member.id]}
              >
                Customize
                <MdOutlineEdit
                  className="ml-1"
                  style={{
                    color: checkedMembers[member.id] ? "red" : "#E5E5E5",
                  }}
                />
              </Button>
              <Checkbox
                checked={checkedMembers[member.id]}
                onCheckedChange={(checked) =>
                  handleCheckboxChange(member.id, checked)
                }
                className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
              />
            </div>
          </div>
        ))}
      </div>

      <Button
        className="mt-4 mb-7 bg-[#F2C773] text-white font-bold rounded-full h-[60px]"
        onClick={handleProceed}
        disabled={isDisbursingPointSystem||isFetchingJars}
      >
        {isDisbursingPointSystem||isFetchingJars ? "Processing..." : "PROCEED"}
      </Button>

      {/* Customize Points Modal */}
      {renderCustomizeModal()}

      {/* Confirmation Modal */}
      <Dialog open={isConfirmModalOpen} onOpenChange={setIsConfirmModalOpen}>
        <DialogContent className="sm:max-w-[425px] rounded-3xl">
          <DialogHeader>
            <DialogTitle
              className="text-lg font-semibold text-center"
              style={{ color: "#50A2A7" }}
            >
              Confirm details
            </DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-center mb-2">
              Amount: KES {jarBalance.toLocaleString("en-US")}
            </p>
            <p className="text-center mb-2">
              {`Tip distribution: ${
                disbursementPayload?.distribution_amounts?.length||0

              } individual(s)`}
            </p>
            {/* <p className="text-center mb-2">
              Total individuals: {tipJARMembers.length}
            </p> */}
            <p className="text-center mb-4">From: {activeEntityJAR.name}</p>
            <Button
              className="w-full h-[60px] rounded-full font-bold text-white"
              style={{ backgroundColor: "#F2C773" }}
              onClick={handleSendRequestClick}
              disabled={isDisbursingPointSystem}
            >
              {isDisbursingPointSystem ? "PROCESSING..." : "SEND REQUEST"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Success Modal */}
      <Dialog open={isSuccessModalOpen} onOpenChange={setIsSuccessModalOpen}>
        <DialogContent className="sm:max-w-[425px] rounded-3xl">
          <div className="py-4 text-center">
            <Check className="mx-auto mb-4 h-12 w-12 text-green-500" />
            <h2 className="text-lg font-semibold mb-2">
              Request sent successfully!
            </h2>
            <p className="text-sm text-gray-500">
              Your disbursement will be processed by your manager for approval
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmTipRecipients;
