import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { persistor, store, storePersist } from "./state/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AlertOctagon, RefreshCcw } from 'lucide-react';
import { Button } from "./components-shadcn/ui/button";
import { PersistGate } from "redux-persist/integration/react";
import { useNavigate } from "react-router-dom";

Sentry.init({
  dsn: "https://4ba8e2e04e2c4aa2976adfe2fe4c7d2b@o461119.ingest.sentry.io/4505194064904192",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const ErrorPage = ({ 
  title = "Oops! Something went wrong",
  message = "We're having trouble loading this page",
  showRefresh = true,
  onRefresh,
  customAction
}) => {
  const navigate=useNavigate()
  const handleRefresh = () => {
    if (onRefresh) {
      onRefresh();
    } else {
      window.location.reload();
    }
  };

  const handleNavigateToLogin=()=>{
    navigate("/login/")
    handleRefresh()
  }



  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <div className="text-center max-w-md">
        <AlertOctagon className="w-16 h-16 mx-auto mb-4" color="red" />
        
        <h1 className="text-xl font-semibold text-gray-700 mb-2 upercase">
          {title}
        </h1>
        
        <p className="text-gray-500 mb-8">
          {message}
        </p>

        <div className="space-y-4">
          {showRefresh && (
            <Button
              onClick={handleRefresh}
              className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-white rounded-full h-12"
            >
              <RefreshCcw className="w-4 h-4 mr-2" />
              <p className="text-slate-600"> Refresh Page </p>
            </Button>
          )}

          {customAction}
        </div>
        <p className="mt-6 text-sm underline cursor-pointer" onClick={handleNavigateToLogin}>Back to login</p>
      </div>
    </div>
  );
};

function FallbackComponent() {
  return (
    <div className="flex justify-center align-center text-red-600 font-bolder">
      <ErrorPage />
    </div>
  );
}


const myFallback = <FallbackComponent />;

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store()}>
      <PersistGate loading={null} persistor={persistor}>
        <Sentry.ErrorBoundary fallback={myFallback} showDialog>
          <App />
        </Sentry.ErrorBoundary>
      </PersistGate>
    </Provider>
  </BrowserRouter>,

  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
