export const TipJarQueryTypes = {
  CREATE_GROUP: "createGroup",
  UPDATE_GROUP: "updateGroup",
  ADD_MEMBER: "addMember",
  REMOVE_MEMBER: "removeMember",
  GET_MAIN_TIPJAR_INFO: "getMainTipjarInfo",
  FETCH_ENTITY_MEMBERS: "fetchEntityMebers",
  ADD_MEMBER_TO_ENTITY: "addMemberToEntity",
  ADD_MEMBERS_TO_ENTITY: "addMembersToEntity",
  GET_DISTRIBUTION_POINTS_DATA: "getDistributionPointsData",
  REMOVE_MEMBERS_FROM_ENTITY: "removeMembersFromEntity",
  UPDATE_ENTITY_JAR: "updateEntityJar",
  DEACTIVATE_ENTITY_JAR: "deactivateEntityJar",
  ACTIVATE_ENTITY_JAR: "activateEntityJar",
  FETCH_TIPJAR_REPORTS: "fetchEntityReports",
  FETCH_ENTITY_TRANSACTIONS: "fetchEntityTransactions",
  TRANSFER_FROM_TIPJAR: "transferFromTipJar",
};
