import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components-shadcn/ui/button";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import loader from "../../../assets/Icons/Registration/loader.svg";
import { X, Search, OctagonAlert } from "lucide-react";
import { useSearchShukranMembers } from "../../../shared/server-state/useSearchShukranMembers";
import { useTipJarLocalState } from "../state/local-state/useTipjarLocalState";
import { useAddMemeberToGroup } from "../state/server-state/useTipJarServerState";
import BackButton from "../../globals/BackButton";
import { Input } from "../../../components-shadcn/ui/input";
import { Checkbox } from "../../../components-shadcn/ui/checkbox";

const AddGroupMembers = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndividuals, setSelectedIndividuals] = useState([]);
  const { activeEntity, activeEntityJAR, activeEntityJARGroup } =
    useTipJarLocalState();

  const {
    data: shukranMembers,
    isLoading: isLoadingShukranMembers,
    error: shukranMembersError,
  } = useSearchShukranMembers({ searchQuery: searchQuery });

  const addGroupMembers = useAddMemeberToGroup();
  const isAddingGroupMembers = addGroupMembers.isLoading;

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const toggleIndividual = (individual) => {
    setSelectedIndividuals((prev) =>
      prev.some((i) => i.id === individual.id)
        ? prev.filter((i) => i.id !== individual.id)
        : [...prev, individual]
    );
  };

  const handleAddMembersToGroup = async (payload) => {
    try {
      const response = await addGroupMembers.mutateAsync(payload);

      toast.success("Member(s) added successfully");
    } catch (error) {
      toast.error("Failed to add members");
    }
  };

  const handleConfirmMembersClicked = () => {
    if (!selectedIndividuals || selectedIndividuals.length <= 0) {
      toast.error("Select Some members");
      return;
    }
    if (!activeEntityJAR) {
      toast.error("Some data is missing");
    }

    const entityJARId = activeEntityJAR.id;

    const members = selectedIndividuals.map((individual) => {
      return {
        shukran_id: individual.shukran_id,
        group_id: activeEntityJARGroup.id,
      };
    });

    const payload = {
      entity_jar: entityJARId,
      shukran_ids: members,
    };
    handleAddMembersToGroup(payload);
  };

  return (
    <div className="flex flex-col px-3 w-full h-screen px-2">
      <div className="w-full p-0">
        <BackButton
          onPress={() => {
            handleBack();
          }}
        />
      </div>

      <h1 className="text-2xl font-semibold text-[#6CCBD1] mb-1 mt-6">
        Set up group
      </h1>
      <p className="text-sm text-gray-500 mb-6">
        Select team members to add to group
      </p>

      <p className="mb-2">
        Add members: {selectedIndividuals.length} selected
      </p>

      {selectedIndividuals.length > 0 && (
        <div className="flex flex-wrap gap-2 mb-4">
          {selectedIndividuals.map((individual) => (
            <Button
              key={individual.id}
              variant="secondary"
              className="bg-[#CBE3E5] text-white rounded-full"
              onClick={() => toggleIndividual(individual)}
            >
              {individual.first_name} <X size={16} className="ml-2" />
            </Button>
          ))}
        </div>
      )}
      <div className="relative mb-4">
        <Search
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          size={20}
        />
        <Input
          className="pl-10 pr-4 w-full border-2 border-[#6CCBD1] rounded-full h-[55px]"
          placeholder="Search name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <div className="flex-grow overflow-auto">
        {!shukranMembers ? (
          <div className="flex flex-col w-full h-[200px] items-center gap-2 justify-center">
            <OctagonAlert size={30} color="red" />
            <p>Start typing to search members</p>
          </div>
        ) : (
          <>
            {shukranMembers?.data?.results.length > 0 ? (
              <>
                {shukranMembers?.data?.results.map((individual) => (
                  <div
                    key={individual.id}
                    className={`flex items-center p-4 mb-2 rounded-lg border border-[#F5F5F5]`}
                    onClick={() => toggleIndividual(individual)}
                  >
                    <div className="w-10 h-10 rounded-full bg-[#6CCBD1] text-white flex items-center justify-center mr-4">
                      {individual.id}
                    </div>

                    <div className="flex-grow">
                      <p className="font-semibold">{`${individual.first_name} ${individual.last_name}`}</p>
                      <p className="text-sm text-gray-500">
                        {individual.shukran_id}
                      </p>
                    </div>
                    <Checkbox
                      checked={selectedIndividuals.some(
                        (i) => i.id === individual.id
                      )}
                      onCheckedChange={() => toggleIndividual(individual)}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="flex flex-col w-full h-[200px] items-center gap-2">
                  <OctagonAlert size={30} color="red" />
                  <p>No such member</p>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className="mt-auto mb-7" onClick={handleConfirmMembersClicked}>
        <Button className="w-full bg-[#F2C773] hover:bg-yellow-500 text-white py-6 rounded-full font-semibold h-[60px] font-semibold ">
          CONFIRM MEMBERS
        </Button>
      </div>
      <div
        className={`${isAddingGroupMembers ? "absolute blur-sm" : "hidden"}
        flex justify-center items-center w-full h-full
        `}
      >
        <img
          src={loader}
          alt="loader"
          className={`animate-spin h-8 mx-auto text-sm mt-1`}
        />
      </div>
    </div>
  );
};

export default AddGroupMembers;
