import { Select, SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,} from "../../../components-shadcn/ui/select";


    const EntitySelector = ({ entities, selectedEntity, onEntitySelect }) => {
        return (
          <Select value={selectedEntity?.id} onValueChange={onEntitySelect}>
            <SelectTrigger className="w-[250px]">
              <SelectValue placeholder="Select an entity" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {entities.map((entity) => (
                  <SelectItem 
                    key={entity.id} 
                    value={entity.id}
                    className="flex justify-between items-center"
                  >
                    <div className="flex justify-between items-center w-full gap-2">
                      <span>{entity.name}</span>
                      {/* <span className={`text-xs px-2 py-1 rounded-full ${
                        entity.kyc_status === "PENDING" 
                          ? "bg-yellow-100 text-yellow-800"
                          : entity.kyc_status === "APPROVED"
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }`}>
                        {entity.kyc_status}
                      </span> */}
                    </div>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        );
      };

export default EntitySelector

