import {
  useQuery,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
  useMutation,
} from "@tanstack/react-query";
import { AxiosInstance, AxiosResponse } from "axios";
import { constructApiUrl } from "../../../../../src/utils/constructApiUrl";
import useAxios from "../../../../shared/useAxios";
import { authQueryTypes } from "./authQueryTypes";
import axios from "axios";
import { testUrl } from "../../../../helper";


interface DeviceInfo { device: string; password: string; phone: string; platform: string; }

const signInUser = async ( data:DeviceInfo):Promise<any> => {

  try{
    axios.defaults.headers.common["Authorization"] = ``;
    const response = await axios({
      url: `${testUrl}accounts/auth/signin/`,
  
      headers: {
        "Content-Type": "application/json",
        XAT: "U",
        "X-IDT": "A",
      },
      method: "post",
      data: data,
      validateStatus: function (status) {
        return status >= 200 && status < 500;
      },
    });
  
    if (response["status"] === 200) {
      let loginRes = response.data.data;
      return loginRes
      
    } else if (response["status"] > 400 && response["status"] !== 401) {
      console.log("RESPONSE ERROR---> ", response["status"] )
      throw new Error(response.data);
     
    } else if (response["status"] === 401) {
      console.log("RESPONSE ERROR---> ", response["status"] )
  
      throw new Error(response.data);
    
    }
  }catch(error){
    console.log("Login server state error ", error);
    throw new Error( `An unexpected error occurred`);    
  }

};

export const useSignInUser = (): UseMutationResult<AxiosResponse, unknown, DeviceInfo, unknown> => {
  // const $http = useAxios();
  const queryClient = useQueryClient();

  return useMutation((data: DeviceInfo) => signInUser(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([authQueryTypes.SIGN_IN_USER]);
    },
  });
};
