import React, { useEffect, useState,useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTipJarLocalState } from "./state/local-state/useTipjarLocalState";
import BackButton from "../globals/BackButton";


const FullDisbursmentReport = () => {

    const {entityJARs,activeEntityJAR, activeTipJarReport}=useTipJarLocalState();

    const navigate = useNavigate();

    console.log("ACTIVE TIPJAR REPORT ", activeTipJarReport)
    console.log("ACTIVE Entity JAR ", activeEntityJAR)
    
    const { totalDistributed, groupedDistributions } = useMemo(() => {
        if (!activeTipJarReport) {
          return { totalDistributed: 0, groupedDistributions: [] };
        }
    
        // Handle both single report and array cases
        const reports = Array.isArray(activeTipJarReport) 
          ? activeTipJarReport 
          : [activeTipJarReport];
    
        // Calculate total distributed
        const total = reports.reduce(
          (sum, report) => sum + (report.total_distribution_amount || 0),
          0
        );
    
        // Process and normalize each report
        const processedReports = reports.map(report => {
          const matrix = report.distribution_matrix;
          // Handle different distribution_matrix structures
          const normalizedMatrix = Array.isArray(matrix) 
            ? matrix 
            : matrix 
              ? Object.values(matrix) 
              : [];
    
          return {
            ...report,
            distribution_matrix: normalizedMatrix
          };
        });
    
        // Sort reports by date descending
        const sortedReports = [...processedReports].sort((a, b) => 
          new Date(b.created_at) - new Date(a.created_at)
        );
    
        return {
          totalDistributed: total,
          groupedDistributions: sortedReports
        };
      }, [activeTipJarReport]);
    
      const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-KE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(amount || 0);
      };
    
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
      };
    
      const getTotalMembers = () => {
        if (!groupedDistributions || groupedDistributions.length <= 0) {
          return 0;
        }
        let maxLength=0
        for(let group of groupedDistributions) {
          if (group?.distribution_matrix?.length > 0) {
            maxLength=Math.max(maxLength,group?.distribution_matrix?.length)
          }
            
        
      };
      return maxLength
    }
    
    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div className="flex flex-col w-full h-screen p-2">
<div className="w-full p-0">
        <BackButton onPress={handleBack} />
       <h1 className="text-2xl font-semibold text-[#6CCBD1] mb-2 mt-2">
        {activeEntityJAR?.name}
      </h1>
        </div>

        {/* Total Tips Card */}
      <div className="bg-[#E9F6FE] rounded-lg p-4 mb-6">
        <p className="text-gray-600 text-sm mb-1">Total tips disbursed:</p>
        <p className="text-[#6CCBD1] text-2xl font-bold">
          KSH {formatAmount(totalDistributed)}
        </p>
      </div>

      {/* Distribution Count */}
      <p className="text-gray-700 mb-4">
        Distribution report: {getTotalMembers()} Individuals
      </p>

      {/* Distributions Grouped by Date */}
      <div className="flex-1 space-y-6 pb-2">
        {groupedDistributions.map((report, reportIndex) => (
          <div key={reportIndex} className="space-y-4">
            {/* Date Header */}
            <div className="flex justify-between items-baseline">
              <h3 className="text-sm font-medium text-gray-500">
                {formatDate(report.created_at)}
              </h3>
              <span className="text-sm text-[#6CCBD1] font-bold">
                KSH {formatAmount(report.total_distribution_amount)}
              </span>
            </div>

            {/* Distribution List */}
            <div className="space-y-4">
              {report.distribution_matrix.map((distribution, index) => (
                <div key={index} className="flex items-center justify-between p-3 border rounded border-slate-200">
                  {/* User Info */}
                  <div className="flex items-center flex-1">
                    <div className="w-10 h-10 rounded-full bg-[#6CCBD1] text-white flex items-center justify-center mr-3">
                      {distribution.user.first_name[0]}
                      {distribution.user.last_name[0]}
                    </div>
                    <div className="flex flex-col">
                      <span className="font-medium">
                        {`${distribution.user.first_name} ${distribution.user.last_name}`}
                      </span>
                      <span className="text-sm text-gray-500">
                        {distribution.group_name}
                      </span>
                    </div>
                  </div>

                  {/* Points and Amount */}
                  <div className="text-right">
                    <div className="flex items-center justify-end space-x-2">
                      <span className="text-gray-600">
                        {distribution.group_points} points
                      </span>
                      <span className="text-sm text-[#E9B44C] font-bold">
                        - KES {formatAmount(distribution.amount)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Separator for all but last group */}
            {reportIndex < groupedDistributions.length - 1 && (
              <div className="border-b border-gray-200 pb-2" />
            )}
          </div>
        ))}
      </div>
        </div>
    )

}

export default FullDisbursmentReport

