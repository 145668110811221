import React from "react";
import { Card } from "../../components-shadcn/ui/card";
import { CreditCard, ChevronRight, LockKeyhole } from "lucide-react";
import VisaIcon from "../../assets/Images/visa.png";
import AmericanExpressIcon from "../../assets/Images/american_express.png";
import MasterCardIcon from "../../assets/Images/master_card.png";
import MpesaIcon from "../../assets/Images/mpesa.png";
import PoweredByStripe from "../../assets/Images/powered_by_stripe.png";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import BackButton from "../globals/BackButton";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const SelectPaymentMethod = () => {
  const { selectedCurrency, totalAmountToTip } = useTipJarLocalState();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleNavigateToCardPayment = () => {
    if (selectedCurrency === "KES" && totalAmountToTip < 99) {
      toast.error(
        `Minimum tip amount for card payments is ${selectedCurrency} 100`
      );
      return;
    }

    if (selectedCurrency === "USD" && totalAmountToTip < 1) {
      toast.error(
        `Minimum tip amount for card payments is ${selectedCurrency} 1`
      );
      return;
    }
    navigate("/stripe-email-input/");
  };

  return (
    <div className="flex flex-col px-4 w-full h-screen">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
          <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
        </div>
      </div>
      <h2 className="text-2xl font-semibold text-[#6CCBD1] mb-4">
        Select a payment method
      </h2>

      <Card
        className="mb-4 bg-[#FFFAF0] border-[#EFEFF4]"
        onClick={() => handleNavigateToCardPayment()}
      >
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center space-x-4">
            <CreditCard className="text-gray-600" />
            <img src={VisaIcon} alt="Visa" className="h-6" />
            <img src={MasterCardIcon} alt="MasterCard" className="h-6" />
            <img
              src={AmericanExpressIcon}
              alt="American Express"
              className="h-6"
            />
          </div>
          <ChevronRight className="text-gray-600" />
        </div>
      </Card>

      <>
        {selectedCurrency === "KES" && (
          <Card
            className="mb-4 bg-[#FFFAF0] border-[#EFEFF4]"
            onClick={() => navigate("/mpesa-number-input/")}
          >
            <div className="flex items-center justify-between p-4">
              <div className="flex items-center space-x-4">
                <img src={MpesaIcon} alt="Mpesa" className="h-8" />
                <span className="font-medium">Mpesa</span>
              </div>
              <ChevronRight className="text-gray-600" />
            </div>
          </Card>
        )}
      </>

      <div className="mt-8">
        <div className="flex items-center justify-center mb-2 hidden">
          <div className="w-full flex flex-row items-center justify-between">
            <LockKeyhole size={24} />
            <p className="text-sm">G</p>
          </div>
        </div>
        <p className="text-xs text-gray-500 text-center">
          All card payments are processed by Stripe which is PCI/DSS compliant.
          No card details are stored or transmitted by Shukran.
        </p>
      </div>
    </div>
  );
};

export default SelectPaymentMethod;
