import { create, StateCreator } from "zustand";
import {
    createJSONStorage,
    devtools,
    persist,
    PersistOptions,
  } from "zustand/middleware";

  interface HasSeenOnboardingScreensSlice {
    hasSeenOnboardingScreens: boolean;
    setHasSeenOnboardingScreens: (hasSeenOnboardingScreens: boolean) => void;
  }

  const initialState={
    hasSeenOnboardingScreens: false,
  }

  type DashboardStore = HasSeenOnboardingScreensSlice 

const createHasSeenOnboardingScreensSlice: StateCreator<DashboardStore,[],[],HasSeenOnboardingScreensSlice> = (set) => ({
    hasSeenOnboardingScreens: false,
    setHasSeenOnboardingScreens: (hasSeenOnboardingScreens: boolean) => set({ hasSeenOnboardingScreens }),
  });

  const persistOptions: PersistOptions<DashboardStore> = {
    name: "dashboard-storage",
    storage: createJSONStorage(() => localStorage),
    version: 1, // Increment this when you make breaking changes to the store structure
    partialize: (state: any) => ({
      ...state,
      // Exclude any properties you don't want to persist
      reset: undefined,
    }),
    onRehydrateStorage: (state: DashboardStore) => {
        // Optional: Handle any logic when the store is rehydrated
        return (error: any) => {
          if (error) {
            console.log("Rehydration error ---> ", error);
          }
        };
      },
    };
  

export const useDashboardLocalState = create<DashboardStore>()(
   
      persist(
        (...a) => ({
            ... createHasSeenOnboardingScreensSlice(...a)
        }),
       
        persistOptions
      )
    
  );

