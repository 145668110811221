import { useNavigatorOnLine } from "../../../src/hooks/useNavigatorOnline";

const NetworkStatusIndicator = () => {
  const isOnline = useNavigatorOnLine();

  if (isOnline) {
    return <></>;
  } else {
    return (
      <div
        className={`flex w-full items-center justify-center bg-red-600 text-white`}
      >
        <p className="text-white">You are offline</p>
      </div>
    );
  }
};

export default NetworkStatusIndicator;
