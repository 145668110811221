import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTipJarLocalState } from "./state/local-state/useTipjarLocalState";
import BackButton from "../globals/BackButton";
import { useParams } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { Skeleton } from "../../components-shadcn/ui/skeleton";
import { OctagonAlert } from "lucide-react";
import { useFetchEntityTransactions } from "./state/server-state/useTipJarServerState";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components-shadcn/ui/tabs";

const mergeTransactionsWithEntityJar = (transactions, entityJARs) => {
  const transformedTransactions = transactions.map(transaction => {
    const matchingJar = entityJARs?.find(jar => jar.shukran_id === transaction.shukran_id);

    return {
      entity_jar: matchingJar || {
        name: "Transaction", 
      },
      total_distribution_amount: transaction.amount,
      created_at: transaction.created_at,
      isTransaction: true,
      transactionData: {
        ...transaction,
        entityJarDetails: matchingJar
      }
    };
  });

  return transformedTransactions
};

const EntityJarReport = () => {
  const { activeTipJarReport, entityTransactions,  entityJARs, setEntityTransactions, activeEntity } = useTipJarLocalState();
  const [tips, setTips] = useState(entityTransactions || []);
  const [transactions, setTransactions] = useState([]);
  const [currJar, setCurrJar]=useState(null);

  const navigate = useNavigate();
  const { entityJarId } = useParams();
  const fetchEntityTransactions = useFetchEntityTransactions();

  const handleFetchEntityTransactions = async () => {
    try {
      const response = await fetchEntityTransactions.mutateAsync({
        entity_id: activeEntity.id,
      });
      setTransactions(response.data || []);
    } catch (error) {
      setTransactions([]);
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!activeEntity || !activeEntity?.id) return;
    handleFetchEntityTransactions();
  }, [activeEntity]);

  useEffect(() => {
    if (!transactions || !entityJARs) return;
    const mergedTransactions = mergeTransactionsWithEntityJar(transactions, entityJARs);
    setEntityTransactions(mergedTransactions);
  })


  useEffect(() => { 
    if(!entityJARs) return;
    const currEntityJar=entityJARs?.find((jar) => jar.id === entityJarId?.toString());
  
    if (currEntityJar) {
      setCurrJar(currEntityJar)
    }
  }, [entityJarId, entityJARs]);


  useEffect(() => {
    if (!entityTransactions || !entityJarId) {
      return;
    }
    const tips = entityTransactions?.filter((transaction) => {
      return transaction?.entity_jar?.id === entityJarId;
    });
   
    setTips(tips);
   
  }, [entityJarId, entityTransactions]);

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-KE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(new Date(dateString));
  };

  const handleBack = () => {
    navigate(-1);
  };

  const renderTips = () => {

    const totalAmount = tips?.reduce(
      (acc, report) => acc + (report?.total_distribution_amount || 0),
      0
    );

    return(
      <div className="space-y-4">
      {tips.length > 0 ? (
        <div className="w-full h-full flex flex-col">
        <div className="flex justify-between items-baseline bg-[#E9F6FE] rounded-lg p-2 mb-6">
          <h3 className="font-medium text-gray-800 ">Total Tips</h3>
          <span className="text-sm text-black font-semibold">
            KSH {formatAmount(totalAmount)}
          </span>
        </div>
        <>
        {
        tips.map((tip, index) => (
          <div key={index} className="p-4 border rounded-lg mt-3">
           
            <div className="text-sm text-gray-600 gap-2">
            <div className="flex justify-between items-center">
              <span className="text-sm">Tip Amount </span>
             
              <span className="text-sm font-semibold">{`${tip?.transactionData?.currency} ${formatAmount(tip?.transactionData?.amount)}`} </span>
              
            </div>
              <p>Status: {tip?.transactionData?.status}</p>
              <span className="text-xs text-gray-500">{formatDate(tip?.created_at)}</span>
            </div>
          </div>
        ))
      }
       
      </>
      </div>
      ) : (
        <div className="w-full h-[300px] flex flex-col items-center justify-center gap-3">
          <OctagonAlert size={28} color="#6CCBD1" />
          <p className="text-[#6CCBD1] text-lg font-semibold">No tips data</p>
        </div>
      )}
    </div>
      
    )
  }
    
  
  return (
    <div className="flex flex-col min-h-screen bg-white p-4">
      <div className="mb-6">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
        </div>
      </div>

      {
        currJar==null?<Skeleton className="w-[130px] h-[50px]">:

        </Skeleton>: <h1 className="text-2xl font-semibold text-[#6CCBD1] mb-4">
        {currJar?.name || "Report"}
      </h1>
      }
      <>
      { renderTips()}
    

      </>
      

     
    </div>
  );
};

export default EntityJarReport;