import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useNavigate } from "react-router-dom";
import BackButton from "../globals/BackButton";
import { stagingUrl } from "../../../src/helper";
import { Button } from "../../components-shadcn/ui/button";
import { useGetEntitiesMutation } from "../TipGroups/state/server-state/useTipGroupsServerState";
import {useGetSingleEntity} from "../TipGroups/state/server-state/useTipGroupsServerState";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../state/index";
import { AlertCircle, Loader2 } from "lucide-react";
import QRCode from "react-qr-code";
import { useState } from "react";
import {toast} from "sonner"

const RejectedEntity = () => {
  const [entity, setEntity]=useState(null)
  const { activeEntity } = useTipJarLocalState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getEntitiesMutation = useGetEntitiesMutation();
  const getSingleEntityMutation=useGetSingleEntity()
  const isGettingSingleEntity=getSingleEntityMutation.isLoading
  const { logOut } = bindActionCreators(actions, dispatch);
    console.log("ACTIVE ENTITY ---> ", activeEntity)
  const handleBack = () => {
    logOut();
    navigate("/login");
  };

  const handleContactSupport = () => {
    // Implement contact support functionality
    const supportUrl = "https://wa.link/aigch7";
  window.open(supportUrl, '_blank');
  };

  return (
    <div className="w-full min-h-screen bg-white p-4 flex flex-col">
      <div className="w-full px-4">
        <BackButton onPress={handleBack} />
      </div>

      <h1 className="text-2xl font-semibold text-red-500 mt-6 mb-8">
        Verification Failed
      </h1>

      <div className="w-full flex flex-col items-center">
        {/* QR Code Container */}
        <div className="w-full max-w-md bg-red-200 rounded-lg p-6 flex flex-col items-center">
          <h2 className="text-[#023844] font-semibold mb-4">TipJAR QR Code</h2>

          <div className="relative mb-4">
            <QRCode
              value={`${stagingUrl}${activeEntity?.shukran_id}`}
              size={200}
              fgColor="bg-red-600"
              style={{ margin: "0 auto" }}
              className="bg-white p-2"
            />
            {/* Avatar overlay on QR code - optional based on your needs */}
            {/* <div className="absolute bottom-4 right-4 bg-[#50A2A7] rounded-full w-8 h-8 flex items-center justify-center text-white border-2 border-white">
              M
            </div> */}
          </div>

          <p className="text-[#023844] text-sm">
            TipJAR ID: {activeEntity?.shukran_id}
          </p>
        </div>

        {/* Inactive Notice */}
        <div className="w-full max-w-md mt-6 flex flex-col items-center">
          <div className="bg-[#FFF1D6] rounded-lg p-4 w-full flex flex-col items-center gap-3 mb-4">
            <div className="flex items-center gap-2">
              <AlertCircle className="text-[#EE5582]" size={20} />
              <span className="text-[#EE5582] font-medium">
              Verification Failed
              </span>
            </div>
            <p className="text-center text-sm text-gray-600">
                {activeEntity?.kyc_comments||"Contact Shukran support"}
            </p>
           
          </div>

          {/* Share QR Button */}
          <div className="w-full mt-auto pb-6 gap-4 flex flex-col">
        <Button
          className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-md font-bold py-3 px-4 rounded-full shadow-md h-[60px]"
         
        >
                          <a 
  href="tel:0758464586" 
  className=" text-black cursor-pointer hover:text-slate-800 hover:underline font-semibold"
>
  Contact support via phone
</a>
        </Button>

        <Button 
              onClick={handleContactSupport}
              className="w-full text-white bg-green-400 px-4 py-2 rounded-full text-md h-[60px]"
            >
              Contact support via WhatsApp
            </Button>
      </div>
        </div>
      </div>
    </div>
  );
};

export default RejectedEntity;
