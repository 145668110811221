import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import ShukranLogoSacco from "../../assets/Logos/shukran-logo-sacco.svg";
import SidePattern from "../../assets/Images/side_pattern.svg";
import inviteSacco from "../../assets/Icons/Dashboard/invite_sacco.svg";
import dropDownIcon from "../../assets/Icons/Dashboard/dropdown_icon.svg";
import downloadIcon from "../../assets/Icons/Dashboard/download.svg";
import appleIcon from "../../assets/Icons/Dashboard/apple.svg";
import accountIcon from "../../assets/Icons/Profile/my_account.svg";
import loader from "../../assets/Icons/Registration/loader.svg";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import cross from "../../assets/Icons/Tipping/cross.svg";
import FacebookIcon from "../../assets/Icons/Referrals/facebook.svg";
import TwitterIcon from "../../assets/Icons/Referrals/twitter.svg";
import CopyIcon from "../../assets/Icons/Referrals/clipboard-copy.svg";
import EmailIcon from "../../assets/Icons/Referrals/email.svg";
import Share2 from "../../assets/Icons/Dashboard/share2.png";
import tipScan from "../../assets/Images/tipScan.png";
import Members from "../../assets/Icons/Dashboard/Members.png";
import Scan from "../../assets/Images/scan.png";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useGetMembersInEntityMutation } from "../../../src/components/Tipjar/state/server-state/useTipJarServerState";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TransactionCard from "../../components/Card/TransactionCard";
import {
  getSaccoBalances,
  getTipjarData,
  getTipjarMembers,
  getUserData,
  loadBalanceResults,
  loadTIpjarDisbursements,
  loadTransactionResults,
  updateProfile,
} from "../../state/reducers/user/user.thunk";
import { getFromSessionStorage } from "../../Services/Storage";
import { useGetAllTransactions } from "../../components/Transactions/transactions/transactionServerState";
import "./Dashboard.css";
import { Skeleton } from "../../components-shadcn/ui/skeleton";
import { useInvalidateEntities } from "../../../src/components/TipGroups/state/server-state/useTipGroupsServerState";
import { searchActions } from "../../state/reducers/search/search.actions";
import { ratingActions } from "../../state/reducers/rating/rating.actions";
import { businessActions } from "../../state/reducers/business/business.actions";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { saccoActions } from "../../state/reducers/sacco/sacco.actions";
import Share from "../../components/Dashboard/Share";
import { resetActions } from "../../state/reducers/reset/reset.actions";
import ReservationCard from "../../components/Dashboard/ReservationCard";
import { reservationActions } from "../../state/reducers/reservation/reservation.actions";
import useIsIOS, { checkForIOS } from "../../components/popups/useIsIos";
import { get, truncate } from "lodash";
import AkibaProgress from "./AkibaProgress";
import UpdateAkiba from "./UpdateAkiba";
import ShareQrCode from "./ShareQrCode";
import { toast as sonnerToast } from "sonner";
import axios from "axios";
import { useTipJarLocalState } from "../../../src/components/Tipjar/state/local-state/useTipjarLocalState";
import { useGetEntities } from "../../../src/components/TipGroups/state/server-state/useTipGroupsServerState";
import { testUrl } from "../../helper";
import { ToastContainer, toast } from "react-toastify";
import DashboardActions from "./DashboardActions";
import { set } from "react-hook-form";
import { useGetEntitiesMutation } from "../../../src/components/TipGroups/state/server-state/useTipGroupsServerState";
import { useGetJARsMutation } from "../../../src/components/TipGroups/state/server-state/useTipGroupsServerState";
import DashBoardReports from "./DashboardReports";
import { useDashboardLocalState } from "./state/local-state/useDashboardLocalState";
import EntitySelector from "./components/EntitySelector";


const DashboardPage = () => {
  const [accessTipError, setAccessTipError] = useState(false);
  const [reservationBanner, setReservationBanner] = useState(false);
  // const [hideDownloadPrompt, setHideDownloadPrompt] = useState(true)
  const [shareApiErr, setShareApiErr] = useState(false);
  const [switchProfile, setSwitchProfile] = useState(false);
  const [encodeUrl, setEncodeUrl] = useState("");
  const [toggleShow, setToggleShow] = useState(false);
  const [hasAdjustedPercentage, setHasAdjustedPercentage] = useState(false);
  const [jarBalance, setJarBalance] = useState(0);
  const {
    setActiveEntity,
    activeEntity,
    entityMembers,
    setEntityMembers,
    setEntityJARs,
    entitiesList, 
    setEntitiesList
  } = useTipJarLocalState();
  const { hasSeenOnboardingScreens } = useDashboardLocalState();

  // get all the jars in the entity
  const getJARSMutation = useGetJARsMutation();
  const isGettingJARS = getJARSMutation.isLoading;

  const [shareQR, setShareQR] = useState(false);
  console.log("SHARE QR CODE", shareQR);
 
  const [quotes, setQuotes] = useState([
    "Gratitude is a powerful catalyst for happiness",
    "Gratitude makes sense of our past, brings peace for today, and creates a vision for tomorrow. - Melody Beattie",
    "Gratitude is the wine for the soul. Go on. Get drunk. - Rumi",
    "Gratitude is the fairest blossom which springs from the soul. - Henry Ward Beecher",
    "Gratitude is not only the greatest of virtues, but the parent of all the others. - Cicero",
    "Gratitude turns what we have into enough. - Melody Beattie.",
  ]);
  const [isIOS, setIsIOS] = useIsIOS();
  const [shareUrl, setShareUrl] = useState("");
  const [emailShareUrl, setEmailShareUrl] = useState("");
  const [twitterShareUrl, setTwitterShareUrl] = useState("");
  const [isCopied, setIsCopied] = useState("");
  const [fallbackOption, setFallbackOption] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (!hasSeenOnboardingScreens) {
    navigate("/tipjar-onboarding-screens/");
  }
  // user global state values
  const userAccount = useSelector((state) => state.account);
  const userData = useSelector((state) => state.balance.userInfo);

  const tipjar = useSelector((state) => state.tipjar);
  const saccoState = useSelector((state) => state.sacco);
  const profileState = useSelector((state) => state.profile);
  const ShukranId = userAccount.userDetails.shukranId;
  const isAuthenticated = userAccount.isAuthenticated;
  const [expandProfile, setExpandProfile] = useState(false);
  const [showTipMe, setShowTipMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [streakInfo, setStreakInfo] = useState();
  const [akibaShow, setAkibaShow] = useState();
  const [quoteInt, setQuoteInt] = useState(0);
  const [showToast, setShowToast] = useState({ show: false, type: "" });
  const [isNew, setIsNew] = useState(false);
  const [userInfo, setUserInfo] = useState(userData);
  const getMembersInEntityMutation = useGetMembersInEntityMutation();
  const isLoadingMembersInEntity = getMembersInEntityMutation.isLoading;
  const getEntitiesMutation = useGetEntitiesMutation();
  const [searchParams] = useSearchParams();

  const { data, isLoading, error } = useGetEntities();

  if (data === 403) {
    navigate("/login/");
  }

  const handleGetEntitiesMutation = async () => {
    try {
      const data = await getEntitiesMutation.mutateAsync();
      if (data === 403) {
        navigate("/login/");
        return;
      }
      if (data) {
        setEntitiesList(data?.data?.results)
        const entity =
          data?.data?.results?.length > 0 ? data?.data?.results[0] : null;

          if(!entity){  
            sonnerToast.error("No such entity found");
            return
          }

        if (entity?.kyc_status === "PENDING") {
          setActiveEntity(entity);
          navigate("/activate-tipping/");
          return;
        }
        
        if (entity?.kyc_status === "DOCUMENTS_UPLOADED") {
          setActiveEntity(entity);
          navigate("/entity-in-review/");
          return;
        }

        if (entity?.kyc_status === "REJECTED") {
          setActiveEntity(entity);
          navigate("/rejected-entity/");
          return;
        }

        if (entity&&!activeEntity) {
          setActiveEntity(entity);
         
        }
      }
    } catch (error) {
      console.log("MUTATION ERROR --> ", error);
      sonnerToast.error("Could not fetch entities");
    }
  };

  useEffect(()=>{
    if(!activeEntity){
      return
    }
    handleGetMembersInEntity(activeEntity.id);
    handleGetJARsMutation(activeEntity.id);

  },[activeEntity])


  useEffect(() => {
    localStorage.setItem("hasSeenOnboarding", JSON.stringify(true));
    handleGetEntitiesMutation();
  }, []);

  const locationPath = useLocation();

  const logEvery24Hours = () => {
    localStorage.setItem("lastCheckIn", new Date().getTime());
  };

  const handleGetJARsMutation = async (entityID) => {
    try {
      const response = await getJARSMutation.mutateAsync(entityID);
      const entityJars = response.data.data;
      setEntityJARs(entityJars);
      const totalJarBalance = entityJars.reduce(
        (total, jar) => total + jar.jar_balance,
        0
      );

      if (typeof totalJarBalance === "number") {
        setJarBalance(totalJarBalance);
      }
    } catch (error) {}
  };

  const handleGetMembersInEntity = async (entityId) => {
    try {
      const response = await getMembersInEntityMutation.mutateAsync(entityId);
      setEntityMembers(response.data);
    } catch (error) {}
  };

  const handleEntitySelect = (entityId) => {
    const entity = entitiesList.find(e => e.id === entityId);
    if(entity){
      setActiveEntity(entity)
    }
  };

 

  useEffect(() => {
    if (showToast.show && showToast.type === "success") {
      toast.success("Redeemed successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (showToast.show && showToast.type === "success") {
      toast.warning("Cannot redeem, try again later!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [showToast.show]);
  useEffect(() => {
    if (userInfo?.user_type === "SERVICE WORKER") {
      setLoading(true);
      const lastCallTime = localStorage.getItem("lastCheckIn");
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${userAccount.userDetails.token}`;
      axios.defaults.headers.common["XAT"] = `U`;
      axios.defaults.headers.common["X-IDT"] = `A`;
      axios
        .get(`${testUrl}sacco/akiba100/streak/`)
        .then((resD) => {
          setStreakInfo(resD?.data?.data);
          setIsNew(resD?.data?.is_new);

          if (lastCallTime === null) {
            logEvery24Hours();
            if (
              resD?.data?.data?.daily_streak === 0 ||
              resD?.data?.data?.current_week * 7 ===
                resD?.data?.data?.daily_streak
            ) {
              setAkibaShow(true);
            } else {
              setAkibaShow(false);
            }
          }

          if (new Date().getTime() - lastCallTime >= 12 * 60 * 60 * 1000) {
            logEvery24Hours();

            if (
              resD?.data?.data?.daily_streak === 0 ||
              resD?.data?.data?.current_week * 7 ===
                resD?.data?.data?.daily_streak
            ) {
              setAkibaShow(true);
            } else {
              setAkibaShow(false);
            }
          }
        })
        .catch((e) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userInfo]);

  // useEffect(() => {
  //   if (
  //     locationPath?.pathname ===
  //     `/${balanceState?.userInfo?.first_name}${balanceState?.userInfo?.last_name}`
  //   ) {
  //     console.log("SET SHOW TIP ME TRUE")
  //     setShowTipMe(true);
  //   } else {
    
  //     setShowTipMe(false);
  //   }
  // }, [dispatch]);
 

  useEffect(() => {
    let intervalId;
    if (userInfo?.user_type === "TIPPER") {
      intervalId = setInterval(() => {
        setQuoteInt(Math.floor(Math.random() * 5));
      }, 10000);
    }

    return () => clearInterval(intervalId);
  }, [userInfo]);

  // const balance = userAccount.balance;
  const balanceState = useSelector((state) => {
    return state.balance;
  });
  useEffect(() => {}, []);

  const transactionData = useSelector((state) => state.transactions);

  //Fetch transactions
  const [transactionState, setTrasnactionState] = useState(transactionData);

  // const getTransactions = useGetAllTransactions();
  // const isGettingTransactions = getTransactions.isLoading;
  // const handleGetTransactions = async () => {
  //   const entityShukranId = activeEntity.shukran_id;

  //   try {
  //     const response = await getTransactions.mutateAsync(entityShukranId);
  //     const transactions = response.data.results;

  //   } catch (error) {
  //     console.log("Transactions error", error);
  //   }
  // };
  // useEffect(() => {
  //   handleGetTransactions();
  // }, [activeEntity]);

  const disbursements = transactionState?.disbursements?.slice(0, 5);
  const transactions = useSelector((state) => {
    // show transactions from latest to earliest
    let stateArr = state?.transactions?.transactions?.results
      ? state.transactions?.transactions?.results?.slice(0, 10)
      : 0;

    const appendAdditionOperator = (amount, currency) => {
      return `+ ${currency} ` + amount;
    };

    const appendSubtractionOperator = (amount, currency) => {
      return `- ${currency} ` + amount;
    };
    const parseType = (type) => {
      let newType = type?.split("_");
      newType = newType.map((type, idx) => {
        return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
      });
      return newType.join(" ");
    };

    let arr = stateArr
      ? stateArr.map((trans) => {
          let dataFormat = {
            id: trans.id,
            amount: trans.amount,
            type: trans.type,
            date: moment(trans.created_at).format("D/M/Y"),
            status: trans.status,
            sender_name: trans.receiver_first_name,
            sender_id: trans.receiver_shukran_id,
            tip_type: trans.tip_type,
            currency: trans.currency,
            exchange_rate: trans.exchange_rate,
          };

          if (trans.type === "Tip") {
            dataFormat = {
              ...dataFormat,
              amount: appendSubtractionOperator(trans.amount, trans.currency),
              sender_name: trans.sender_first_name,
              receiver_shukran_id: trans.receiver_shukran_id,
              currency: trans?.currency,
              exchange_rate: trans?.exchange_rate,
            };
            return dataFormat;
          }
          if (trans.type === "tip_match") {
            dataFormat = {
              ...dataFormat,
              amount: appendAdditionOperator(trans.amount, trans.currency),
              sender_name: trans.sender_first_name,
              receiver_shukran_id: trans.receiver_shukran_id,
              currency: trans.currency,
              exchange_rate: trans.exchange_rate,
            };
            return dataFormat;
          }

          if (trans.type === "sacco_tip") {
            dataFormat = {
              ...dataFormat,
              amount: appendSubtractionOperator(trans.amount, trans.currency),
              sender_name: trans.sender_first_name,
              sender_id: trans.recipient_id,
              currency: trans.currency,
            };
            return dataFormat;
          }

          if (
            trans.type === "pay_membership_fee" ||
            trans.type === "pay_share_capital" ||
            trans.type === "contribution"
          ) {
            dataFormat = {
              ...dataFormat,
              sender_name: parseType(trans.type),
            };
            return dataFormat;
          }
          if (trans.type === "access_transfer") {
            dataFormat = {
              ...dataFormat,
              sender_name: parseType(trans.type),
              amount: appendSubtractionOperator(trans.amount),
            };
            return dataFormat;
          }
          if (trans.type === "sacco_transfer") {
            dataFormat = {
              ...dataFormat,
              sender_name: parseType(trans.type),
              amount: appendAdditionOperator(trans.amount),
            };
            return dataFormat;
          }
          if (trans.type === "Credit") {
            dataFormat = {
              ...dataFormat,
              amount: appendAdditionOperator(trans.amount),
              sender_name: "Deposit",
            };
            return dataFormat;
          }

          if (trans.type === "Debit") {
            dataFormat = {
              ...dataFormat,
              amount: appendSubtractionOperator(trans.amount),
              sender_name: "Withdrawal",
            };
            return dataFormat;
          }

          return dataFormat;
        })
      : [];
    const ids = arr.map((o) => o.id);
    arr = arr.filter(({ id }, index) => !ids.includes(id, index + 1));
    arr.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
    return arr;
  });

  const handleSwitchProfile = () => {
    setSwitchProfile(true);
  };

  const handleUpdateProfile = () => {
    let payload = {
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      role:
        userInfo?.user_type === "SERVICE WORKER" ? "TIPPER" : "SERVICE WORKER",
      email: userInfo?.email,
      sacco_type: userInfo.sacco_stage ? userInfo.sacco_stage : "",
      user_type:
        userInfo?.user_type === "SERVICE WORKER" ? "TIPPER" : "SERVICE WORKER",
    };

    dispatch(updateProfile(payload, userAccount.userDetails.token));
    setTimeout(() => {
      dispatch(getSaccoBalances(userAccount.userDetails.token));
    }, 2000);
  };

  useEffect(() => {
    let param = searchParams.get("restaurant");
    if (profileState.errorMessage === 403 && param === null) {
      navigate("/login");
    } else if (param?.split("").length > 0) {
      navigate("/search");
    }

    return () => {};
  }, [profileState, navigate]);
  const ReduceCalls = useMemo(
    () => async () => {
      try {
        dispatch(resetActions.updateProfileSuccess({}));

        dispatch(getSaccoBalances(userAccount.userDetails.token));
        dispatch(
          loadBalanceResults(userInfo.shukran_id, userAccount.userDetails.token)
        );

        //

        if (
          balanceState?.userInfo?.user_type === "TIP_JAR" ||
          balanceState?.userInfo?.user_type === "ENTITY_JAR"
        ) {
          dispatch(
            getTipjarData(userInfo.shukran_id, userAccount.userDetails.token)
          );
          dispatch(
            getTipjarMembers(userInfo.shukran_id, userAccount.userDetails.token)
          );
        }
      } catch (error) {}
    },
    []
  );

  useEffect(() => {
    if (profileState?.results?.success) {
      let shukran_id = userInfo.shukran_id;
      let params = {
        shukran_id: shukran_id,
      };
      ReduceCalls();
    }

    return () => {};
  }, [profileState]);

  const makeReservation = () => {
    // setReservationBanner(true);
    navigate("/searchRestaurant");
  };
  useEffect(() => {
    // dispatch(getTipjarData({}, userAccount.userDetails.token));
  }, []);
  useEffect(() => {
    if (
      balanceState?.userInfo?.user_type === "TIP_JAR" ||
      balanceState?.userInfo?.user_type === "ENTITY_JAR"
    ) {
      dispatch(
        getTipjarData(userInfo.shukran_id, userAccount.userDetails.token)
      );
      dispatch(
        getTipjarMembers(userInfo.shukran_id, userAccount.userDetails.token)
      );
    }
  }, [balanceState?.userInfo?.user_type]);

  useEffect(() => {
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);

    let onboardingState = localStorage.getItem("hasSeenOnboarding")
      ? localStorage.getItem("hasSeenOnboarding")
      : "false";
    let hasAdjustedPercentage = localStorage.getItem("hasAdjustedPercentage")
      ? localStorage.getItem("hasAdjustedPercentage")
      : "false";
    hasAdjustedPercentage = JSON.parse(hasAdjustedPercentage);
    setHasAdjustedPercentage(hasAdjustedPercentage);
    let hasSeenOnboarding = JSON.parse(onboardingState);

    if (!isAuthenticated && isLoggedIn) {
      let param = searchParams.get("restaurant");
      if (!hasSeenOnboarding && param === null) {
        if (param) {
          dispatch(reservationActions.updateRestaurant(param));
          navigate("/search");
        }
        localStorage.setItem("hasSeenOnboarding", JSON.stringify(true));
        // navigate("/tipjar-onboarding-screens/");
        navigate("/login");
      } else if (param?.split("").length < 0) {
        navigate("/login");
      }
      // }
    }
    if (!isAuthenticated && !isLoggedIn && !hasSeenOnboarding) {
      let param = searchParams.get("restaurant");
      if (param?.split("").length > 0) {
        dispatch(reservationActions.updateRestaurant(param));
        // navigate("/search");
        navigate("/login");
      } else {
        // navigate("/splashScreen");
        navigate("/login");
      }

      // if (isIOS.platform === 'web') {

      // } else {
      //   navigate("/registration");
      // }
    }
    let shukran_id = getFromSessionStorage("ShukranId");
    let params = {
      shukran_id: shukran_id,
    };
    let encodeUrl = "https://wa.link/aigch7";
    encodeUrl = encodeURI(encodeUrl);
    setEncodeUrl(encodeUrl);

    // RESET redux store state
    dispatch(
      ratingActions.getBusinessRatingSuccess({
        ratings: [],
      })
    );
    dispatch(getUserData(userInfo.shukran_id, userAccount.userDetails.token));
    dispatch(
      loadTransactionResults(userInfo.shukran_id, userAccount.userDetails.token)
    );

    dispatch(
      loadTIpjarDisbursements(tipjar?.tipjarId, userAccount?.userDetails?.token)
    );
    dispatch(getSaccoBalances(userAccount.userDetails.token));
    dispatch(
      loadBalanceResults(userInfo.shukran_id, userAccount.userDetails.token)
    );

    //dispatch(loadBalanceResults(userAccount.userDetails.token));
    dispatch(ratingActions.getBusinessRatingError(""));
    dispatch(businessActions.searchBusinessError(""));
    dispatch(balanceActions.subscriptionLoadError(""));
    dispatch(reservationActions.makeReservationSuccess(""));
    dispatch(reservationActions.getReservationHistorySuccess(""));
    dispatch(reservationActions.searchRestaurantSuccess(""));
    dispatch(saccoActions.saccoActivateError(""));
    dispatch(searchActions.searchLoadError(""));
  }, []);

  useEffect(() => {
    let loginState = localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : "false";
    let isLoggedIn = JSON.parse(loginState);
    let onboardingState = localStorage.getItem("hasSeenOnboarding")
      ? localStorage.getItem("hasSeenOnboarding")
      : "false";
    let hasSeenOnboarding = JSON.parse(onboardingState);
    let param = searchParams.get("restaurant");

    if (
      saccoState.errorMessage === 403 &&
      isLoggedIn &&
      !hasSeenOnboarding &&
      param === null
    ) {
      // navigate("/newUserType");
      navigate("/login/");
      localStorage.setItem("hasSeenOnboarding", JSON.stringify(true));
    }
    if (
      saccoState.errorMessage === 403 &&
      isLoggedIn &&
      hasSeenOnboarding &&
      param === null
    ) {
      navigate("/login");
    }
    if (
      saccoState.errorMessage === 403 &&
      !isLoggedIn &&
      !hasSeenOnboarding &&
      param === null
    ) {
      // navigate("/splashScreen");
      navigate("/login");
      // navigate("/tipjar-onboarding-screens/");
    }
    if (
      balanceState.errorMessage === 403 &&
      isLoggedIn &&
      hasSeenOnboarding &&
      param === null
    ) {
      navigate("/login");
    }
    // if (balanceState.errorMessage === 403 && isLoggedIn && !hasSeenOnboarding) {
    //   navigate("/login");
    // }
    if (
      balanceState.errorMessage === 403 &&
      !isLoggedIn &&
      hasSeenOnboarding &&
      param === null
    ) {
      navigate("/login");
    }
    return () => {};
  }, [saccoState, balanceState, navigate]);

  useEffect(() => {
    if (showTipMe) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [showTipMe]);

  const handleDownloadApp = () => {
    // setHideDownloadPrompt(false)
    isIOS.setHidePrompt();
    window.open(
      "http://shukranplatform.app.link/igHyjchrxBb",
      "_blank",
      "noreferrer"
    );
  };

  const AccessError = () => (
    <div className="px-16 drop-shadow-xl w-11/12 bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setAccessTipError(false);
        }}
      >
        X
      </button>
      <img src={inviteSacco} alt="error-img" className="h-28 pb-4" />
      {/* <p className="justify-self-center text-lg text-center py-2">
        Referral
      </p> */}
      <p className="justify-self-center	text-lg text-center py-6">
        Pay Membership fee to Refer
      </p>
    </div>
  );

  const ShareApiError = () => (
    <div className="px-16 drop-shadow-xl w-11/12 bg-white pt-10 flex flex-col relative pb-4 rounded-3xl z-50">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setShareApiErr(false);
        }}
      >
        X
      </button>
      <img src={cross} alt="error-img" className="h-24" />
      <p className="justify-self-center text-lg text-center py-2">
        Tell a Friend
      </p>
      <p className="justify-self-center	text-lg text-center py-2">
        Your browser doesn't support the sharing capability
      </p>
    </div>
  );

  const UnActivatedCard = () => (
    <>
      {userInfo?.user_type === "TIPPER" ? (
        <div className="pr-10 mt-3 h-4/5 flex flex-col justify-between">
          <div className="text-base text-white ">{quotes[quoteInt]}</div>
          {/* <div className="italic text-white text-sm">
            #OctoberFest2023
          </div> */}
        </div>
      ) : (
        <>
          {userInfo?.sacco_stage === "new" &&
            userInfo?.user_type !== "TIP_JAR" &&
            userInfo?.user_type !== "ENTITY_JAR" && (
              <>
                <div className="text-3xl text-white">
                  {balanceState.balance
                    ? `${Math.trunc(balanceState.balance)} KES`
                    : `0 KES`}
                </div>
                <div className="text-md text-white">Join Sacco</div>
              </>
            )}
        </>
      )}
      {userInfo?.user_type === "TIP_JAR" ||
      userInfo?.user_type === "ENTITY_JAR" ? (
        <div className="flex flex-col">
          {isGettingJARS ? (
            <Skeleton className="h-6 w-24" />
          ) : (
            <span className="text-2xl text-white">
              {!isNaN(jarBalance) && typeof jarBalance === "number"
                ? `${Math.trunc(jarBalance).toLocaleString()} KES`
                : "0 KES"}
            </span>
          )}

          <span className="text-base text-white my-1">
            {userInfo?.user_type === "TIP_JAR" ||
            userInfo?.user_type === "ENTITY_JAR"
              ? `Tip Jar Balance`
              : ``}
          </span>

          {isLoadingMembersInEntity ? (
            <Skeleton className="h-6 w-24" />
          ) : (
            <span className="text-sm text-white">
              Active Members {entityMembers?.length}
            </span>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );

  const SaccoBalancesCard = () => (
    <div className="flex flex-col">
      <div className="text-3xl text-white">
        {userInfo.sacco_stage === "application_rejected" ||
        userInfo.sacco_stage === "approval_pending" ||
        userInfo.sacco_stage === "member_left"
          ? `${
              balanceState.balance
                ? `${Math.trunc(balanceState.balance)} KES`
                : `0 KES`
            }`
          : userInfo.sacco_stage === "kyc_incomplete"
          ? `${
              balanceState.balance
                ? `${Math.trunc(balanceState.balance)} KES`
                : saccoState.balance.data.membership_fee
                ? `${Math.trunc(saccoState.balance.data.membership_fee)} KES`
                : saccoState.balance.data.share_capital
                ? `${Math.trunc(saccoState.balance.data.share_capital)} KES`
                : "0 KES"
            }`
          : userInfo.sacco_stage === "pay_membership_fee"
          ? `${
              saccoState.balance.data.membership_fee
                ? Math.trunc(saccoState.balance.data.membership_fee)
                : 0
            } KES`
          : userInfo.sacco_stage === "approval_pending"
          ? balanceState.balance
            ? `${Math.trunc(balanceState.balance)} KES`
            : saccoState.balance.data.share_capital
            ? `${Math.trunc(saccoState.balance.data.share_capital)} KES`
            : `${Math.trunc(saccoState.balance.data.membership_fee)} KES`
          : userInfo.sacco_stage === "new"
          ? balanceState.balance
            ? `${Math.trunc(balanceState.balance)} KES`
            : saccoState.balance.data.share_capital
            ? `${Math.trunc(saccoState.balance.data.share_capital)} KES`
            : `${Math.trunc(saccoState.balance.data.membership_fee)} KES`
          : userInfo.sacco_stage === "pay_share_capital"
          ? `${
              saccoState.balance.data.share_capital
                ? Math.trunc(saccoState.balance.data.share_capital)
                : 0
            } KES`
          : userInfo.sacco_stage === "full_member"
          ? `${
              saccoState.balance.data.sacco_balance
                ? Math.trunc(saccoState.balance.data.sacco_balance)
                : 0
            } KES`
          : userInfo.sacco_stage === "new" || userInfo.sacco_stage === null
          ? `${
              balanceState.balance
                ? `${Math.trunc(balanceState.balance)} KES`
                : saccoState.balance.data.share_capital
                ? `${Math.trunc(saccoState.balance.data.share_capital)} KES`
                : saccoState.balance.data.membership_fee
                ? `${Math.trunc(saccoState.balance.data.membership_fee)} KES`
                : "0 KES"
            }`
          : ""}
      </div>
      <div className="text-base text-white my-1">
        {userInfo.sacco_stage === "application_rejected" ||
        userInfo.sacco_stage === "application_pending" ||
        userInfo.sacco_stage === "member_left"
          ? `${
              saccoState.balance.data.share_capital
                ? `Share Capital`
                : saccoState.balance.data.membership_fee
                ? `Membership Fee`
                : balanceState.balance
                ? `Wallet Balance`
                : ""
            }`
          : userInfo.sacco_stage === "kyc_incomplete"
          ? `${
              // saccoState.balance.data.membership_fee
              //   ? `Membership Fee`
              //   : balanceState.balance
              //   ? `Wallet Balance`
              //   : ""
              "KYC Incomplete"
            }`
          : userInfo.sacco_stage === null
          ? "Join SACCO"
          : userInfo.sacco_stage === "pay_membership_fee"
          ? `${
              saccoState.balance.data.membership_fee
                ? "Membership Fee"
                : "Membership Fee"
            }`
          : userInfo.sacco_stage === "approval_pending"
          ? `${`Approval Pending`}`
          : userInfo.sacco_stage === "approval_rejected"
          ? `${`Approval Rejected`}`
          : userInfo.sacco_stage === "pay_share_capital"
          ? `${
              saccoState.balance.data.share_capital
                ? "Share Capital"
                : saccoState.balance.data.share_capital === 0
                ? "Share Capital"
                : ""
            }`
          : userInfo.sacco_stage === "full_member"
          ? `${
              saccoState.balance.data.sacco_balance >= 0 ? "Sacco Balance" : ""
            }`
          : userInfo.sacco_stage === "new"
          ? `${
              // saccoState.balance.data.sacco_balance
              //   ? "Sacco Balance"
              //   : saccoState.balance.data.share_capital
              //   ? `Share Capital`
              //   : saccoState.balance.data.membership_fee
              //   ? `Membership Fee`
              //   : balanceState.balance
              //   ? `Wallet Balance`
              //   :
              "Join SACCO"
            }`
          : ""}
      </div>
      {(userInfo.sacco_stage === "pay_membership_fee" ||
        userInfo.sacco_stage === "pay_share_capital" ||
        userInfo.sacco_stage === "full_member") && (
        <div className="text-sm text-[#6CCBD1]">
          Available Tips:{" "}
          {saccoState.balance.data.access_tip_active &&
          saccoState.balance.data.access_balance > 0
            ? Math.trunc(saccoState.balance.data.access_balance)
            : Math.trunc(balanceState.balance)}{" "}
          {" KES"}
        </div>
      )}

      {/* {userInfo.sacco_stage === "full_member" ||
      userInfo.user_type === "TIPPER" ? (
        <span className="text-sm text-white">
          {userInfo.sacco_stage === "full_member" ? "SACCO balance" : ""}
        </span>
      ) : (
        <span className="text-sm text-inactivated">
          {userInfo.sacco_stage === "pay_membership_fee" ||
          userInfo.sacco_stage === null
            ? `Membership Fee: ${
                500 - saccoState.balance.data.membership_fee
              }KES`
            : userInfo.sacco_stage === "pay_share_capital"
            ? `Share Capital Required: ${
                3000 - saccoState.balance.data.share_capital
              } KES`
            : userInfo.sacco_stage === "new"
            ? "Activate your SACCO to receive tips"
            : userInfo.sacco_stage === "kyc_incomplete"
            ? "Complete submission of SACCO details to receive tips"
            : userInfo.sacco_stage === "approval_pending"
            ? "Approval pending"
            : userInfo.sacco_stage === "application_rejected"
            ? "Application rejected"
            : ``}
        </span>
      )} */}
    </div>
  );

  const SuccessRating = () => (
    <div className="mt-24 mx-auto shadow bg-white pt-10 flex flex-col relative pb-2 h-fit rounded-3xl z-50 w-4/5">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          dispatch(resetActions.updateProfileSuccess({}));
        }}
      >
        X
      </button>
      <img src={checkCircle} alt="error-img" className="h-20" />
      <p className="justify-self-center	text-lg text-center py-2">
        {profileState?.results?.msg}
      </p>
    </div>
  );

  const SwitchPopup = () => (
    <div className="mt-24 mx-auto shadow bg-white flex flex-col relative p-4 h-fit rounded-3xl z-50 w-4/5">
      <button
        className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
        onClick={() => {
          setSwitchProfile(false);
        }}
      >
        X
      </button>
      <img src={accountIcon} alt="error-img" className="h-20" />
      <p className="justify-self-center	text-base text-center p-2">
        Switch your profile from {userInfo?.user_type} to
        {userInfo?.user_type === "SERVICE WORKER"
          ? " TIPPER"
          : " SERVICE WORKER"}
      </p>
      <button
        className="mt-12 rounded-3xl py-4 bg-primary-pwa text-white text-base uppercase w-full"
        onClick={handleUpdateProfile}
      >
        Confirm
      </button>
    </div>
  );

  const DownloadPopup = () => {
    return (
      <div className="max-w-[600px] w-full bg-white rounded-xl pb-auto w-full flex flex-col items-center">
        <div className="flex flex-row-reverse w-full">
          <div
            className="text-base text-pink-400 rounded-2xl py-1 px-3 cursor-pointer"
            onClick={() => {
              // setHideDownloadPrompt(false)
              isIOS.setHidePrompt();
              setIsIOS(checkForIOS());
            }}
          >
            X
          </div>
        </div>
        <div>Access Shukran in on click!</div>
        <div
          onClick={() => {
            handleDownloadApp();
          }}
          className="flex flex-row items-center my-2"
        >
          <div className="underline text-primary-pwa mb-2">
            Download the app
          </div>
          {isIOS.isIOS ? (
            <img src={appleIcon} alt="error-img" className="h-4 ml-2" />
          ) : (
            <img src={downloadIcon} alt="error-img" className="h-4 ml-2" />
          )}
        </div>
      </div>
    );
  };

  // Fallback sharing options
  useEffect(() => {
    const canonical = document.querySelector("link[rel=canonical]");
    let url = canonical ? canonical.href : document.location.href;
    let emailUrl = `mailto:?subject="Have you heard of Shukran?"&body=Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit ${url}`;
    let twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${"Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit "}`;
    setShareUrl(url);
    setEmailShareUrl(emailUrl);
    setTwitterShareUrl(twitterUrl);
    return () => {};
  }, []);

  const onFacebookShare = (e) => {
    e.preventDefault();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      "facebook-share-dialog",
      "width=800,height=600"
    );
  };

  const onCopyToClipboard = (e) => {
    e.preventDefault();
    let strVal =
      "Sasa, nimejipanga na Shukran SACCO, je wewe? To learn more, Visit " +
      shareUrl;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(strVal)
        .then(() => setIsCopied(true))
        .catch(console.error);
    }
  };

  // Restarts the copy state after a few seconds
  useEffect(() => {
    if (!isCopied) return;

    const timer = setTimeout(() => {
      setIsCopied((currentIsCopied) => !currentIsCopied);
    }, 3000);

    return () => clearTimeout(timer);
  }, [isCopied]);

  const FallBackOptions = () => (
    <div className="flex flex-col items-center drop-shadow-2xl rounded-t-2xl bg-white pb-auto w-full z-30">
      <div className="flex flex-row-reverse w-full">
        <div
          className="text-base text-pink-400 rounded-2xl py-1 px-3 cursor-pointer"
          onClick={() => {
            setFallbackOption(false);
          }}
        >
          X
        </div>
      </div>
      <div className="flex flex-row bg-white items-center justify-around w-full pb-4">
        <div
          className="h-14 w-14 flex flex-col items-center justify-content"
          onClick={onFacebookShare}
        >
          <img src={FacebookIcon} alt="icon" />
          <span className="text-xs">Facebook</span>
        </div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={twitterShareUrl}
          className="h-14 w-14 flex flex-col items-center justify-content"
        >
          <img src={TwitterIcon} alt="icon" />
          <span className="text-xs">Twitter</span>
        </a>
        <div
          className="h-14 w-14 flex flex-col items-center justify-content"
          onClick={onCopyToClipboard}
        >
          <img src={CopyIcon} alt="icon" />
          <span className="text-xs text-center">
            {isCopied ? "Copied!" : "Copy to clipboard"}
          </span>
        </div>
        <a
          href={emailShareUrl}
          title="Have you heard of Shukran?"
          className="h-14 w-14 flex flex-col items-center justify-content"
        >
          <img src={EmailIcon} alt="email" />
          <span className="text-xs">Email</span>
        </a>
      </div>
    </div>
  );
  
  return (
    <div
      className={`

        w-full flex flex-col h-full min-h-screen bg-imag-dash-2
      `}
    >
      <ToastContainer />
      {showTipMe && (
        <div
          style={{
            bottom: 0,
            position: "absolute",

            height: "85%",
            backgroundColor: "white",
            zIndex: 100,
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
          className="w-full max-w-[600px]"
        >
          <div className="w-full flex flex-col items-center p-5">
            <button
              onClick={() => {
                setShowTipMe(false);
              }}
              className="absolute text-gray-400 right-4 px-2 text-2xl "
            >
              X
            </button>
            <p
              className="justify-self-center text-lg text-center "
              style={{ fontWeight: "900" }}
            >
              Scan QR code to tip
            </p>
            <ShareQrCode />
          </div>
        </div>
      )}
      <div
        className={`
          ${accessTipError ? "blur-sm" : ""}
          ${akibaShow ? "blur-sm" : ""}
          ${shareQR ? "blur-sm" : ""}
          ${shareApiErr ? "blur-sm" : ""}
          ${switchProfile ? "blur-sm" : ""}
          ${fallbackOption ? "blur-sm brightness-50" : ""}
          ${reservationBanner ? "blur-sm brightness-50" : ""}
          ${profileState.isLoading ? "blur-sm" : ""}
          ${showTipMe ? "blur-sm" : ""}

          mx-auto w-full flex flex-col h-full  `}
      >
        <div className="flex flex-col justify-between items-center mt-16 mb-8 px-5">
          <div className="flex flex-row justify-between items-center  w-full">
            {userInfo?.user_type === "SERVICE WORKER" ? (
              <img src={ShukranLogoSacco} alt="Logo" className="w-28 h-10" />
            ) : (
              <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
            )}
            <div className="flex flex-row items-center justify-center">

           
              <button
                id="dropdownHoverButton"
                data-dropdown-toggle="dropdownHover"
                data-dropdown-trigger="click"
                className=" rounded-full mr-2 flex flex-col justify-center items-center h-12 w-12"
                type="button"
                onClick={() => setShowTipMe(!showTipMe)}
              >
                <img src={tipScan} alt="scan me" className="w-18 h-15" />
              </button>
              <button
                id="dropdownHoverButton"
                data-dropdown-toggle="dropdownHover"
                data-dropdown-trigger="click"
                className="bg-primary-pwa rounded-full flex flex-col justify-center items-center h-12 w-12"
                type="button"
                onClick={() => navigate("/profile/android")}
              >
                <p className="text-2xl text-white">{ShukranId?.split("", 2)}</p>
              </button>
            </div>
          </div>

          <div className="w-full mt-2">
          {/* <EntitySelector 
            entities={entitiesList}
            selectedEntity={activeEntity}
            onEntitySelect={handleEntitySelect}
          />  */}
          </div> 
        </div>
       
        {/* CARDs */}

        {/* <div className="flex flex-row justify-between pl-2 w-11/12 h-40 rounded-md z-50 mx-auto bg-gradient-to-r from-amber-400 to-violet-950 relative">
          <div className="flex flex-col justify-between py-5">
            <p className="text-sm italic text-white">Shukran ID: {ShukranId}</p>
            {!saccoState.isLoading &&
              saccoState.balance.success &&
              userInfo.is_sacco && (
                <SaccoBalancesCard />
              )}
            {!saccoState.isLoading &&
              (saccoState.errorMessage.msg === "Account not activated" ||
                !userInfo.is_sacco) && <UnActivatedCard />}
          </div>
          <div className="absolute top-0 right-10 flex flex-col justify-between items-end h-full">
            <img
              src={Overfill}
              alt="overfill-pattern"
              className="h-9 w-40"
            />
            <img
              src={Beerbottles}
              alt="beer-bottle"
              className="h-24 w-20 mr-6 mb-2"
            />
          </div>
          <img
            src={SidePattern}
            alt="side-pattern"
            className="object-contain"
          />
        </div> */}
        {/* <div
          id="dropdownHover"
          className={`z-10 ${
            expandProfile ? "" : "hidden"
          } bg-[#FCF7EA] divide-y divide-gray-100 z-10 self-end mr-2 absolute rounded-lg shadow w-25 h-20 `}
          style={{ marginTop: "120px" }}
        >
          <ul
            class="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownHoverButton"
          >
            <li>
              <div
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={() => {
                  setShowTipMe(!showTipMe);
                  setExpandProfile(false);
                }}
              >
                Get Tipped
              </div>
            </li>
            <li>
              <Link
                to="/profile/android"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Account
              </Link>
            </li>
          </ul>
        </div> */}

        <div className="flex flex-row justify-between z-0 pl-2 w-11/12 bg-[#1E2E47] h-40 rounded-md z-50 mx-auto">
          <div className="flex flex-col justify-between py-5">
            {showTipMe ? (
              <p
                className="text-sm italic text-white"
                style={{ fontSize: "12px" }}
              >
                {userInfo?.user_type !== "TIP_JAR"
                  ? "Shukran ID:"
                  : "Tip Jar ID:"}{" "}
                {activeEntity?.shukran_id}
              </p>
            ) : (
              <p className="text-sm italic text-white">
                {userInfo?.user_type !== "TIP_JAR"
                  ? "Shukran ID:"
                  : "Tip Jar ID:"}{" "}
                {activeEntity?.shukran_id}
              </p>
            )}

            {!saccoState.isLoading &&
              saccoState?.balance?.success &&
              userInfo?.user_type === "SERVICE WORKER" && <SaccoBalancesCard />}
            {!saccoState.isLoading &&
              (saccoState?.errorMessage?.msg === "Account not activated" ||
                !userInfo.is_sacco ||
                userInfo.sacco_stage !== "new" ||
                userInfo.sacco_stage === null ||
                userInfo.user_type === "TIP_JAR") && <UnActivatedCard />}
          </div>
          {userInfo?.user_type === "SERVICE WORKER" && (
            <>
              {showTipMe && (
                <div
                  onClick={() => setShareQR(true)}
                  className="w-8 h-6 flex items-center justify-center mt-4"
                >
                  <img src={Scan} style={{ width: "20px", height: "20px" }} />
                </div>
              )}
              <AkibaProgress
                setAkibaShow={setAkibaShow}
                type={
                  streakInfo?.daily_streak === 0 && isNew === false
                    ? "failed"
                    : isNew
                    ? "new"
                    : "success"
                }
                setShowToast={setShowToast}
                streakInfo={streakInfo}
                loading={loading}
              />
            </>
          )}
          <img
            src={SidePattern}
            alt="side-pattern"
            className="object-contain"
          />
        </div>

        <DashboardActions
          userData={userInfo}
          setUserData={setUserInfo}
          hasAdjustedPercentage={hasAdjustedPercentage}
          encodeUrl={encodeUrl}
        />
      </div>

      <>
      {
        shareQR&&(
        <div
        className={`${
          shareQR ? "absolute blur-none" : "hidden"
        } flex flex-col items-center pt-36 w-full h-full`}
      >
        <ShareQrCode setQrCode={setShareQR} />
      </div>
        )
      }
      </>
      
      {!showTipMe && <DashBoardReports />}
    </div>
  );
};

export default DashboardPage;
