import React, { useState, useEffect, useCallback, useRef } from "react";
import { ChevronLeft, Info } from "lucide-react";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import BackButton from "../globals/BackButton";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import MPesaConfirmationDialog from "./MPesaConfirmationDialog";
import {
  useSendTips,
  useCheckMpesaTransactionStatus,
} from "./state/server-state/useTipGroupsServerState";
import { useTipGroupsLocalState } from "./state/local-state/useTipGroupsLocalState";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Button } from "../../../src/components-shadcn/ui/button";
import loader from "../../assets/Icons/Registration/loader.svg";
import {
  Dialog,
  DialogContent,
} from "../../../src/components-shadcn/ui/dialog";

const RETRY_DELAY = 6000; // 5 seconds between retries
const MAX_RETRIES = 8; // Maximum number of retries (30 seconds total)

const MPesaNumberInput = () => {
  const [mpesaNumber, setMpesaNumber] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const timerRef = useRef(null);

  const sendTips = useSendTips();
  const isSendingTips = sendTips.isLoading;
  const navigate = useNavigate();

  const {
    initialMpesaDetails,
    mpesaPaymentState,
    setMpesaPaymentState,
    setInitialMpesaDetails,
  } = useTipGroupsLocalState();

  const checkMpesaTransactionStatus = useCheckMpesaTransactionStatus();

  const cleanupTransaction = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setRetryCount(0);
    setMpesaPaymentState(false);
    setInitialMpesaDetails(null);
  }, [setMpesaPaymentState, setInitialMpesaDetails]);

  const handleTransactionComplete = useCallback(
    (status) => {
      cleanupTransaction();

      switch (status) {
        case "Success":
          toast.success("Payment completed successfully");
          navigate("/payment-complete/")
          break;
        case "Failed":
          toast.error("The MPesa transaction failed");
          break;
        case "Cancelled":
          toast.error("The MPesa transaction was cancelled");
          break;
        default:
          toast.error("An unexpected error occurred");
      }
    },
    [cleanupTransaction, navigate]
  );

  const checkStatus = useCallback(async () => {
    if (!initialMpesaDetails?.id || retryCount >= MAX_RETRIES) {
      cleanupTransaction();
      if (retryCount >= MAX_RETRIES) {
        toast.error("Transaction timeout. Please try again.");
      }
      return;
    }

    try {
      const response = await checkMpesaTransactionStatus.mutateAsync(
        initialMpesaDetails.id
      );
      const responseStatus =
        response?.data?.txn_status || response?.response?.data?.txn_status;

      if (responseStatus === "Pending") {
        setRetryCount((count) => count + 1);
        // Schedule next check
        timerRef.current = setTimeout(checkStatus, RETRY_DELAY);
      } else {
        handleTransactionComplete(responseStatus);
      }
    } catch (error) {
      console.error("Error checking status:", error);
      cleanupTransaction();
      toast.error("Failed to check transaction status");
    }
  }, [
    initialMpesaDetails?.id,
    retryCount,
    checkMpesaTransactionStatus,
    handleTransactionComplete,
    cleanupTransaction,
  ]);

  // Start checking status when transaction is initiated
  useEffect(() => {
    if (initialMpesaDetails?.id && mpesaPaymentState) {
      checkStatus();
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [initialMpesaDetails?.id, mpesaPaymentState]); // Deliberately omit checkStatus to prevent infinite loop

  const handleBack = () => {
    cleanupTransaction();
    navigate(-1);
  };

  const handleProceed = () => {
    if (!mpesaNumber || mpesaNumber.length < 10) {
      toast.error("Please enter a valid M-Pesa number");
      return;
    }
    setShowConfirmation(true);
  };

  const handleSendTip = async (payload) => {
    cleanupTransaction();
    setShowConfirmation(false);

    try {
      const response = await sendTips.mutateAsync({
        ...payload,
        phone_number: mpesaNumber,
      });

      if (response?.data?.data) {
        setInitialMpesaDetails(response.data.data);
        setMpesaPaymentState(true);
      }
    } catch (error) {
      console.error("Send tip error:", error);
      toast.error(error.message || "Failed to initiate M-Pesa payment");
      cleanupTransaction();
    }
  };

  return (
    <div className="flex flex-col h-screen bg-white p-4">
      <div className="flex items-center mb-6 w-full">
        <div className="w-full flex flex-row items-center justify-between">
          <BackButton onPress={handleBack} />
          <img src={ShukranLogo} alt="Logo" className="w-28 h-10" />
        </div>
      </div>

      <h1 className="text-2xl font-semibold text-[#50A2A7] mb-4">
        Enter your M-Pesa Number
      </h1>

      <div className="flex flex-row drop-shadow-xl mt-8 justify-between relative">
        <input
          type="tel"
          value={mpesaNumber}
          onChange={(e) => setMpesaNumber(e.target.value)}
          placeholder="0712345678"
          className="w-full p-3 rounded-md border-2 border-[#50A2A7] focus:outline-none focus:border-[#50A2A7]"
        />
      </div>

      <div className="bg-[#F0E6FF] rounded-md p-4 mt-6">
        <div className="flex items-start">
          <Info className="h-5 w-5 text-[#7C3AED] mr-2 mt-1" />
          <div>
            <h3 className="font-semibold text-[#7C3AED] mb-1">
              How does it work
            </h3>
            <p className="text-sm text-[#7C3AED]">
              You will receive a popup anytime you want to tip a service worker
              when you choose the Mpesa option.
            </p>
          </div>
        </div>
      </div>

      <div className="mt-auto mb-4">
        <Button
          disabled={isSendingTips}
          className="w-full rounded-full h-[60px] bg-[#F2C773] text-white font-semibold"
          onClick={handleProceed}
        >
          {isSendingTips && (
            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
          )}
          PROCEED
        </Button>
      </div>

      {showConfirmation && (
        <MPesaConfirmationDialog
          onClose={() => setShowConfirmation(false)}
          onSendTip={handleSendTip}
          phoneNumber={mpesaNumber}
        />
      )}

      <Dialog
        open={mpesaPaymentState}
        onOpenChange={(open) => {
          if (!open) {
            cleanupTransaction();
          }
        }}
      >
        <DialogContent className="sm:max-w-md rounded-lg">
          <div className="flex flex-col items-center justify-center p-6 space-y-6">
            <img
              src={loader}
              alt="loader"
              className="animate-spin h-32 mx-auto text-sm mt-1 mb-5"
            />
            <p className="text-center text-gray-700 font-medium">
              Input your PIN on the Mpesa prompt to tip
            </p>
            {mpesaPaymentState && (
              <p className="text-sm text-gray-500">
                Checking transaction status... Attempt {retryCount + 1} of{" "}
                {MAX_RETRIES}
              </p>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MPesaNumberInput;
