import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import add from "../../assets/Icons/tipjar/add.png";
import user from "../../assets/Icons/Dashboard/Members.png";
import "./members.css";
import { Loader2, Trash2, RefreshCcw, Search } from "lucide-react";
import { ReloadIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";
import { useRef } from "react";
import BackButton from "../globals/BackButton";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useFetchEntityMembers } from "../Tipjar/state/server-state/useTipJarServerState";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components-shadcn/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components-shadcn/ui/select";
import { Button } from "../../components-shadcn/ui/button";
import { useRemoveMemberFromEntity } from "../Tipjar/state/server-state/useTipJarServerState";
import { useAddMemeberToEntity } from "../Tipjar/state/server-state/useTipJarServerState";
import AddButton from "../globals/AddButton";
import { Checkbox } from "../../components-shadcn/ui/checkbox";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components-shadcn/ui/tabs";
export default function Members() {
  const [confirmRemoveMemberFromEntity, setConfirmRemoveMemberFromEntity] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState({});
  const { activeEntity, entityDepartments, setEntityMembers } = useTipJarLocalState();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const {
    data: entityMembers,
    isLoading: isLoadingEntityMembers,
    error: entityMembersError,
    refetch,
  } = useFetchEntityMembers(activeEntity?.id);

  if (entityMembers === 403) {
    navigate("/login/");
  }

  useEffect(() => {
    if (entityMembers?.data) {
      setEntityMembers(entityMembers.data);
    }
  }, [entityMembers]);

  const filteredMembers = searchTerm.trim() === '' 
    ? entityMembers?.data 
    : entityMembers?.data?.filter(member => 
        `${member.user.first_name} ${member.user.last_name}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        member.user.shukran_id.toLowerCase().includes(searchTerm.toLowerCase())
      );

  // Separate members based on can_join_entity_jar
  const groupMembers = filteredMembers?.filter(member => member.can_join_entity_jar) || [];
  const individualMembers = filteredMembers?.filter(member => !member.can_join_entity_jar) || [];

  const removeMembersFromEntity = useRemoveMemberFromEntity();
  const isRemovingMembersFromEntity = removeMembersFromEntity.isLoading;

  const changeMemberDepartment = useAddMemeberToEntity();
  const isUpdatingMember = changeMemberDepartment.isLoading;

  const [changeDepartmentDialogOpen, setChangeDepartmentDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const handleBack = () => {
    navigate("/tipjar");
  };

  const handleRemoveMemberClick = (member) => {
    setConfirmRemoveMemberFromEntity(true);
    setMemberToRemove(member);
  };

  const handleRemoveMember = async () => {
    const payload = {
      shukran_ids: [memberToRemove.user.shukran_id],
      entity_id: activeEntity.id,
    };

    try {
      await removeMembersFromEntity.mutateAsync(payload);
      refetch();
      toast.success("Member removed successfully");
    } catch (error) {
      toast.error("Failed to remove member from entity");
    }
  };

  const openChangeDepartmentDialog = (member) => {
    setSelectedMember(member);
    setSelectedDepartment(member.department);
    setChangeDepartmentDialogOpen(true);
  };

  const handleChangeDepartment = async (selectedMember, newDepartmentId, canJoinEntityJar) => {
    const payload = {
      entity_id: activeEntity.id,
      user_id: selectedMember.user.id,
      department_id: newDepartmentId,
      can_join_entity_jar: canJoinEntityJar,
    };
  
    try {
      await changeMemberDepartment.mutateAsync(payload);
      refetch();
      setChangeDepartmentDialogOpen(false);
    } catch (error) {
      throw error;
    }
  };

  const renderMembersList = (members) => {
    if (members.length === 0) {
      return (
        <div className="mt-6 empty">
          <img src={user} alt="No members" />
          <p className="w-40 mt-6 text-center desctxt">
            No members available. Add new
          </p>
        </div>
      );
    }

    return members.map((member) => (
      <div
        key={member.id}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          className="border-2 border-[#F5F5F5] w-full min-h-[67px] mb-3"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className="w-1/2 px-2"
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "15px",
                backgroundColor: "#50A2A7",
              }}
              className="flex items-center justify-center text-white text-sm"
            >
              {`${member?.user?.first_name[0]}${member?.user?.last_name[0]}`}
            </div>
            <div className="ml-2">
              <p
                style={{ color: "#404B5A", fontSize: "14px" }}
                className="font-semibold"
              >
                {`${member?.user?.first_name} ${member?.user?.last_name}`}
              </p>
              <div className="flex flex-row items-center gap-1 w-full">
                <p style={{ color: "#929FB1", fontSize: "10px" }}>
                  {member?.user?.shukran_id}
                </p>
                <p style={{ fontSize: "11px" }} className="font-semibold text-slate-600">
                  - {member?.department?.name}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "4px",
            }}
            className="w-fit px-2"
          >
            <Button
              style={{ fontSize: "12px" }}
              className="text-sm text-white px-2 py-1 rounded-sm flex items-center h-[28px] bg-accent"
              onClick={() => openChangeDepartmentDialog(member)}
            >
              <div className="flex items-center gap-2">
                <span className="text-white">Manage</span>
                <RefreshCcw className="mr-1" color="white" size={15} />
              </div>
            </Button>

            <Button
              style={{
                fontSize: "12px",
                backgroundColor: "#EE5582",
              }}
              disabled={isRemovingMembersFromEntity}
              className="text-sm text-white px-2 py-1 rounded-sm flex items-center h-[28px]"
              onClick={() => handleRemoveMemberClick(member)}
            >
              <div className="flex items-center gap-2">
                <span className="text-white">Remove</span>
                <Trash2 className="mr-1" color="white" size={15} />
              </div>
            </Button>
          </div>
        </div>
      </div>
    ));
  };


  const ConfirmChange = ({
    selectedMember,
    entityDepartments,
    onChangeDepartment,
  }) => {
    const [departmentChange, setDepartmentChange] = useState(
      selectedMember?.department?.id
    );
    const [canJoinEntityJar, setCanJoinEntityJar] = useState(
      selectedMember?.can_join_entity_jar
    );
    const [isChangingDepartment, setIsChangingDepartment] = useState(false);
    const hasUserChangedDepartment = useRef(false);
    const activeDepartments = entityDepartments?.filter(
      (el) => el?.active === true
    );
  
    // Update handleChangeDepartment to include can_join_entity_jar
    const handleChangeDepartment = async () => {
      setIsChangingDepartment(true);
      try {
        await onChangeDepartment(selectedMember, departmentChange, canJoinEntityJar);
        toast.success("Member updated successfully");
      } catch (error) {
        toast.error("Failed to update member");
      } finally {
        setIsChangingDepartment(false);
        hasUserChangedDepartment.current = false;
      }
    };
  
    return (
      <section id="confirmchange" className="w-full flex flex-col gap-4">
        <div className="flex items-center space-x-2 mb-4">
          <div className="w-10 h-10 bg-[#50A2A7] rounded-full flex items-center justify-center text-white">
            {selectedMember?.user?.first_name[0]}
            {selectedMember?.user?.last_name[0]}
          </div>
          <div>
            <p className="font-medium">{`${selectedMember?.user?.first_name} ${selectedMember?.user?.last_name}`}</p>
            <p className="text-sm text-gray-500">{selectedMember?.user?.shukran_id}</p>
          </div>
        </div>
  
        <Select value={departmentChange} onValueChange={setDepartmentChange}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select department" />
          </SelectTrigger>
          <SelectContent>
            {activeDepartments.map((department) => (
              <SelectItem key={department?.id} value={department?.id}>
                {department.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
  
        <div className="flex items-center space-x-2">
          <Checkbox
            id="canJoinEntityJar"
            checked={canJoinEntityJar}
            onCheckedChange={setCanJoinEntityJar}
            className="data-[state=checked]:bg-[#6CCBD1] data-[state=checked]:border-[#6CCBD1]"
          />
          <label htmlFor="canJoinEntityJar" className="text-sm">
            Include in group tips
          </label>
        </div>
  
        <Button
          className="bg-accent text-white w-full mt-3"
          disabled={isChangingDepartment}
          onClick={handleChangeDepartment}
        >
          {isChangingDepartment && (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          )}
          Update
        </Button>
      </section>
    );
  };

  return (
    <div className={`flex flex-col h-screen w-full`}>
      <div
        className={`flex flex-col items-center justify-center px-3 ${
          changeDepartmentDialogOpen && `blur-sm`
        }`}
      >
        <div className="w-full p-3">
          <BackButton onPress={handleBack} />
        </div>
        <div className="mx-4 mt-6 h-full w-full" style={{ width: "100%" }}>
          <h2 className="text-3xl font-semibold text-primary-pwa">
            Manage members
          </h2>
          <p
            className="text-md font-semibold text-gray-400"
            style={{ width: "100%" }}
          >
            Add or remove members from TipJar database
          </p>

          <div className="add_box mt-6">
            <AddButton
              title="Add member"
              onClick={() => navigate("/tipjar/add-members/")}
            />
          </div>
          <div className="mt-6">
            <h5>Members ({entityMembers?.data?.length || 0})</h5>
            {isLoadingEntityMembers ? (
              <p>Loading members...</p>
            ) : entityMembersError ? (
              <p>Error loading members: {entityMembersError.message}</p>
            ) : entityMembers?.data && entityMembers.data.length > 0 ? (
              <div className="mt-3 empty">
               
<Tabs defaultValue="group" className="w-full">
  
                  <TabsList className="w-full mb-4">
                    <TabsTrigger value="group" className="w-1/2">Group Tips ({groupMembers.length})</TabsTrigger>
                    <TabsTrigger value="individual" className="w-1/2">Individual Tips ({individualMembers.length})</TabsTrigger>
                  </TabsList>
                  <div className="relative w-full mb-3">
  <input
    type="text"
    placeholder="Search by name or Shukran ID"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    className="w-full h-[55px] pl-12 pr-4 rounded-full border-2 border-[#F5F5F5] focus:outline-none focus:border-[#50A2A7] text-sm"
  />
  <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
    <Search className="h-5 w-5 text-gray-400" />
  </div>
</div>
                  <TabsContent value="group">
                    
                    {renderMembersList(groupMembers)}
                  </TabsContent>
                  <TabsContent value="individual">
                    {renderMembersList(individualMembers)}
                  </TabsContent>
                </Tabs>
              </div>
            ) : (
              <div className="mt-6 empty">
                <img src={user} alt="No members" />
                <p className="w-40 mt-6 text-center desctxt">
                  No members available. Add new
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={changeDepartmentDialogOpen}
        onOpenChange={setChangeDepartmentDialogOpen}
      >
        <DialogContent className="rounded-md">
          <ConfirmChange
            selectedMember={selectedMember}
            entityDepartments={entityDepartments}
            onChangeDepartment={handleChangeDepartment}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={confirmRemoveMemberFromEntity}
        onOpenChange={setConfirmRemoveMemberFromEntity}
      >
        <DialogContent className="rounded-md">
          <DialogHeader className="p-2">
            <DialogTitle>
              {`Are you sure you want to remove ${memberToRemove?.user?.first_name} ${memberToRemove?.user?.last_name}`}
            </DialogTitle>
          </DialogHeader>

          <DialogFooter className="flex flex-row w-full justify-between mt-2">
            <Button
              onClick={() => setConfirmRemoveMemberFromEntity(false)}
              className="w-fit rounded-md h-[60px] bg-[#F2C773] hover:bg-[#e0b665] text-white font-semibold rounded-none flex items-center h-[28px]"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleRemoveMember();
                setConfirmRemoveMemberFromEntity(false);
              }}
              className="w-fit rounded-md h-[60px] bg-red-500 hover:bg-red-400  text-white font-semibold rounded-none flex items-center h-[28px]"
            >
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
