import { useState } from "react";
import ShukranLogo from "../../assets/Logos/shukran-logo-with-tagline-light.png";
import { useNavigate } from "react-router-dom";
import BackButton from "../globals/BackButton";
import { Input } from "../../../src/components-shadcn/ui/input";
import { Button } from "../../../src/components-shadcn/ui/button";
import { Loader2 } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../src/components-shadcn/ui/select";
import { toast } from "sonner";
import { FileIcon, X, Check } from "lucide-react";
import {
  Dialog,
  DialogContent,
} from "../../../src/components-shadcn/ui/dialog";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useUploadKYCDocuments } from "./state/server-state/entityRegistrationServerState";

const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30MB in bytes

const EnterBusinessDetails = () => {
  const navigate = useNavigate();
  const [documentType, setDocumentType] = useState("TRA_CERTIFICATE");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Validation state
  const [validationState, setValidationState] = useState({
    referenceNumber: {
      isValid: false,
      errorMessage: "",
    },
    file: {
      isValid: false,
      errorMessage: "",
    }
  });

  const { activeEntity, setActiveEntity } = useTipJarLocalState();

  const uploadKYCDocuments = useUploadKYCDocuments();
  const isUploadingKYC = uploadKYCDocuments.isLoading;

  const handleBack = () => {
    navigate(-1);
  };

  // Validation functions
  const validateReferenceNumber = (number) => {
    if (!number || number.trim().length === 0) {
      return {
        isValid: false,
        errorMessage: "Document reference number is required"
      };
    }
    return {
      isValid: true,
      errorMessage: ""
    };
  };

  const validateFile = (file) => {
    if (!file) {
      return {
        isValid: false,
        errorMessage: "Please select a document"
      };
    }

    const fileType = file.type.toLowerCase();
    const validTypes = ["image/png", "image/jpeg", "application/pdf"];

    if (!validTypes.includes(fileType)) {
      return {
        isValid: false,
        errorMessage: "Please select a PNG, JPEG, or PDF file"
      };
    }

    if (file.size > MAX_FILE_SIZE) {
      return {
        isValid: false,
        errorMessage: "File size must not exceed 30MB"
      };
    }

    return {
      isValid: true,
      errorMessage: ""
    };
  };

  const handleReferenceNumberChange = (e) => {
    const value = e.target.value;
    setReferenceNumber(value);
    
    const validationResult = validateReferenceNumber(value);
    setValidationState(prev => ({
      ...prev,
      referenceNumber: validationResult
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validationResult = validateFile(file);
      setValidationState(prev => ({
        ...prev,
        file: validationResult
      }));

      if (validationResult.isValid) {
        setSelectedFile(file);
      } else {
        toast.error(validationResult.errorMessage);
      }
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    setValidationState(prev => ({
      ...prev,
      file: {
        isValid: false,
        errorMessage: ""
      }
    }));
  };

  const isFormValid = () => {
    return validationState.referenceNumber.isValid && validationState.file.isValid;
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      toast.error("Please fill all required fields correctly");
      return;
    }

    const formData = new FormData();
    formData.append("entity_id", activeEntity.id);
    formData.append("document", selectedFile);
    formData.append("document_type", documentType);

    try {
      const response = await uploadKYCDocuments.mutateAsync(formData);
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        navigate("/entity-in-review/");
      }, 2000);
    } catch (error) {
      toast.error("Failed to upload KYC documents");
    }
  };

  return (
    <div className="w-full h-full min-h-screen bg-imag-dash-2 flex flex-col items-center">
      <div className="w-full px-4">
        <BackButton onPress={handleBack} />
      </div>

      <div className="w-full flex justify-center mt-8 mb-16">
        <img src={ShukranLogo} alt="Shukran Logo" className="w-32" />
      </div>

      <div className="w-full h-full bg-white rounded-tl-[20px] rounded-tr-[20px] p-6">
        <div className="w-full flex flex-col gap-2">
          <h1 className="text-2xl font-semibold text-[#50A2A7] mb-6">
            Enter business details
          </h1>

          <p className="text-sm text-gray-500 mb-6">
            Select the document type and enter reference number
          </p>

          <div className="space-y-6">
            <Select defaultValue={documentType} onValueChange={setDocumentType}>
              <SelectTrigger className="w-full h-[55px]">
                <SelectValue placeholder="Select document type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="TRA_CERTIFICATE">TRA Certificate</SelectItem>
              </SelectContent>
            </Select>

            <div>
              <Input
                placeholder="Document reference number"
                value={referenceNumber}
                onChange={handleReferenceNumberChange}
                className="h-[55px]"
              />
              <p className={`text-xs mt-1 ${validationState.referenceNumber.errorMessage ? "text-red-500" : "text-gray-400"}`}>
                {validationState.referenceNumber.errorMessage || "Enter your document reference number"}
              </p>
            </div>

            {!selectedFile ? (
              <div className="mt-4">
                <p className="text-sm text-gray-500 mb-2">Upload document</p>
                <label className="cursor-pointer flex items-center justify-between p-3 border-2 border-dashed border-gray-300 rounded-lg">
                  <span className="text-gray-500">Choose File</span>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept=".png,.jpg,.jpeg,.pdf"
                    className="hidden"
                  />
                  <span className="text-gray-400">No file selected</span>
                </label>
                <p className={`text-xs mt-1 ${validationState.file.errorMessage ? "text-red-500" : "text-gray-400"}`}>
                  {validationState.file.errorMessage || "Select a PNG, JPEG, or PDF file (max 30MB)"}
                </p>
              </div>
            ) : (
              <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                <div className="flex items-center">
                  <FileIcon className="h-5 w-5 text-[#50A2A7] mr-2" />
                  <span className="text-sm">{selectedFile.name}</span>
                </div>
                <button onClick={removeFile}>
                  <X className="h-5 w-5 text-gray-400" />
                </button>
              </div>
            )}
          </div>

          <Button
            onClick={handleSubmit}
            disabled={!isFormValid() || isUploadingKYC}
            className="w-full mt-6 bg-[#F2C773] hover:bg-[#e0b665] text-white rounded-full h-[60px] disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isUploadingKYC ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                SUBMITTING KYC...
              </>
            ) : (
              "SUBMIT"
            )}
          </Button>
        </div>
      </div>

      <Dialog open={showSuccessModal} onOpenChange={setShowSuccessModal}>
        <DialogContent className="sm:max-w-[425px] flex flex-col items-center text-center p-6 rounded-lg">
          <div className="rounded-full bg-[#50A2A7]/10 p-3 mb-4">
            <div className="rounded-full bg-[#50A2A7] p-2">
              <Check className="h-6 w-6 text-white" />
            </div>
          </div>
          <h3 className="font-semibold text-lg mb-2">Submission successful</h3>
          <p className="text-gray-500">
            Your verification results will be out in 24 - 48 hours
          </p>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EnterBusinessDetails;