import React, { useState, useCallback, useRef } from "react";
import { Input } from "../../components-shadcn/ui/input";
import { Button } from "../../components-shadcn/ui/button";
import { useTipJarLocalState } from "../Tipjar/state/local-state/useTipjarLocalState";
import { useUpdateDepartment } from "./state/server-state/useDepartmentServerState";
import BackButton from "../globals/BackButton";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Loader2, CirclePlus, RefreshCcw } from "lucide-react";

const ChangeDistribution = () => {
  const navigate = useNavigate();
  const { entityDepartments } = useTipJarLocalState();
  const [departments, setDepartments] = useState(
    entityDepartments.map((dept) => ({
      name: dept.name,
      points: dept.distribution_points,
      id: dept.id,
      active: dept.active,
    }))
  );

  const updateDepartment = useUpdateDepartment();
  const isUpdatingDepartment = updateDepartment.isLoading;

  // Use useRef to store the timeout IDs
  const timeoutRef = useRef({});

  // Custom debounce function
  const debounceUpdate = useCallback((departmentId, updateFunction) => {
    // Clear existing timeout for this department if it exists
    if (timeoutRef.current[departmentId]) {
      clearTimeout(timeoutRef.current[departmentId]);
    }

    // Set new timeout
    timeoutRef.current[departmentId] = setTimeout(async () => {
      try {
        await updateFunction();
        toast.success("Update successful");
      } catch (error) {
        toast.error("Failed to update");
      }
      // Clear the timeout reference after it's done
      delete timeoutRef.current[departmentId];
    }, 700); // 1 second delay
  }, []);

  // Cleanup timeouts on unmount
  React.useEffect(() => {
    return () => {
      Object.values(timeoutRef.current).forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    };
  }, []);

  const handleBack = () => {
    if(isUpdatingDepartment){
      toast.error("Please wait until the update is complete");
      return;
    }
    navigate(-1);
  };

  const findDepartmentById = (departmentId) => {
    return departments.findIndex((dept) => dept.id === departmentId);
  };

  const handleNameChange = (departmentId, value) => {
    const index = findDepartmentById(departmentId);
    if (index === -1) return;

    const newDepartments = [...departments];
    newDepartments[index].name = value;
    setDepartments(newDepartments);

    const updateData = {
      name: value,
      distribution_points: newDepartments[index].points,
      department_id: departmentId,
    };

    debounceUpdate(departmentId, () =>
      updateDepartment.mutateAsync(updateData)
    );
  };

  const handlePointsChange = (departmentId, value) => {
    const index = findDepartmentById(departmentId);
    if (index === -1) return;

    const points = Math.min(Math.max(0, parseInt(value) || 0), 10);
    const newDepartments = [...departments];
    newDepartments[index].points = points;
    setDepartments(newDepartments);

    const updateData = {
      name: newDepartments[index].name,
      distribution_points: points,
      department_id: departmentId,
    };

    debounceUpdate(departmentId, () =>
      updateDepartment.mutateAsync(updateData)
    );
  };

  const adjustPoints = (departmentId, amount) => {
    const index = findDepartmentById(departmentId);
    if (index === -1) return;

    const newDepartments = [...departments];
    const newPoints = Math.min(
      Math.max(0, newDepartments[index].points + amount),
      10
    );
    newDepartments[index].points = newPoints;
    setDepartments(newDepartments);

    const updateData = {
      name: newDepartments[index].name,
      distribution_points: newPoints,
      department_id: departmentId,
    };

    debounceUpdate(departmentId, () =>
      updateDepartment.mutateAsync(updateData)
    );
  };

  return (
    <div className="flex flex-col px-3 w-full h-screen">
      <div className="w-screen">
        <BackButton onPress={handleBack} />
      </div>

      <h2 className="text-3xl font-semibold text-primary-pwa mt-6">
        Points distribution
      </h2>
      <p className="text-md font-semibold text-gray-400 mt-2 mb-6">
        Change the distribution allocation for existing departments
      </p>

      <div></div>

      {departments
        .filter((el) => el.active === true)
        .map((department) => (
          <div key={department.id} className="mb-6">
            <div className="flex gap-2 mb-2">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Department
                </label>
                <Input
                  value={department.name}
                  onChange={(e) =>
                    handleNameChange(department.id, e.target.value)
                  }
                  className="w-full border-[#C1F2F5] shadow-md h-[60px]"
                />
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Distribution points
                </label>
                <Input
                  type="number"
                  value={department.points}
                  onChange={(e) =>
                    handlePointsChange(department.id, e.target.value)
                  }
                  min="0"
                  max="10"
                  className="w-full border-[#C1F2F5] shadow-md h-[60px]"
                />
              </div>
            </div>
            <div className="flex justify-end gap-2">
              <Button
                onClick={() => adjustPoints(department.id, -1)}
                className="bg-[#F2C773] hover:bg-[#e0b665] text-white font-bold py-2 px-4 rounded shadow-md"
                disabled={department.points <= 0}
              >
                -
              </Button>
              <Button
                onClick={() => adjustPoints(department.id, 1)}
                className="bg-[#F2C773] hover:bg-[#e0b665] text-white font-bold py-2 px-4 rounded shadow-md"
                disabled={department.points >= 10}
              >
                +
              </Button>
            </div>
          </div>
        ))}

      <div className="w-full mt-auto pb-6">
        <Button
          className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-white font-bold py-3 px-4 rounded-full shadow-md h-[60px]"
          onClick={handleBack}
          disabled={isUpdatingDepartment}
        >
          {isUpdatingDepartment ? (
            <div className="w-full flex flex-row items-center gap-2 justify-center">
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              <span className="text-white">UPDATING...</span>
            </div>
          ): <span className="text-white">BACK TO DEPARTMENTS</span>}
        </Button>
      </div>
    </div>
  );
};

export default ChangeDistribution;
