import PhoneLeft from "../../assets/Images/phone_left.png";
import PhoneRight from "../../assets/Images/phone_right.png";
import PlatformToTip from "../../assets/Images/platform_to_tip.png";
import Rating from "../../assets/Images/rating.png";
import { Download, Fingerprint, CreditCard } from "lucide-react";
const PaymentComplete = () => {
  const handleGetApp = () => {
    window.location.href = "https://play.google.com/store/apps/details?id=com.shukran.main&hl=en";
  };
  return (
    <div className="flex flex-col items-center p-6 max-w-md mx-auto">
      <div className="flex justify-between w-full mb-8 gap-2">
        <img src={PhoneLeft} alt="Phone Left" className="w-1/3 h-auto" />
        <img src={PhoneRight} alt="Phone Right" className="w-1/2 h-auto" />
      </div>

      <h1 className="text-2xl font-semibold text-[#50A2A7] mb-4">
        Download Shukran Tip!
      </h1>

      <img src={Rating} alt="Rating" className="mb-4 w-32" />
      <img src={PlatformToTip} alt="Platform" className="mb-6 w-48" />

      <h2 className="text-lg font-medium mb-4">Our new app features:</h2>

      <div className="space-y-4 w-full mb-8 flex flex-col items-center">
        <div className="flex items-center gap-3">
          <Download className="text-[#50A2A7]" />
          <span>Tip without registering</span>
        </div>
        <div className="flex items-center gap-3">
          <Fingerprint className="text-[#50A2A7]" />
          <span>Log-in with biometrics</span>
        </div>
        <div className="flex items-center gap-3">
          <CreditCard className="text-[#50A2A7]" />
          <span>Pay bills using card</span>
        </div>
      </div>

      <button className="w-full bg-[#E9B44C] text-white py-4 rounded-full font-medium"   onClick={handleGetApp}>
        GET THE APP
      </button>
    </div>
  );
};

export default PaymentComplete;
