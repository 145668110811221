import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import back from "../../assets/Icons/Rating/back_icon.svg";
import { useNavigate } from "react-router-dom";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import stripeLoader from "../../assets/Animation/stripe/shukran_loader.json";
import { stripeTest, testUrl } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../globals/Loader";
import BackButton from "../globals/BackButton";
import confetti from "../../assets/Icons/Tipping/confetti.gif";
import checkCircle from "../../assets/Icons/Popups/check_circle.svg";
import ConfirmTransaction from "../globals/ConfirmTransaction";
import { balanceActions } from "../../state/reducers/user/user.actions";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { postJson } from "../../utils/fetch.utils";
import userStore from "../../store/user.store";
import { useToast } from "../../components-shadcn/ui/use-toast";
import { BASE_URL } from "../../constants/network";

const stripeKey = loadStripe(
  "pk_live_51OeJHtChcCBTvsVbEfyttS3i0GjI2aIVTpfWPr14DJvvVuqvqPnonHeLgUrITNPSh01ecgyIPlLR8qfAOvlVTkvu00rDi2NJh9"
);

const Stripe = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [emailSet, setEmailSet] = useState(false);
  const [email, setEmail] = useState("");
  const [emailToken, setEmailToken] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const account = useSelector((state) => state.account);
  const navigate = useNavigate();
  const tipData = useSelector((state) => state.tipData);
  let nonShukranId = tipData.nonShukranId;
  let queryShukranId = tipData.queryShukranId;
  const shukranId = tipData.shukranId;
  let queryName = tipData.queryName;
  let currency = tipData.currency;
  let tipAmount = tipData.tipAmount;

  const { toast } = useToast();

  const createStripeCustomerMutation = useMutation({
    mutationFn: async () => {
      const resp = await postJson(
        `${BASE_URL}api/v1/remittances/stripe/customer/`,
        {
          shukran_id: userStore?.getState().user?.shukran_id,
          email: email,
        }
      );

      if (resp.success) {
        userStore.getState().setUserInfo({
          ...userStore.getState()?.user,
          stripe_customer_id: resp.data.id,
        });
        // setUserUser({
        //   ...user,
        //   stripe_customer_id: resp.data.id,
        // });
        // alert(JSON.stringify(resp.data));
      } else {
        toast({
          title:
            resp?.msg ?? "An error occurred while creating stripe customer",
        });
        // TODO: Get Error message from response
        throw new Error("An error occurred while creating stripe customer");
      }
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    (() => {})();
  }, []);
  const options = {
    // passing the client secret obtained from the server updated issue
    clientSecret: clientSecret,
  };

  const emailAuthentication = async () => {
    if (tipData.tipAmount === undefined) {
      navigate(-1);
    } else {
      if (email) {
        setLoading(true);
        const body = {
          email: email,
        };
        const response = await axios.post(
          `${testUrl}accounts/auth/signup-email-only/otp/`,
          body
        );
        if (response.data?.success) {
          setEmailToken(response?.data?.data?.token);
          setConfirmPayment(true);
        }
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    let timeout;
    if (isSuccess === true) {
      timeout = setTimeout(() => {
        navigate("/download");
      }, 2000);
    }
    return () => clearTimeout(timeout);
  }, [isSuccess]);
  return (
    <div className="flex flex-col w-full h-screen">
      <div className="w-full p-3">
        <BackButton onPress={handleBack} />
      </div>

      <div className="px-4 flex flex-col">
        <div className="mt-4">
          {clientSecret?.split("").length > 0 ? (
            <label className="text-3xl font-semibold text-primary-pwa">
              Please Enter Your Card Details To Tip Using Stripe.
            </label>
          ) : (
            <label className="text-3xl font-semibold text-primary-pwa">
              Please Enter Your Email To Receive Card Payments Receipts.
            </label>
          )}
        </div>
        {emailSet ? (
          <div className="mt-4">
            {clientSecret?.split("").length > 0 && (
              <Elements stripe={stripeKey} options={{ clientSecret }}>
                <TipWithStripe
                  setIsSuccess={setIsSuccess}
                  setLoadingConfirm={setLoadingConfirm}
                />
              </Elements>
            )}
          </div>
        ) : (
          <div className="mt-0 ">
            <input
              type="text"
              className="w-full shadow-lg mt-10 p-4"
              placeholder="johndoe@gmail.com"
              value={email}
              {...register("email", {
                required: true,
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              })}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              autoSave={true}
            />
            <div className="w-full mt-5 flex flex-row items-center justify-center">
              <button
                onClick={() => {
                  const re = /\S+@\S+\.\S+/;
                  if (re.test(email)) {
                    // setConfirmPayment(true);
                    //emailAuthentication();
                    setConfirmPayment(true);
                    if (userStore.getState().user) {
                      createStripeCustomerMutation.mutate();
                    }
                  } else {
                    toast.warning("enter a valid email", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                  }
                }}
                className="w-1/2 text-white justify-center h-14 rounded-xl bg-accent"
              >
                Confirm
              </button>
            </div>
          </div>
        )}
      </div>
      {isSuccess && <SuccessModal setIsSuccess={setIsSuccess} />}
      <ToastContainer />
      {loading && <Loader desc={"Processing Your Card Payment!"} />}
      {loadingConfirm && (
        <Loader desc={"Hold Tight As We Process Your Payment"} />
      )}
      {confirmPayment && !loading && (
        <ConfirmTransaction
          method={"Stripe"}
          submitTip={() => {
            const tipPayload = {
              charge_tipper: false,
              receivers: [
                {
                  receiver_shukran_id: shukranId
                    ? shukranId
                    : nonShukranId
                    ? nonShukranId
                    : queryShukranId
                    ? queryShukranId
                    : tipData?.phone,
                  amount: tipAmount,
                },
              ],
              sender: email,
              currency: currency === "USD" ? "USD" : "KES",
              setup_future_usage: false,
              customer: userStore.getState()?.user?.stripe_customer_id,
              method: "stripe",
            };
            setLoading(true);
            const re = /\S+@\S+\.\S+/;
            if (re.test(email)) {
              fetch(`${testUrl}api/v1/tips/`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer `,
                  XAT: "U",
                  "X-IDT": "A",
                },

                body: JSON.stringify(tipPayload),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data?.detail?.split("").length > 1) {
                    setConfirmPayment(false);
                    setLoading(false);
                  }
                  if (data?.data?.key?.split("").length > 1) {
                    setLoading(false);
                    setConfirmPayment(false);
                    setClientSecret(data?.data?.key);
                    setEmailSet(true);
                  } else {
                    setLoading(false);
                    toast.warning("stripe couldn't load, try again please", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                  }
                })
                .catch((e) => {
                  setLoading(false);
                })
                .finally(() => {
                  setLoading(false);
                });
            } else {
              toast.warning("enter a valid email", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoading(false);
            }
          }}
          queryShukranId={queryShukranId}
          nonShukranId={nonShukranId}
          tipData={tipData}
          queryName={queryName}
          onPress={() => {
            setConfirmPayment(false);
          }}
        />
      )}
    </div>
  );
};

const SuccessModal = ({ setIsSuccess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="w-full absolute h-screen backdrop-blur-sm flex items-center justify-center">
      <div className="my-auto blur-none px-4 drop-shadow-md bg-white w-4/5 py-10 flex flex-col relative pb-4 rounded-3xl z-50">
        <button
          className="absolute top-3 text-gray-400 right-4 px-2 text-2xl "
          onClick={() => {
            dispatch(balanceActions.tippingSuccess({}));
            dispatch(balanceActions.checkTippingSuccess(""));
            setIsSuccess(false);
            navigate("/download");
            // addShukranId({})
          }}
        >
          X
        </button>

        <div className="flex flex-row justify-between">
          <img src={confetti} alt="confetti" className="h-28 -scale-x-100" />
          <img
            src={checkCircle}
            alt="error-img"
            className="h-24 left-1/3 absolute"
          />
          <img src={confetti} alt="confetti" className="h-28" />
        </div>

        {/* Check status of express tipping error responses */}
      </div>
    </div>
  );
};
const TipWithStripe = ({ setLoadingConfirm, setIsSuccess }) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoadingConfirm(true);
    const result = await stripe.confirmPayment({
      elements: elements,
      confirmParams: {
        return_url: "https://app.shukran.co/download",
      },
    });
    if (result.error) {
      toast.warning(result.error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoadingConfirm(false);
      setIsSuccess(false);
    } else {
      setLoadingConfirm(false);
      // toast.success("Payment was succesfull", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      setIsSuccess(true);
      //navigate("/download");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          supportedPaymentMethods: ["card"],
        }}
      />
      <div className="w-full mt-10 flex flex-row items-center justify-center">
        <button
          className="w-4/5 text-white h-16 rounded-xl items-center self-center bg-[#000]"
          type="submit"
        >
          Pay
        </button>
      </div>
    </form>
  );
};

export default Stripe;
