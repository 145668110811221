import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components-shadcn/ui/dialog";
import { Button } from "../../../components-shadcn/ui/button";
import { Input } from "../../../components-shadcn/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components-shadcn/ui/select";
import { ArrowLeftRight, ArrowRightLeft, Loader2 } from "lucide-react";

const TransferFundsDialog = ({
  isOpen,
  onClose,
  activeEntityJAR,
  entityJars,
  onTransfer,
  isTransferring,
}) => {
  const [transferType, setTransferType] = useState("from"); // 'to' or 'from'
  const [selectedJar, setSelectedJar] = useState("");
  const [amount, setAmount] = useState("");

  const otherJars =
    entityJars?.filter((jar) => jar.id !== activeEntityJAR?.id && jar.active) ||
    [];

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setAmount(value);
    }
  };

  const handleTransfer = () => {
    if (!amount || !selectedJar) {
      return;
    }

    const payload = {
      currency: "KES",
      origin_user_id:
        transferType === "from" ? activeEntityJAR.user : parseInt(selectedJar),
      destination_user_id:
        transferType === "to" ? activeEntityJAR.user : parseInt(selectedJar),
      amount: parseFloat(amount),
    };

    onTransfer(payload);
  };

  const maxAmount =
    transferType === "from"
      ? activeEntityJAR?.jar_balance || 0
      : entityJars?.find((jar) => jar.user.toString() === selectedJar)
          ?.jar_balance || 0;

  const selectedJarName = entityJars?.find(
    (jar) => jar.user.toString() === selectedJar
  )?.name;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] rounded-lg">
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold text-[#50A2A7] text-center">
            Transfer Funds
          </DialogTitle>
        </DialogHeader>

        <div className="py-4 space-y-4">
          {/* Transfer Direction Select */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              From {activeEntityJAR?.name}
            </label>
            <Select value={transferType} onValueChange={setTransferType} disabled>
              <SelectTrigger className="w-full h-[55px]">
                <SelectValue placeholder="Select transfer type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="to">
                  <div className="flex items-center">
                    <ArrowRightLeft className="mr-2 h-4 w-4" />
                    Transfer to {activeEntityJAR?.name}
                  </div>
                </SelectItem>
                <SelectItem value="from">
                  <div className="flex items-center">
                    <ArrowLeftRight className="mr-2 h-4 w-4" />
                    Transfer from {activeEntityJAR?.name}
                  </div>
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* JAR Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {transferType === "to" ? "Source JAR" : "To"}
            </label>
            <Select value={selectedJar} onValueChange={setSelectedJar}>
              <SelectTrigger className="w-full h-[55px]">
                <SelectValue placeholder="Select JAR" />
              </SelectTrigger>
              <SelectContent>
                {otherJars.map((jar) => (
                  <SelectItem key={jar.id} value={jar.user.toString()}>
                    {jar.name} - KES {jar.jar_balance?.toLocaleString()}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Amount Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Amount (KES)
            </label>
            <Input
              type="number"
              value={amount}
              onChange={handleAmountChange}
              placeholder="Enter amount"
              className="h-[55px]"
            />
            <p className="text-sm text-gray-500 mt-1">
              Available balance: KES {maxAmount?.toLocaleString()}
            </p>
          </div>

          {/* Transfer Summary */}
          {amount && selectedJar && (
            <div className="bg-[#E6F7F8] rounded-lg p-4">
              <p className="text-sm font-medium mb-2">Transfer Summary:</p>
              <p className="text-sm">
                {transferType === "to" ? "From: " : "To: "} {selectedJarName}
              </p>
              <p className="text-sm">
                {transferType === "to" ? "To: " : "From: "}{" "}
                {activeEntityJAR?.name}
              </p>
              <p className="text-sm font-medium mt-2">
                Amount: KES {parseFloat(amount).toLocaleString()}
              </p>
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex flex-col gap-2">
            <Button
              onClick={handleTransfer}
              disabled={
                !amount || !selectedJar || isTransferring || amount > maxAmount
              }
              className="w-full bg-[#F2C773] hover:bg-[#e0b665] text-white h-[60px] rounded-full"
            >
              {isTransferring && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              {isTransferring ? "Processing..." : "Confirm Transfer"}
            </Button>
            <Button
              onClick={onClose}
              variant="outline"
              className="w-full border-[#F2C773] text-[#F2C773] h-[60px] rounded-full"
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TransferFundsDialog;
