import React, { useState, useEffect,useMemo  } from "react";
import { useNavigate } from "react-router-dom";
import { useTipJarLocalState } from "./state/local-state/useTipjarLocalState";
import BackButton from "../globals/BackButton";
import { useParams } from "react-router-dom";
import { Button } from "../../components-shadcn/ui/button";


const DisbursmentOverview = () => {

    const { entityJarId } = useParams();
    const {entityJARs,activeEntityJAR, activeTipJarReport}=useTipJarLocalState();
    console.log("ACTIVE TIPJAR REPORT ", activeTipJarReport)
    
    console.log("ACTIVE Entity JAR ", activeEntityJAR)

    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };

    // Calculate total distributed amount and get latest distribution date
  const { totalDistributed, latestDistribution } = useMemo(() => {
    if (!activeTipJarReport) {
      return { totalDistributed: 0, latestDistribution: null };
    }

    if (Array.isArray(activeTipJarReport)) {
      const total = activeTipJarReport.reduce(
        (sum, report) => sum + (report.total_distribution_amount || 0),
        0
      );
      
      // Find most recent distribution
      const latest = activeTipJarReport.reduce((latest, current) => {
        if (!latest) return current;
        return new Date(current.created_at) > new Date(latest.created_at) 
          ? current 
          : latest;
      }, null);

      return { totalDistributed: total, latestDistribution: latest };
    }

    // Single report case
    return {
      totalDistributed: activeTipJarReport.total_distribution_amount || 0,
      latestDistribution: activeTipJarReport
    };
  }, [activeTipJarReport]);

  // Format currency
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-KE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount || 0);
  };

  // Format date
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(date).toLowerCase();
    } catch (error) {
      return "";
    }
  };

  // Get member count for each group
  const getMemberCountByGroup = (groupId) => {
    if (!activeEntityJAR?.members) return 0;
    return activeEntityJAR.members.filter(member => member.group === groupId).length;
  };

  // Find group leader
  const groupLeader = activeEntityJAR?.members?.find(member => member.is_team_leader);


    return (
        <div className="flex flex-col w-full h-screen p-2">
        <div className="w-full p-0">
        <BackButton onPress={handleBack} />
       <h1 className="text-2xl font-semibold text-[#6CCBD1] mb-2 mt-2">
        {activeEntityJAR?.name}
      </h1>
        </div>

        {/* Balance Cards */}
      <div className="space-y-3 mb-6">
        {/* Current Balance */}
        <div className="bg-[#E9F6FE] rounded-lg p-4">
          <p className="text-gray-600 text-sm mb-1">Current Balance</p>
          <p className="text-[#6CCBD1] text-2xl font-bold">
            KSH {formatAmount(activeEntityJAR?.jar_balance)}
          </p>
        </div>

        {/* Total Distributed */}
        {totalDistributed > 0 && (
          <div className="bg-[#E9F6FE] rounded-lg p-4">
            <div className="flex justify-between items-start flex-col ">
              
                <p className="text-gray-600 text-sm mb-1">Total Distributed</p>
                <p className="text-[#6CCBD1] text-2xl font-bold">
                  KSH {formatAmount(totalDistributed)}
                </p>
             
              {latestDistribution && (
                <p className="text-sm text-white text-center p-2 bg-[#6CCBD1] rounded-md">
                  Last distribution - {formatDate(latestDistribution.created_at)}
                </p>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Group Leader Section */}
      <div className="mb-6">
        <h2 className="text-gray-700 font-medium mb-2">Group leader</h2>
        {groupLeader ? (
          <div className="bg-[#E9F6FE] border border-[#6CCBD1] rounded-lg p-4 flex items-center">
            <div className="w-10 h-10 rounded-full bg-[#6CCBD1] flex items-center justify-center text-white mr-3">
              {groupLeader.user.first_name[0]}{groupLeader.user.last_name[0]}
            </div>
            <div>
              <p className="font-medium">{`${groupLeader.user.first_name} ${groupLeader.user.last_name}`}</p>
              <p className="text-sm text-gray-500">{groupLeader.user.shukran_id}</p>
            </div>
          </div>
              
         
        ): (
            <div className="bg-[#E9F6FE] border border-[#6CCBD1] rounded-lg p-4 flex items-start justify-start">
                <p >No group leader</p>
               
            </div>
            )}
      </div>

      {/* Teams and Individuals */}
      <div>
        <h2 className="text-gray-700 font-medium mb-2">Teams and members</h2>
        <div className="space-y-3">
          {activeEntityJAR?.groups?.map((group) => (
            <div 
              key={group.id}
              className="flex items-center justify-between p-3 border rounded-lg"
            >
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-gradient-to-b from-yellow-300 to-teal-300 mr-3"></div>
                <span>{group.name}</span>
              </div>
              <span className="text-gray-500">
                {getMemberCountByGroup(group.id)} members
              </span>
            </div>
          ))}
        </div>
      </div>
      {/* View Report Button */}
      <div className="mt-3 mb-3 pb-3">

   
      <Button
          className="w-full bg-white border-2 border-[#F2C773] text-[#F2C773] h-[60px] rounded-full font-semibold pb-2"
          onClick={()=>navigate("/full-disbursment-report/")}
         
        >
        FULL REPORT
      </Button>
      </div>
         </div>
    )



}

export default DisbursmentOverview