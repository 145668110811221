import {
  useQuery,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
  useMutation,
} from "@tanstack/react-query";
import { AxiosInstance, AxiosResponse } from "axios";
import { constructApiUrl } from "../../../../utils/constructApiUrl";
import useAxios from "../../../../shared/useAxios";
import { TipJarQueryTypes } from "./tipjarQueryTypes";
import axios from "axios";

const createTipJarGroup = async (
  $http: AxiosInstance,
  options: { name: string; jar_id: string; disbursement_points: number }
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/create-group/`);
  try {
    const response = await $http.post(apiUrl, options);
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

export const useCreateTipJarGroup = () => {
  const queryClient = useQueryClient();
  const $http = useAxios();

  return useMutation({
    mutationKey: [TipJarQueryTypes.CREATE_GROUP],
    mutationFn: (payload: {
      name: string;
      jar_id: string;
      disbursement_points: number;
    }) => createTipJarGroup($http, payload),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([TipJarQueryTypes.CREATE_GROUP]);
    },
    onError: (error) => {
      throw new Error("An unexpected error occurred");
    },
  });
};

type ShukranIds = {
  shukran_id: string;
  group_id: string;
};

const addMemberToGroup = async (
  $http: AxiosInstance,
  options: { entity_jar: string; shukran_ids: ShukranIds[] }
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/add-jar-members/`);
  try {
    const response = await $http.post(apiUrl, options);
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

export const useAddMemeberToGroup = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.ADD_MEMBER],
    mutationFn: (options: { entity_jar: string; shukran_ids: ShukranIds[] }) =>
      addMemberToGroup($http, options),
  });
};

const removeMemberFromGroup = async (
  $http: AxiosInstance,
  options: { shukran_ids: string[]; entity_jar: string }
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/remove-jar-members/`);
  try {
    const response = await $http.post(apiUrl, options);
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

export const useRemoveMemberFromGroup = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.REMOVE_MEMBER],
    mutationFn: (options: { shukran_ids: string[]; entity_jar: string }) =>
      removeMemberFromGroup($http, options),
  });
};

const updateTipJarGroup = async (
  $http: AxiosInstance,

  payload: { name: string; disbursement_points: number; groupId: string }
): Promise<any> => {
  const { groupId } = payload;
  const apiUrl = constructApiUrl(`/tipjar/entity/${groupId}/update-group/`);
  try {
    const response = await $http.patch(apiUrl, payload);
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

export const useUpdateTipJarGroups = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.UPDATE_GROUP],
    mutationFn: (options: {
      name: string;
      disbursement_points: number;
      groupId: string;
    }) => updateTipJarGroup($http, options),
  });
};

const fetchEntityTipJarInfo = async ($http: AxiosInstance): Promise<any> => {
  const apiUrl = constructApiUrl("api/v1/tipjar/");
  try {
    const response = await $http.post(apiUrl);

    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }

    throw new Error("An unexpected error occurred");
  }
};

export const useGetMainTipJarInfo = () => {
  const $http = useAxios();

  return useQuery<AxiosResponse<any, any>, unknown>({
    queryKey: [TipJarQueryTypes.GET_MAIN_TIPJAR_INFO],
    queryFn: () => fetchEntityTipJarInfo($http),
    keepPreviousData: true,
  });
};

const fetchMembersInEntity = async (
  $http: AxiosInstance,
  entityId: string
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/${entityId}/entity-members/`);

  try {
    const response = await $http.get(apiUrl);
    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }
    throw new Error("Failed to fetch members in entity");
  }
};

export const useFetchEntityMembers = (entityId: string) => {
  const $http = useAxios();
  return useQuery<AxiosResponse<any, any>, unknown>({
    queryKey: [TipJarQueryTypes.FETCH_ENTITY_MEMBERS, entityId],
    queryFn: () => fetchMembersInEntity($http, entityId),
  });
};

export const useFetchEntityMembersMutation= () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.FETCH_ENTITY_MEMBERS],
    mutationFn: (entityId: string) => fetchMembersInEntity($http, entityId),
  });
  
}

export const useGetMembersInEntityMutation = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.FETCH_ENTITY_MEMBERS],
    mutationFn: (entityId: string) => fetchMembersInEntity($http, entityId),
  });
};

const addMemberToEntity = async (
  $http: AxiosInstance,
  options: {
    entity_id: string;
    user_id: number;
    department_id: string;
  }
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/members/add-member/`);
  try {
    const response = await $http.post(apiUrl, options);
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

export const useAddMemeberToEntity = () => {
  const $http = useAxios();
  return useMutation({
    mutationFn: (options: {
      entity_id: string;
      user_id: number;
      department_id: string;
    }) => addMemberToEntity($http, options),
  });
};

interface EntityMember {
  user_id: number;
  department_id?: string; // optional
  can_join_entity_jar: boolean;
}

interface EntityMembersPayload {
  entity_id: string;
  users_ids: EntityMember[];
}

const addMembersToEntity = async (
  $http: AxiosInstance,
  options: EntityMembersPayload
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/members/add-members/`);
  try {
    const response = await $http.post(apiUrl, options);
    return response;
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

export const useAddMemebersToEntity = () => {
  const $http = useAxios();
  return useMutation({
    mutationFn: (options: EntityMembersPayload) =>
      addMembersToEntity($http, options),
  });
};

const updateGroupDisbursmentPoints = async (
  $http: AxiosInstance,
  options: {
    name: string;
    disbursement_points: string;
    groupId: string;
  }
): Promise<any> => {
  const { groupId, disbursement_points, name } = options;
  const apiUrl = constructApiUrl(`/tipjar/entity/${groupId}/update-group/`);
  const payload = { name, disbursement_points };
  try {
    const response = await $http.post(apiUrl, payload);
    return response;
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

export const useUpdateDistributionPoints = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.UPDATE_GROUP],
    mutationFn: (options: {
      name: string;
      disbursement_points: string;
      groupId: string;
    }) => updateGroupDisbursmentPoints($http, options),
  });
};

export const updateGroupDisbursmentPointsWithoutMutation = async (options: {
  name: string;
  disbursement_points: string;
  groupId: string;
}): Promise<any> => {
  const { groupId, disbursement_points, name } = options;

  // const apiUrl = constructApiUrl(`/tipjar/entity/${groupId}/update-group/`);
  const url = `https://staging.shukran.co/tipjar/entity/${groupId}/update-group/`;
  const payload = { name, disbursement_points };
  try {
    const response = await axios.patch(url, payload);
    return response;
  } catch (error) {
    throw new Error("An unexpected error occurred");
  }
};

const getDistributionPointsData = async (
  $http: AxiosInstance,
  options: { entity_jar: string }
): Promise<any> => {
  const apiUrl = constructApiUrl(
    `/tipjar/point-system/calculate-disbursement/`
  );

  try {
    const response = await $http.post(apiUrl, options);
    return response;
  } catch (error) {
    throw new Error("FAILED TO GET DISTRIBUTION POINTS ");
  }
};

export const useGetDistributionPointsData = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.GET_DISTRIBUTION_POINTS_DATA],
    mutationFn: (options: { entity_jar: string }) =>
      getDistributionPointsData($http, options),
  });
};

type RemoveMemberData = {
  shukran_ids: string[];
  entity_id: string;
};

const removeMembersFromEntity = async (
  $http: AxiosInstance,
  options: RemoveMemberData
): Promise<any> => {
  const apiUrl = constructApiUrl(`/tipjar/entity/remove-entity-members/`);
  try {
    const response = await $http.post(apiUrl, options);
  } catch (error) {
    throw new Error("Failed to remove member");
  }
};

export const useRemoveMemberFromEntity = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.REMOVE_MEMBERS_FROM_ENTITY],
    mutationFn: (options: RemoveMemberData) =>
      removeMembersFromEntity($http, options),
  });
};

type UpdateEntityJarType = {
  name: string;
  is_default_entity_jar: boolean;
  entity_jar_id: string;
};

const updateEntityJar = async (
  $http: AxiosInstance,
  options: UpdateEntityJarType
) => {
  const { name, is_default_entity_jar, entity_jar_id } = options;
  const payload = { name, is_default_entity_jar };

  const apiUrl = constructApiUrl(
    `/tipjar/entity/${entity_jar_id}/update-entity-jar/`
  );

  try {
    const response = await $http.patch(apiUrl, payload);
    return response;
  } catch (error) {
    throw new Error("Entity jar update failed");
  }
};

export const useUpdateEntityJar = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.UPDATE_ENTITY_JAR],
    mutationFn: (options: UpdateEntityJarType) =>
      updateEntityJar($http, options),
  });
};

const deactivateEntityJar = async (
  $http: AxiosInstance,
  options: { entity_jar_id: string }
) => {
  const apiUrl = constructApiUrl(`/tipjar/entity/entity-jar/deactivate/`);
  try {
    const response = await $http.post(apiUrl, options);
    return response;
  } catch (error) {
    throw new Error("Failed to deactivate entity jar");
  }
};

export const useDeactivateEntityJar = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.DEACTIVATE_ENTITY_JAR],
    mutationFn: (options: { entity_jar_id: string }) =>
      deactivateEntityJar($http, options),
  });
};

const activateEntityJAR = async (
  $http: AxiosInstance,
  options: { entity_jar_id: string }
) => {
  const apiUrl = constructApiUrl(`/tipjar/entity/entity-jar/activate/`);
  try {
    const response = await $http.post(apiUrl, options);
    return response;
  } catch (error) {
    throw new Error("Failed to deactivate entity jar");
  }
};

export const useActivateEntityJAR = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.ACTIVATE_ENTITY_JAR],
    mutationFn: (options: { entity_jar_id: string }) =>
      activateEntityJAR($http, options),
  });
};

const fetchTipJarReports = async (
  $http: AxiosInstance,
  entity_id: string,
  entity_jar_id: string | null
) => {
  if (entity_jar_id) {
    const apiUrl = constructApiUrl(
      `/tipjar/point-system-disburse/${entity_id}/entity-report/`,
      {
        entity_jar_id: entity_jar_id,
      }
    );

    try {
      const response = await $http.get(apiUrl);
      return response;
    } catch (error:any) {
      console.log("error", error?.response?.data?.msg);
      throw new Error( `${error?.response?.data?.msg}`);
    }
  } else {
    const apiUrl = constructApiUrl(
      `/tipjar/point-system-disburse/${entity_id}/entity-report/`
    );
    try {
      const response = await $http.get(apiUrl);
      return response;
    } catch (error:any) {
      console.log("error", error?.response?.data?.msg);
      throw new Error( `${error?.response?.data?.msg}`);
    }
  }
};

export const useFetchTipJarReports = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.FETCH_TIPJAR_REPORTS],
    mutationFn: (options: {
      entity_id: string;
      entity_jar_id: string | null;
    }) => fetchTipJarReports($http, options.entity_id, options.entity_jar_id),
  });
};

export const fetchEntityTransactions = async (
  $http: AxiosInstance,
  entity_id: string
) => {
  const apiUrl = constructApiUrl(`/transactions/entity-transactions/`, {
    entity_id: entity_id,
  });

  try {
    const response = await $http.get(apiUrl);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch reports");
  }
};

export const useFetchEntityTransactions = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.FETCH_ENTITY_TRANSACTIONS],
    mutationFn: (options: { entity_id: string }) =>
      fetchEntityTransactions($http, options.entity_id),
  });
};

type TransferRequest = {
  currency: string;
  origin_user_id: number;
  destination_user_id?: number;
  amount: number;
  destination_user_first_name?: string;
  destination_user_last_name?: string;
  destination_user_phone_number?: string;
};

export const transferFromTipJar = async (
  $http: AxiosInstance,
  payload: TransferRequest
) => {
  const apiUrl = constructApiUrl(`/transactions/wallet-transfer/`);

  try {
    const response = await $http.post(apiUrl, payload);
    return response;
  } catch (error: any) {
    const responseCode = error?.response?.status;
    if (responseCode === 403) {
      return responseCode;
    }
    throw new Error("Could not transfer funds");
  }
};

export const useTransferFromTipJar = () => {
  const $http = useAxios();
  return useMutation({
    mutationKey: [TipJarQueryTypes.TRANSFER_FROM_TIPJAR],
    mutationFn: (payload: TransferRequest) =>
      transferFromTipJar($http, payload),
  });
};
